<h2 mat-dialog-title>{{ data.hubPartnerMap ? 'Edit ' + data.hubPartnerMap.partner.name : 'Add Partner Hubs' }}</h2>
<mat-dialog-content>
    <form [formGroup]="form" (submit)="submitForm()">

        <auto-complete-input placeholder="Partner" [options]="partnerNames" [control]="form.get('partner')"
            *ngIf="!data.hubPartnerMap" />
        <div class="row">
            <div class="cell">
                <ng-container *ngFor="let state of states; index as i">
                    <div *ngIf="i % 2 === 0">
                        <p>{{state}}</p>
                        <auto-complete-input placeholder="Pharmacy" [options]="pharmacyNames"
                            [control]="form.get(state)" />
                    </div>
                </ng-container>
            </div>
            <div class="cell">
                <ng-container *ngFor="let state of states; index as i">
                    <div *ngIf="i % 2 !== 0">
                        <p>{{state}}</p>
                        <auto-complete-input placeholder="Pharmacy" [options]="pharmacyNames"
                            [control]="form.get(state)" />
                    </div>
                </ng-container>
            </div>
        </div>
        <button mat-flat-button color="accent" type="submit"
            [disabled]="!form.valid || !form.dirty">{{!!data.hubPartnerMap ? "Update": "Save"}}</button>
    </form>
</mat-dialog-content>