<h2 mat-dialog-title>{{ dialogData ? "Edit" : "Add New" }} Tag</h2>
<mat-dialog-content>
    <form [formGroup]="form" (submit)="save()">
        <div class="form-container">
            <mat-form-field>
                <mat-label>Select a category</mat-label>
                <mat-select formControlName="selectedCategory">
                    <mat-option *ngFor="let category of categories" [value]="category">{{ category }}</mat-option>
                </mat-select>
            </mat-form-field>

            <ng-container *ngIf="selectedCategory">
                <mat-form-field>
                    <mat-label>Name</mat-label>
                    <input type="text" matInput formControlName="textValue"/>
                </mat-form-field>
            </ng-container>

            <ng-container *ngIf="selectedCategory.value.includes('Tag')">
                <mat-form-field>
                    <mat-label>Select a color</mat-label>
                    <mat-select formControlName="selectedTagColor">
                        <mat-option *ngFor="let color of tagColors" [value]="color">{{ color }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <ng-container *ngIf="selectedTagColor.value && textValue.value">
                <div class="actions">
                    <mat-chip-set>                        
                        <mat-chip 
                            [class.info]="selectedTagColor.value === 'info'"
                            [class.warn]="selectedTagColor.value === 'warn'"
                            [class.primary]="selectedTagColor.value === 'primary'"
                            [class.accent]="selectedTagColor.value === 'accent'"
                            [class.success]="selectedTagColor.value === 'success'">
                                {{textValue.value}}
                        </mat-chip>
                    </mat-chip-set>
                    <button mat-flat-button color="accent" type="submit">Save</button>
                </div>
            </ng-container>
        </div>
    </form>
</mat-dialog-content>