<h2 mat-dialog-title>Order Notification</h2>
<mat-dialog-content>

    <!-- show orders -->
    <div class="container">
        <div class="wrapper" *ngFor="let order of orders; let i=index">
            <p class="id">#{{order.orderID}}</p>
            <div class="customer">

                <p class="name">Name: {{order.contact.full_name}}</p>
                <p class="phone">Phone: {{order.contact.phone}}</p>
            </div>
            <div class="meds">
                <p class="title" *ngIf="order?.items.length">OTC Items</p>
                <div class="items">
                    <div class="item" *ngFor="let item of order.items">
                        <img *ngIf="item.featuredImageThumb._url" class="item-image"
                            [src]="item.featuredImageThumb._url" />
                        <p class="name">{{item.name}}</p>
                        <p class="quantity">{{item.qty}} X {{item.price}}</p>
                    </div>
                </div>
                <p class="title" *ngIf="order?.prescriptions.length">Script Items</p>
                <div class="items" *ngFor="let prescription of order.prescriptions">
                    <div class="item" *ngFor="let item of prescription.items">
                        <img class="item-image"
                            src="https://chemist2uaus.s3.amazonaws.com/9709f467f4eb606e7f9f79df3a303892_photo.jpg"
                            alt="scriptImage">
                        <p class="name">{{item.name}}</p>
                        <p class="quantity">{{item.quantity}} X {{item.price}}</p>
                    </div>
                </div>
            </div>
            <div class="pin">
                <h5>Order Collection Pin</h5>
                <p>{{order?.fulfillmentDetails?.clickAndCollectPin}}</p>
            </div>
            <div class="actions">
                <button mat-flat-button color="accent" (click)="markDelivered(order.id)">Picked Up</button>
                <button mat-flat-button color="accent" (click)="goToOrder(order.id)">Open Order</button>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>