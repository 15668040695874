<h2 mat-dialog-title *ngIf="!data.publicHoliday" >Holiday Hours</h2>
<h2 mat-dialog-title *ngIf="data.publicHoliday">Trading Hours Confirmation Required</h2>
<mat-dialog-content>
    <div class="row" *ngIf="data.publicHoliday">
        <h5 class="name">Date:</h5>
        <p class="value">{{data.publicHoliday.date | date}}</p>
     </div>
     <div class="row" *ngIf="data.publicHoliday">
        <h5 class="name">Reason: </h5>
        <p class="value">{{data.publicHoliday.name}}</p>
      </div>
    <form [formGroup]="exceptionAddForm" *ngIf="exceptionAddForm">
        <mat-form-field *ngIf="!data.publicHoliday">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="dp" [matDatepickerFilter]="dateFilter" formControlName='date' >
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp disabled="false"></mat-datepicker>
            <mat-error *ngIf="date.invalid">A date is required.</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="!data.publicHoliday" >
            <mat-label>Reason</mat-label>
            <input matInput type="text" formControlName='reason'>
            <mat-error *ngIf="reason.invalid">A reason is required.</mat-error>
        </mat-form-field>

        <label id="example-radio-group-label">Please confirm your trading hours</label>
        <mat-radio-group aria-label="Select an option" formControlName="type">
            <mat-radio-button class="radio-button" *ngFor="let item of radioButtonData"
                [value]="item.value">{{item.label}}</mat-radio-button>
        </mat-radio-group>
        <div *ngIf="type.value ==='Special hours'" class="special-hours">
            <div class="timmer">
                <h4>Opening Time</h4>
                <ngx-timepicker-field formControlName='openingTime' minutesGap="5" ></ngx-timepicker-field>
            </div>
            <div class="timmer">
                <h4>Closing Time</h4>
                <ngx-timepicker-field formControlName='closingTime' minutesGap="5"></ngx-timepicker-field>
            </div>
        </div>
        <div class="error">
            <mat-error *ngIf="showError">{{errorMessage}}</mat-error>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button color='accent' (click)="saveException()" [disabled]="exceptionAddForm.invalid">Save</button>
    <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>