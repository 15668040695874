<h2 mat-dialog-title>Add Event</h2>
<mat-dialog-content>
  <form [formGroup]="form" (submit)="savePharmacyTemplate()">
    <mat-form-field class="example-full-width">
      <mat-label>Type</mat-label>
      <mat-select formControlName="type" (selectionChange)="onTypeChange($event.value)">
        <mat-option *ngFor="let type of ['Pharmacy Incidents', 'Logistics Incidents']" [value]="typeMapping[type]">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>SubType</mat-label>
      <mat-select formControlName="subtype" (selectionChange)="onSubTypeChange($event.value)">
        <mat-option *ngFor="let subtype of subtypeOptions" [value]="subtype">
          {{ subtype }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width" *ngIf="showSubtypeDropdown">
      <mat-label>Details</mat-label>
      <mat-select formControlName="value">
        <mat-option *ngFor="let value of values" [value]="value">
          {{ value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width" *ngIf="!showSubtypeDropdown">
      <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput formControlName="value" placeholder="Enter details"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="accent" [disabled]="!form.valid" (click)="savePharmacyTemplate()">
    Save Event
  </button>
</mat-dialog-actions>