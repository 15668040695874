<div class="page login flex">
  <div class="header">
    <img src="/assets/images/logo.svg" />
    <div class="login-button-container">
      <a [routerLink]="['/login']" class="primary-link">Log in</a>
    </div>
  </div>
  <div class="main">
    <div class="forgot-password-container-wrapper">
      <div class="title">
        Forgot Password
      </div>
      <div class="intro">
        Enter your accounts email address and we’ll send you a reset password
      </div>
      <div class="forgot-password-container">
        <form id="forgetPasswordForm" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPaswordForm()">
          <div class="form-group">
            <input type="email" class="primary" placeholder="Email address" formControlName="email"
              [ngClass]="{'error': (f.get('email').errors?.required || f.get('email').errors?.email ) && submitted ? true: false}" />
          </div>
          <button type="submit" class="btn primary" [disabled]="f.invalid">Send Email</button>
        </form>
      </div>
    </div>
  </div>
</div>