<div class="comments-list" *ngIf="bsFix">
    <div *ngFor="let orderId of orderIds; let index = i" class="comment-chain">
        <ng-container *ngIf="orderedComments[orderId].length > 1">
            <mat-expansion-panel (opened)="threadOpen[orderId] = true;" (closed)="threadOpen[orderId] = false;">
                <mat-expansion-panel-header mat-tab-label>
                    <div class="thread-header">
                        <p class="username">{{orderedComments[orderId][0].username}}</p>
                        <p *ngIf="!threadOpen[orderId]" class="timestamp">{{orderedComments[orderId][0].updatedAt | date: "E d/MM h:mm a" }}</p>
                        <p class="timestamp">{{orderId}} | {{orderedComments[orderId][0].order.status}}</p>
                        <mat-slide-toggle [checked]="toggleStates[orderId]" (click)="resolveOrder($event, orderId)">
                            <p class="toggleAction">Resolved</p>
                        </mat-slide-toggle>
                        <button *ngIf="threadOpen[orderId]" class="count-badge" mat-flat-button (click)="viewOrder(orderedComments[orderId][0])" color="accent">View</button>
                        <span *ngIf="!threadOpen[orderId]" class="count-badge" [matBadge]="orderedComments[orderId].length" matBadgeOverlap="true"></span>
                    </div>
                </mat-expansion-panel-header>
                <inbox-message *ngFor="let comment of orderedComments[orderId]" [minimal]="true" [comment]="comment" (viewEvent)="viewOrder($event)" (resolvedEvent)="resolveComment($event)"></inbox-message>
            </mat-expansion-panel>
        </ng-container>
        <ng-container *ngIf="orderedComments[orderId].length === 1">
            <inbox-message [comment]="orderedComments[orderId][0]" (viewEvent)="viewOrder($event)" (resolvedEvent)="resolveComment($event)"></inbox-message>
        </ng-container>
    </div>
    <div *ngIf="comments.length === 0" class="empty">
        <p class="empty-label">
            Nothing to see here :)
        </p>
    </div>
</div>