import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Parse from 'parse';


@Injectable({
  providedIn: 'root'
})

export class NoAuthGuard  {

  constructor(private router: Router) {}

  canActivate() {
    const currentUser = Parse.User.current();
    if (currentUser && currentUser.authenticated()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
