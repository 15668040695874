import { Component, inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { LoaderService } from '../../services/loader.service';
import Swal from 'sweetalert2';
import { BasePage } from 'src/app/pages/base-page';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePage implements OnInit  {
  private $auth = inject(AuthService);
  private $state = inject(StateService);
  public loginForm: UntypedFormGroup;
  public submitted = false;

  constructor(private fb: UntypedFormBuilder, private loaderService: LoaderService, private amplitude: AmplitudeService, injector: Injector) {
    super(injector)
  }

  ngOnInit(): void {
    this.setupLoginForm();
  }

  async onSubmitLoginForm(): Promise<void> {
    this.submitted = true;
    if (this.loginForm.valid) {
      const userData = this.loginForm.getRawValue();
      try {
        this.loaderService.triggerLoading.emit(true)
        const user:User = await  this.$auth.signIn(userData);
        this.amplitude.setUserId();
        this.amplitude.logEvent(this.amplitude.AmplitudeEventNames.USER_LOGIN, { user: user.id });
        this.$state.isPharmacyLoggedIn.subscribe((value:boolean) => {
          if(value && user){
          this.router.navigate(['/p-orders']);
          this.initializeHubSpot(user)
        }});
        
        this.$state.isLoggedIn.subscribe((value:boolean) => {
          if(value && user)
          this.router.navigate(['./orders']);
        });
        
      } catch (err) {
         this.amplitude.logEvent(this.amplitude.AmplitudeEventNames.USER_LOGIN_FAIL, { error: JSON.stringify(err) });
         Swal.fire({
          title: "Error...",
          icon: "warning",
          text: err
        })
        this.loaderService.triggerLoading.emit(false)
        console.log(err);
      } finally {
        this.loaderService.triggerLoading.emit(false)
      }
    }
  }

  setupLoginForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() {
    return this.loginForm as UntypedFormGroup;
  }
}
