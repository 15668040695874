import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-mat-custom-select',
  templateUrl: './mat-custom-select.component.html',
  styleUrls: ['./mat-custom-select.component.scss']
})
export class MatCustomSelectComponent implements OnInit {

  constructor() {
  }

  @Input() value = null;
  @Input() label = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() options: any[] = [];
  @Input() placeholder = '';
  @Input() multiple = false;

  panelColor = new UntypedFormControl('red');

  ngOnInit(): void {
  }

  change($event: MatSelectChange) {
    this.valueChange.emit($event.value);
  }

}
