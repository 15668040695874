import { Component, inject, Injector } from '@angular/core';
import { MatDialogRef, } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TOrderPrescription } from '@chemist2u/types-client/C2U/Interfaces';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects/Order';
import { DataService } from 'src/app/services/global/data.service';
import { OrderHelper } from 'src/app/services/helpers/order';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'pharmacy-notification',
  templateUrl: './pharmacy-notification.component.html',
  styleUrls: ['./pharmacy-notification.component.scss']
})
export class DialogPharmacyNotification {
  private data = inject(DataService);
  public orders: Order[] = [];
  public prescriptions: TOrderPrescription[] = [];
  private orderHelper = inject(OrderHelper);

  constructor(injector: Injector,
    public dialogRef: MatDialogRef<DialogPharmacyNotification>,
    public router: Router,
    public loaderService: LoaderService) {
  }


  async ngOnInit() {
    this.data.pharmacyNotfications.subscribe((data) => {
      this.orders = data;
    });
  }
  async markDelivered(orderID: string) {
    try {
      this.loaderService.triggerLoading.emit(true);
      //const order = this.orders.find((order) => order.id === orderID);
      await this.orderHelper.markAsShippedOut(orderID);
      await this.orderHelper.markOrderAsDeliveredByPharmacy(orderID);
      this.data.pharmacyNotfications.next(this.orders.filter((order) => order.id !== orderID));
      this.dialogRef.close();
      this.loaderService.triggerLoading.emit(false);
    } catch (error) {
      this.loaderService.triggerLoading.emit(false);

    }
  }
  goToOrder(orderID: string) {
    this.router.navigate(['/p-orders/manage/', orderID]);
    this.dialogRef.close();
  }


}
