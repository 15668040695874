import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MiscString } from '@chemist2u/types-client/C2U/ParseObjects/MiscString';

@Component({
    selector: 'chip-input',
    templateUrl: './chip-input.component.html',
    styleUrls: ['./chip-input.component.scss']
})
export class ChipInputComponent implements OnInit {

    @Input() placeholder: string = '';
    @Input() canUpdate: boolean = false;
    @Input() activeTags: MiscString[] = [];
    @Input() tagsList: MiscString[] = []
    @Input() autocomplete: boolean = false;
    @Output() updateEvent = new EventEmitter<MiscString[]>

    public separatorKeyCodes: number[] = [ENTER, COMMA];
    public currentString = signal('');

    get filteredStrings() {
        const currentStringValue = this.currentString().toLowerCase();
        const filteredList = this._tagsList.filter(s => !this._activeTags.includes(s));
        return currentStringValue ? filteredList.filter(string => string.toLowerCase().includes(currentStringValue)) : filteredList.slice();
    };

    get currentStringValue(): string {
        return this.currentString();
    }

    set currentStringValue(value: string) {
        this.currentString.set(value);
    }

    get _activeTags(): string[] {
        return this.activeTags.map(t => t.value);
    }

    get _tagsList(): string[] {
        return this.tagsList.map(t => t.value);
    }

    constructor() {
    }

    ngOnInit() {
        
    }

    add(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        const check = this.tagsList.find(tag => tag.value.toLowerCase() === value.toLowerCase());
        if (check) {
            this.activeTags.push(check);
            this.updateEvent.next(this.activeTags)
        }
        this.currentString.set('');
        event.chipInput!.clear();
    }

    remove(tag: MiscString): void {
        this.activeTags = this.activeTags.filter(t => t.value !== tag.value);
        this.updateEvent.next(this.activeTags)
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.viewValue;
        const check = this.tagsList.find(tag => tag.value.toLowerCase() === value.toLowerCase());
        if (check) {
            this.activeTags.push(check)
            this.updateEvent.next(this.activeTags)
        }
        this.currentString.set('');
        event.option.deselect();
    }
}
