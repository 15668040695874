import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';

@Component({
  selector: 'auto-complete-input',
  templateUrl: './auto-complete-input.component.html',
  styleUrls: ['./auto-complete-input.component.scss']
})
export class AutoCompleteInputComponent implements OnInit{
  
  @Input() placeholder: string = '';
  @Input() options: string[] = [];
  @Input() value: string | undefined;
  @Input() control: FormControl | undefined;
  @Input() disabled: boolean = false;

  searchCtrl: FormControl;
  searchResults: Observable<string[]>;

  constructor() {
    this.searchCtrl = new FormControl();
    this.searchResults = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      map(search => search ? this.filterOptions(search) : this.options.slice())
    )
  }

  ngOnInit() {
    if (this.control.value) {
      this.searchCtrl.setValue(this.control.value);
    }
  }

  filterOptions(search: string): string[] {
    const array = this.options.filter(option => option.toLowerCase().includes(search.toLowerCase()));
    return array.length ? array : ['No results found'];
  }

  handleSelectionChange($event, control: FormControl) {
    control.setValue($event.source.value) 
    control.markAsDirty()
  }
}
