import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'view-timeline-details',
  templateUrl: './view-timeline-details.component.html',
  styleUrls: ['./view-timeline-details.component.scss']
})
export class ViewTimelineDetailsComponent {
  constructor(
    private dialogRef: MatDialogRef<ViewTimelineDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
