import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'nav-pane',
  templateUrl: './nav-pane.component.html',
  styleUrls: ['./nav-pane.component.scss']
})
export class NavPaneComponent implements OnInit {

  constructor(private router: Router) { }


  @Input() title: string = "Pane Title";
  @Input() hasActions: boolean = true;
  @Input() link: string = "";
  @Input() actions:any[] = [];
  @Input() label: string = "This is the main label";


  ngOnInit(): void {
  }

  goToLink() {
    if(this.link) {
      this.router.navigate([this.link])
    }
  }

}
