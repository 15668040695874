import { Component, Injector, Inject, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderComment } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { BasePage } from 'src/app/pages/base-page';
import { FetchService } from 'src/app/services/fetch.service';
import { PharmacyCommentsTemplate } from '@chemist2u/types-client/C2U/ParseObjects/PharmacyCommentsTemplate';
// import { FetchService } from 'src/app/services/fetch.service';

@Component({
    selector: 'dialog-add-order-comment',
    templateUrl: './add-order-comment.html',
    styleUrls: ['./add-order-comment.scss']
})

export class DialogAddOrderComment extends BasePage implements OnInit {
    private $fetch = inject(FetchService);
    public hasComment: boolean = false;
    public comment: string = '';
    public commentType: string = '';
    public selectedMessageType: string = '';
    public messageSearchCtrl = new FormControl();  // Initialize FormControl
    public messageTypes : PharmacyCommentsTemplate[]= [];
    public filteredMessages: PharmacyCommentsTemplate[] = [];

    constructor(
        injector: Injector,
        public dialogRef: MatDialogRef<DialogAddOrderComment>,
        @Inject(MAT_DIALOG_DATA) public data: { comment: OrderComment | undefined, commentType: 'pharmacyComment' | 'orderComment' }
    ) {
        super(injector);
    }

    async ngOnInit() {
        if (this.data.comment) {
            this.hasComment = true;
            this.comment = this.data.comment.comment;
        }
        this.commentType = this.data.commentType;

        // Initialize search/filter functionality
        this.messageSearchCtrl.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            map(value => this._filterMessages(value || '')) // Use a helper method to filter
        ).subscribe(filtered => {
            this.filteredMessages = filtered;
        });

        try {
            this.messageTypes = await this.$fetch.fetchAllPharmacyCommentTemplates('Active');
            this.filteredMessages = this.messageTypes;
          } catch (error) {
            console.log("error in fetching custom messages", error);
            this.errorAlert(error);
          }
    }

    private _filterMessages(searchTerm: string): PharmacyCommentsTemplate[] {
        if (!searchTerm.trim()) {
            return this.messageTypes; // Return all if no search term
        }
        return this.messageTypes.filter(message =>
            message.messageType.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }

    clearSearch() {
        this.messageSearchCtrl.setValue('');
        this.comment = '';
    }

    onMessageTypeSelect(selectedMessage: PharmacyCommentsTemplate) {
        this.comment = selectedMessage.message; // Set the comment based on the selected message
    }

    saveComment() {
        this.dialogRef.close(this.comment);
    }
}
