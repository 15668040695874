import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'c2u-pane',
  templateUrl: './c2u-pane.component.html',
  styleUrls: ['./c2u-pane.component.scss']
})
export class C2uPaneComponent implements OnInit {

  @Input() title: string = "Title";
  @Input() action: string = "";
  @Input() dark: boolean = false;
  @Input() disabled: boolean = false;
  @Input() warn: boolean = false;
  @Output() actionClick = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  actionClicked() {
    if(!this.disabled) {
      this.actionClick.emit(true);
    }
   
    
  }

}
