<div class="comment">
    <div class="comment-header">
        <div class="labels">
            <p *ngIf="!minimal" class="username">{{comment.username}}</p>
            <p class="timestamp" [class.minimal]="minimal">{{comment.updatedAt | date: "E d/MM h:mm a" }}</p>
            <p *ngIf="!minimal" class="timestamp">{{comment.order.get("orderID")}} | {{comment.order.get("status")}} </p>
        </div>
        <mat-slide-toggle [checked]="comment.action" (click)="emitResolved()">
            <p class="toggleAction">Resolved</p>
        </mat-slide-toggle>
        <mat-dialog-actions *ngIf="!minimal">
            <button mat-flat-button (click)="emitView()" color="accent">View</button>
        </mat-dialog-actions>
    </div>
    <div class="comment-body">
        <p class="comment-text">{{comment.comment}}</p>
    </div>
</div>