<div class="sidebar">
  <div class="header">
    <img src="/assets/images/logo.svg" *ngIf="pageUrl === '/'; else backUrl" />
    <ng-template #backUrl>
      <div *ngIf="pageUrl !== '/'" [routerLink]="['/']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        <div class="back-url title small">Home</div>
      </div>

    </ng-template>
  </div>
  <div class="page-heading" *ngIf="pageUrl !== '/'" [ngStyle]="{showGoToSearch: 'margin: 0; padding: 0;'}">
    <div class="title" *ngIf="!showGoToSearch"> {{sidebarTitle}}</div>
    <div class="title" *ngIf="showGoToSearch" style="margin: 0; padding: 0;">
      <mat-form-field>
        <mat-label>C2U Order Id</mat-label>
        <input matInput type="text" [(ngModel)]="goToString" (keydown.enter)="goTo()">
        <button mat-icon-button matSuffix (click)="goTo()" >
          <mat-icon>manage_search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <div class="page-heading" *ngIf="pageUrl === '/'">
    <div class="title"></div>
  </div>
  <div class="sidebar-menu" [class.mt--2]="showGoToSearch">
    <div class="menu-item" *ngFor="let menuItem of menuItems">
      <div class="menu-item-heading title" *ngIf="pageUrl === '/'" (click)="setSidebarTitle(menuItem.name)"
        [routerLink]="[menuItem.url]">{{menuItem.name}}</div>
      <div class="menu-categories" *ngIf="menuItemBelongsToPage(menuItem.url)">
        <div class="menu-category" *ngFor="let category of menuItem.categories">
          <div class="category-name" *ngIf="category.categoryName">{{category.categoryName}}</div>
          <div class="sub-menu-item" *ngFor="let subMenuItem of category.nestedItems" [class.with-count]="!!orderTypesWithCounts && orderTypesWithCounts.includes(subMenuItem.name)" [routerLink]="[subMenuItem.url]">
            <div class="title medium" [ngClass]="{'selected': pageUrl == subMenuItem.url}">
              <!-- [routerLink]="[subMenuItem.url]"> -->
              {{subMenuItem.name}}
            </div>
            <div *ngIf="orderCounts && orderCounts[subMenuItem.name]" [matBadge]="orderCounts[subMenuItem.name]" matBadgeOverlap="false"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="approvaltime-feedback">
    <!-- <div class="avg-time" *ngIf="avgApprovalTime >0 && pageUrl.includes('/orders')">
      <p matTooltip ='Average time taken by the admin team to mark a script order as approved'>Avg Approval Time</p>
      <p>in past 48 hours (mins)</p>
      <p [ngStyle]="{'color': avgApprovalTime>20? '#ff0000': avgApprovalTime<=10 ? '#19b501':'#f0b500'}">{{avgApprovalTime}}</p>
    </div> -->
    <div class="logout-container" *ngIf="userData?.get('username')">
      <div class="feedback" (click)="feedbackClick()" *ngIf="!isPharmacyUserLoggedIn">
        <mat-icon>report_problem</mat-icon>
        <p>Feedback</p>
      </div>
      <div class="profile-data-container">
        <div class="profile-buttons">
          <button mat-button [matMenuTriggerFor]="menu" #menuTrigger class="logout-button title" yPosition="above"
            xPosition="before">{{userData.get('username') | uppercase | slice:0:1}}</button>
          <mat-menu #menu="matMenu" [overlapTrigger]="false">
            <button mat-menu-item (click)="logout()">Logout</button>
            <button mat-menu-item (click)="viewProfile()">Profile</button>
          </mat-menu>
        </div>
        <div class="profile-data" *ngIf="userData?.get('username')">
          <div class="name title small">{{userData.get('username')}}</div>
          <p class="email">{{userData.get('email')}}</p>
        </div>
      </div>
    </div>
  </div>
</div>