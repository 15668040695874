<h2 mat-dialog-title>Add Token</h2>
<mat-dialog-content>
    <form [formGroup]="form" (submit)="saveTokenTemplate()">
        <mat-form-field class="example-full-width">
            <mat-label>Token</mat-label>
            <input matInput type="text" formControlName="token">
        </mat-form-field>
        <!-- <mat-form-field class="example-full-width">
            <mat-label>Item Name</mat-label>
            <input matInput type="text" formControlName="itemName">
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status" name="status">
                <mat-option *ngFor="let status of ['Active','Inactive']" [value]="status">
                    {{status}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="example-full-width">
            <mat-label>Supplies Remaining</mat-label>
            <input matInput type="text" formControlName="supplyRemaining">
        </mat-form-field> -->
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="accent" (click)="saveTokenTemplate()">Create</button>
</mat-dialog-actions>