import { Component, EventEmitter, inject, Injectable, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { OrderStatusButtonComponent } from '../order-status-button/order-status-button.component';
import { CustomDateDisplayComponent } from '../custom-date-display/custom-date-display.component';
import { ISort } from '../../interfaces/request-params/sort';
import { LoaderService } from '../../../services/loader.service';
import { CurrencyPipe } from '@angular/common';
import { GRID_DATASERVICE_MAPS } from '../../../services/dataservices';
import { FetchService } from 'src/app/services/fetch.service';

@Component({
  selector: 'app-grid-datatable',
  templateUrl: './grid-datatable.component.html',
  styleUrls: ['./grid-datatable.component.scss'],
  providers: [CurrencyPipe]
})
export class GridDatatableComponent implements OnInit, OnChanges {
  private $fetch = inject(FetchService);
  @Input() service: string;
  @Input() items = [];
  @Input() params: any = {};
  @Input() columnDefs = [];
  @Input() type : 'category' | 'subcategory' | 'item' | 'pharmacy' | 'brand' | 'order' = 'category';
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  public gridApi;
  public gridColumnApi;
  public gridOptions: any = {};
  public rowData: [];
  public components: any;
  public frameworkComponents: any;

  private sortObject: ISort;

  itemsService = null;


  constructor(private injector: Injector,
              private loaderService: LoaderService,
              private currencyPipe: CurrencyPipe) {
  }

  ngOnInit(): void {
    if(!(this.type == 'category' || this.type == 'subcategory' || this.type == 'item' || this.type == 'pharmacy' || this.type == 'brand')) {
      this.itemsService = this.injector.get<any>(GRID_DATASERVICE_MAPS[this.service]) as Injectable;
    }
    
    this.initializeGridOptions();
    this.initializeSort();
  }

  ngOnChanges(changes) {
    if (changes.params && !changes.params.firstChange) {
      this.items = [];
      this.gridApi.setFilterModel(this.params);
      this.gridApi.onFilterChanged();
    }
  }

  private initializeSort() {
    this.sortObject = {
      identifier: 'createdAt',
      direction: 'desc'
    };
  }

  onGridReady(params) {
    console.log(params);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //this.gridApi.sizeColumnsToFit();
    //this.gridColumnApi.autoSizeAllColumns();
    const self = this;
    const dataSource = {
      rowCount: null,
      getRows: async (params) => {
        const pagination = {
          limit: self.gridOptions.cacheBlockSize,
          skip: params.startRow
        };
        if (params.sortModel && params.sortModel.length) {
          self.applySort(params.sortModel);
        }
        self.loaderService.triggerLoading.emit(true);
        self.params.withCount = true;
        //const res = await self.itemsService.load(pagination, self.sortObject, self.params);
        let res: any = undefined;
        if(this.type === 'category') {
          res = await this.$fetch.loadCategory(pagination, self.sortObject, self.params);
          console.error("CATEGORY", res);
        }
        if(this.type === 'subcategory') {
          res = await this.$fetch.loadSubCategory(pagination, self.sortObject, self.params);
        }
        if(this.type === 'item') {
          res = await this.$fetch.load(pagination, self.sortObject, self.params);
        }
        if(this.type === 'brand') {
          res = await this.$fetch.loadBrands(pagination, self.sortObject, self.params);
        }
        const rowsThisPage = res.results;
        const total = res.count;
        self.items.concat(rowsThisPage);
        let lastRow = -1;
        if (total <= params.endRow) {
          lastRow = total;
        }
        params.successCallback(rowsThisPage, lastRow);
        self.gridApi.sizeColumnsToFit();
        self.loaderService.triggerLoading.emit(false);
      },
    };
    params.api.setDatasource(dataSource);
  }

  onFirstDataRendered(params) {
    //params.columnApi.autoSizeAllColumns();
  }


  private initializeGridOptions() {
    this.gridOptions = {
      rowModelType: 'infinite',
      maxConcurrentDatasourceRequests: 1,
      enableServerSideFilter: true,
      enableServerSideSorting: true,
      cacheBlockSize: 20,
      columnDefs: this.columnDefs,
      suppressCellSelection: true,
      skipHeaderOnAutoSize:true,
      rowSelection: 'single',
      defaultColDef: {
        flex: 1,
        resizable: true,
        sortable: true
      }
    };
    this.frameworkComponents = {
      orderStatusButtonComponent: OrderStatusButtonComponent,
      customDateComponent: CustomDateDisplayComponent
    };
    this.components = {
      currencyField: (params) => {
        return this.currencyPipe.transform(params.value, 'USD');
      }
    };
  }

  applySort(sortParams) {
    this.sortObject.identifier = sortParams[0].colId;
    this.sortObject.direction = sortParams[0].sort;
  }

  onRowClicked($event) {
    this.rowClicked.emit($event.data);
  }
}
