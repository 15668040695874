<h2 mat-dialog-title>MarketPlace Order Event</h2>
<mat-dialog-content>
    <div class="event">
        <div class="detail">
            <div class="detail-row">
                <h4>Action :</h4>
                <p>{{data.action}}</p>
            </div>
            <div class="detail-row">
                <h4>Timestamp :</h4>
                <p>{{data.timestamp | date: "E d/M h:mm a"}}</p>
            </div>
            <div class="detail-row">
                <h4>User :</h4>
                <p>{{data.user}}</p>
            </div>
        </div>
        <div class="table" *ngIf="data.payload">
            <div class="table-content">
                <div class="table-row">
                    <p class="bold">Title</p>
                    <p class="bold">Info</p>
                </div>
                <div class="table-row" *ngFor="let item of data.payload">
                    <p>{{item.name? item.name:''}}</p>
                    <p>{{item.text? item.text:''}}</p>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>