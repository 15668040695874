import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private $auth = inject(AuthService);
  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request);
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        console.log(err);
        if (err instanceof HttpErrorResponse) {
          try {
            if (err.status === 209) {
              this.$auth.logout();
            }
          } catch (e) {

          }
          // log error
        }
        return throwError(err.message);
      }));
  }
}
