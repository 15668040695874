import { EventEmitter, Injectable } from '@angular/core';
import { AutoMessageConfiguration, Brand, Category, CustomerMedicalProfile, CustomerSessionDetails, CustomSMSTemplates, DeliveryBrackets, EventTracking, Item, Order, OrderComment, PartnerOrder, Pharmacy, PharmacyComment, PharmacyDay, PharmacyException, PharmacyItemOverride, PharmacyReport, PharmacyServiceArea, PharmacyShift, PharmacyShiftDelivery, Promotion, PromotionCustomer, PublicHolidays, Role, ScriptFlow, SlideImage, SubCategory, Subscriptions, User, UserSuggestions, HubPharmacy, PharmacyCommentsTemplate } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import * as Parse from 'parse';
import { IPagination, ISort } from '../shared/interfaces';
import { Partner } from '@chemist2u/types-client/C2U/ParseObjects/Partner';
import C2U from '@chemist2u/types-client';
import moment from 'moment';
import { TFulfillmentMethodName, TSubscriptionToken } from '@chemist2u/types-client/C2U/Interfaces';
import { MiscString } from '@chemist2u/types-client/C2U/ParseObjects';

@Injectable({
  providedIn: 'root'
})
export class FetchService {

  public errorEmitter = new EventEmitter();

  constructor() { }

  async newUserCount(): Promise<number> {
    return new Parse.Query(User)
      .containedIn('status', ['Active', 'Inactive'])
      .equalTo('role', await Role.getCustomer())
      .count();
  }


  async loadCustomerCount(params: any, search: any): Promise<number> {
    const query = new Parse.Query(User)
      .equalTo('role', await Role.getCustomer());

    if (search) {
      query.matches(search.column, search.value, 'i');
    }

    if (params && params.status) {
      query.equalTo('status', params.status);
    } else {
      query.containedIn('status', ['Active', 'Inactive']);
    }
    return query.count();
  }

  public async fetchAllCustomMessages(messageType?: "Order" | "Pharamcy" | "Customer", type?: "C2U" | "Bupa" | "Other", status: "Active" | "Inactive" | "All" = "All"): Promise<CustomSMSTemplates[]> {
    const query = new Parse.Query(CustomSMSTemplates).ascending('name');
    if(status !== "All"){
      query.equalTo('status', status);
    }
    if (messageType) {
      query.equalTo('messageType', messageType);
    }
    if (type) {
      query.equalTo('type', type);
    }
    return query.limit(500).find();
  }

  //Pharmacy Service Area
  public async loadApprovedForPharmacy(pharmacy): Promise<PharmacyServiceArea[]> {
    const query = new Parse.Query(PharmacyServiceArea);

    query.equalTo('approved', true);
    query.equalTo('pharmacy', pharmacy.user);

    return query.find();
  }

  //User Suggestions
  loadFeedback(): Promise<UserSuggestions[]> {
    return new Parse.Query(UserSuggestions).ascending('updatedAt').find();
  }

  getFeedbackById(id: any): Promise<UserSuggestions> {
    //console.log(id); 
    return new Parse.Query(UserSuggestions).equalTo("objectId", id).first();
  }

  async fetchUserServiceability(data:{pharmacyUserId?: string,serviceable?: boolean}) {
    console.log(data);
    
    return Parse.Cloud.run('userServiceability', data);
  }


  //Pharmacy Item Override Functions
  getOverrideForItem(item, pharmacy): Promise<PharmacyItemOverride> {
    const query = new Parse.Query(PharmacyItemOverride);
    query.equalTo('item', item);
    query.equalTo('pharmacy', pharmacy.user);
    return query.first();
  }

  loadForPharmacyItems(pharmacy: Pharmacy, items: Item[]) {
    const query = new Parse.Query(PharmacyItemOverride);
    query.equalTo('pharmacy', pharmacy.user as User);
    query.containedIn('item', items as Item[]);
    //query.limit(items.length);
    return query.find();
  }
  loadPharmacyOverrides(pharmacy: Pharmacy): Promise<PharmacyItemOverride[]> {
    const query = new Parse.Query(PharmacyItemOverride);
    query.equalTo('pharmacy', pharmacy.user as User);
    query.limit(5000);
    query.select('item');
    query.select('basePrice');

    return query.find();
  }


  loadInCloud(params: any = {}): Promise<Item[]> {
    console.log(params);
    return Parse.Cloud.run('getItems', params);
  }

  loadAll(params): Promise<Item[]> {
    // const query = new Parse.Query(Item);
    // query.limit(1000);

    // return query.find();

    return Parse.Cloud.run('loadAllItemsSekelton', params);
  }
  //load complete master catalog
  loadMasterCatalog(masterCatalogCount:number): Promise<Item[]> {
    const query = new Parse.Query(Item)
    query.include('category');
    query.include('subcategory');
    query.select('apn');
    query.select('name');
    query.select('basePrice');
    query.ascending('name');
    query.select("category");
    query.select("subcategory");
    query.equalTo('status', 'Active');
    query.limit(masterCatalogCount);
    return query.find();
  }

  loadItemByApn(apn): Promise<Item> {
    let query = new Parse.Query(Item).exists('category').exists('subcategory');
    query.equalTo("apn", apn);

    return query.first();
  }

  loadItemsCount(params: any): Promise<number> {
    let query = new Parse.Query(Item).exists('category').exists('subcategory');;
    if (params) {

      if (params.category) {
        query.equalTo('category', params.category);

        if (params.subcategory) {
          query.equalTo('subcategory', params.subcategory);
        }
      }

      if (params.search) {
        query.contains('canonical', params.search);
      }


    }
    return query.count();
  }

  loadItems(params: any): Promise<Item[]> {
    let query = new Parse.Query(Item);
    query.include('category').include('subcategory').exists('category').exists('subcategory').equalTo('status', 'Active');

    if (params) {
      if (params.pagination) {
        query.limit(params.pagination.limit);
        query.skip(params.pagination.limit * params.pagination.page);
      }

      if (params.sort) {
        if (params.sort.direction == "asc") {
          query.ascending(params.sort.column);
        }
        if (params.sort.direction == "desc") {
          query.descending(params.sort.column);
        }
      }

      if (params.category) {
        query.equalTo('category', params.category);

        if (params.subcategory) {
          query.equalTo('subcategory', params.subcategory);
        }
      }

      if (params.search) {
        query.contains('canonical', params.search);
      }


    }
    return query.find();
  }


  loadOne(id: string): Promise<Item> {
    const query = new Parse.Query(Item);
    query.include(['category', 'subcategory', 'relatedItems', 'brand','isPostable']);
    query.doesNotExist('deletedAt');
    return query.get(id);
  }

  search(params: any = {}): Promise<Item[]> {
    console.log('Running the search frunction');

    const mainQuery = new Parse.Query(Item);
    if (params.canonical) {
      console.log(params.canonical);
      console.log('Searching on name');
      console.log(params.canonical);
      mainQuery.contains('canonical', params.canonical);
      mainQuery.ascending('name');
    }
    mainQuery.limit(10);
    mainQuery.equalTo('status', 'Active');
    mainQuery.include('name');
    mainQuery.doesNotExist('deletedAt');


    return mainQuery.find();
  }

  async searchInCatalog(params: any = {}, pharmacy: Pharmacy, deliveryMethod:TFulfillmentMethodName='OnDemand'): Promise<Item[]> {
    console.log('Running the search frunction');

    const relation = pharmacy.relation('pharmacyItems');
    const mainQuery = relation.query();

    if (params.canonical) {
      mainQuery.contains('canonical', params.canonical);
      mainQuery.ascending('name');
    }
    if(deliveryMethod === 'Postal'){
      mainQuery.equalTo('isPostable', true);
    }
    mainQuery.limit(10);
    mainQuery.equalTo('status', 'Active');
    mainQuery.include('name');
    mainQuery.doesNotExist('deletedAt');


    return await mainQuery.find() as Item[];
  }


  load(pagination, sort?: ISort, params?, count: boolean = false): Promise<Item[] | number> {
    const mainQuery = new Parse.Query(Item);
    if (params.priceMin) {
      mainQuery.greaterThanOrEqualTo('netPrice', Number(params.priceMin));
    }
    if (params.priceMax) {
      mainQuery.lessThanOrEqualTo('netPrice', Number(params.priceMax));
    }
    if (params.ratingMin) {
      mainQuery.greaterThanOrEqualTo('ratingAvg', Number(params.ratingMin));
    }
    if (params.ratingMax) {
      mainQuery.lessThanOrEqualTo('ratingAvg', Number(params.ratingMax));
    }
    if (params.canonical) {
      mainQuery.contains('canonical', params.canonical);
    }
    if (params.category) {
      if (Array.isArray(params.category)) {
        if (params.category.length) {
          mainQuery.containedIn('category', params.category);
        }
      } else {
        mainQuery.equalTo('category', params.category);
      }
    }

    if (params.subCategory) {
      if (Array.isArray(params.subCategory)) {
        if (params.subCategory.length) {
          mainQuery.containedIn('subcategory', params.subCategory);
        }
      } else {
        mainQuery.equalTo('subcategory', params.subCategory);
      }
    }
    /*
    if (params.brand) {
      if (Array.isArray(params.brand)) {
        const brands = params.brand.map((id: string) => {
          const obj = new Brand;
          obj.id = id;
          return obj;
        });

        if (brands.length) {
          mainQuery.containedIn('brand', brands);
        }

      } else {

        const brand = new Brand;
        brand.id = params.brand;

        mainQuery.equalTo('brand', brand);
      }
    }*/

    if (params.sale === '1') {
      mainQuery.greaterThan('salePrice', 0);
    }

    if (params.new === '1') {
      mainQuery.equalTo('isNewArrival', true);
    }

    if (params.featured === '1') {
      mainQuery.equalTo('isFeatured', true);
    }

    if (pagination && !count) {
      if (pagination.limit) {
        mainQuery.limit(pagination.limit);
      }
      if (pagination.skip) {
        mainQuery.skip(pagination.skip);
      }
    }

    if (params) {
      this.applyParams(mainQuery, params);
    }

    if (sort && !count) {
      this.sort(mainQuery, sort);
    } else {
      mainQuery.descending('createdAt');
    }
    mainQuery.include(['category', 'subcategory', 'brand']);
    mainQuery.doesNotExist('deletedAt');
    if (count) {
      return mainQuery.count();
    }
    return mainQuery.find();

  }

  async saveItem(data) {
    const item = new Item();
    return item.save(data);
  }

  count(params: any = {}): Promise<number> {

    const mainQuery = new Parse.Query(Item);

    if (params.priceRange) {
      mainQuery.greaterThanOrEqualTo('netPrice', params.priceRange.lower);
      mainQuery.lessThanOrEqualTo('netPrice', params.priceRange.upper);
    }

    if (params.rating) {
      mainQuery.greaterThanOrEqualTo('ratingAvg', params.rating.lower);
      mainQuery.lessThanOrEqualTo('ratingAvg', params.rating.upper);
    }

    if (params.isFeatured) {
      mainQuery.equalTo('isFeatured', true);
    }

    if (params.isOnSale) {
      mainQuery.greaterThan('salePrice', 0);
    }

    if (params.cat) {
      if (Array.isArray(params.cat)) {
        if (params.cat.length) {
          mainQuery.containedIn('category', params.cat);
        }
      } else {
        mainQuery.equalTo('category', params.cat);
      }

    }
    /*
        if (params.brand) {
    
          if (Array.isArray(params.brand)) {
    
            const brands = params.brand.map((id: string) => {
              const obj = new Brand;
              obj.id = id;
              return obj;
            });
    
            if (brands.length) {
              mainQuery.containedIn('brand', brands);
            }
    
          } else {
    
            const brand = new Brand;
            brand.id = params.brand;
    
            mainQuery.equalTo('brand', brand);
          }
        }*/

    mainQuery.equalTo('status', 'Active');
    mainQuery.doesNotExist('deletedAt');
    return mainQuery.count();
  }

  like(itemId: string) {
    return Parse.Cloud.run('likeItem', { itemId });
  }

  isLiked(itemId: string): Promise<boolean> {
    return Parse.Cloud.run('isItemLiked', { itemId });
  }

  trackView(itemId: string) {
    return Parse.Cloud.run('trackViewItem', { itemId });
  }

  applyParams(query, params) {
    if (params.contains) {
      for (let i = 0; i < params.contains.length; i++) {
        const value = params.contains[i].value;
        const name = params.contains[i].name;
        if (value && value !== '') {
          query.matches(name, value, 'i');
        }
      }
    }

    if (params.equalTo) {
      for (let i = 0; i < params.equalTo.length; i++) {
        const name = params.equalTo[i].name;
        const value = params.equalTo[i].value;
        if (value) {
          query.equalTo(name, value);
        }
      }
    }
    if (params.include) {
      query.include(params.include);
    }
    if (params.withCount) {
      query.withCount(true);
    }
  }

  sort(query, sort: ISort) {
    if (sort.direction === 'asc') {
      query.ascending(sort.identifier);
    } else {
      query.descending(sort.identifier);
    }
  }

  //Category Functions
  fullLoadCategory(): Promise<Category[]> {
    const query = new Parse.Query(Category);
    query.limit(1000);
    return query.find();
  }

  loadCategory(pagination: IPagination, sort: ISort, params: any = {}, count = false): Promise<Category[] | number> {
    const query = new Parse.Query(Category);
    query.equalTo('status', 'Active');
    if (params.canonical) {
      query.contains('canonical', params.canonical);
    }
    if (params) {
      this.applyParams(query, params);
    }
    if (!count) {
      if (pagination.limit) {
        query.limit(pagination.limit);
      }
      if (pagination.skip) {
        query.skip(pagination.skip);
      }
    }
    if (sort) {
      this.sort(query, sort);
    } else {
      query.ascending('name');
    }
    if (!count) {
      return query.find().then((res) => res, this.catchError);
    } else {
      return query.count().then((res) => res, this.catchError);
    }
  }

  loadAllCategory(params: any, pagination: IPagination, count = false): Promise<Category[] | number> {

    const query = new Parse.Query(Category);
    query.equalTo('status', 'Active');
    query.ascending('name');
    if (params) {
      this.applyParams(query, params);
    }
    if (count) {
      return query.count();
    } else {
      if (pagination) {
        query.limit(pagination.limit);
      }
      return query.find();
    }

  }

  saveCategory(data): Promise<Category> {
    const category = new Category();
    return category.save(data);
  }

  catchError(err) {
    switch (err.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        this.errorEmitter.emit('INVALID_SESSION');
        break;
    }
    throw err;
    return err;
  }


  //SubCategory Functions
  fullLoadSubCategory(): Promise<SubCategory[]> {
    return new Parse.Query(SubCategory).limit(1000).find();
  }

  loadSubCategory(pagination: IPagination, sort: ISort, params: any = {}, count = false): Promise<SubCategory[] | number> {
    const query = new Parse.Query(SubCategory);
    query.equalTo('status', 'Active');
    query.include('category');
    query.doesNotExist('deletedAt');
    if (params.category) {
      query.equalTo('category', params.category);
    }
    if (params.canonical) {
      query.contains('canonical', params.canonical);
    }
    if (params) {
      this.applyParams(query, params);
    }
    if (!count && pagination) {
      query.limit(pagination.limit);
      const skip = pagination.skip ? pagination.skip : 0;
      query.skip(skip);
    }
    if (sort && !count) {
      this.sort(query, sort);
    } else {
      query.ascending('name');
    }
    if (!count) {
      return query.find().then((res) => res, this.catchError);
    } else {
      return query.count().then((res) => res, this.catchError);
    }
  }

  countSubCategory(params: any = {}): Promise<number> {

    const query = new Parse.Query(SubCategory);

    if (params.category) {
      query.equalTo('category', params.category);
    }

    if (params.canonical) {
      query.contains('canonical', params.canonical);
    }

    query.equalTo('status', 'Active');
    query.doesNotExist('deletedAt');

    return query.count();
  }

  async saveSubCategory(data) {
    const subCategory = new SubCategory();
    return subCategory.save(data);
  }

  async loadOneSubCategory(id: string) {
    // const params = {
    //   include: ['Category']
    // };
    return new Parse.Query(SubCategory).include("category").get(id).then((res) => res, this.catchError);
  }



  //Pharmacy Functions

  async getAllPharmacies(): Promise<Pharmacy[]> {
    const query = new Parse.Query(Pharmacy);
    query.ascending('businessName');
    query.limit(1000);
    return query.find();
  }

  async getPharmacyByID(id): Promise<Pharmacy> {
    const query = new Parse.Query(Pharmacy);
    query.equalTo('objectId', id);
    query.include('user');
    query.include('sfScripts');
    query.include('sfOTC');
    query.include('sfScriptsFreight');
    return query.first();
  }


  createPharmacy(data: any): Promise<Pharmacy> {
    return Parse.Cloud.run('createPharmacyUser', data);
  }

  getPharmacyUser(id): Promise<User> {
    return new Parse.Query(User).get(id);
  }

  getPharmaciesByDistance(from: Parse.GeoPoint, distance: number = 8): Promise<Pharmacy[]> {
    return new Parse.Query(Pharmacy).equalTo('status', 'Active').withinKilometers('location', from, distance).find();
  }

  getPharmacyByUser(p: User): Promise<Pharmacy> {
    return new Parse.Query(Pharmacy).equalTo('user', p).first();
  }

  getUberPharmacyByUser(pharmacyUser: User): Promise<Pharmacy> {
    return new Parse.Query(Pharmacy).equalTo('user', pharmacyUser).exists('meta').first();
  }


  async getPharmacyDetails(): Promise<Pharmacy> {
    const query = new Parse.Query(Pharmacy);
    const pharmacy = await query.first();
    return pharmacy;
  }

  getPharmacyShifts(pid: string): Promise<{ simpleShifts: C2U.Interfaces.TAllocatedShift[] }> {
    return Parse.Cloud.run("getShiftsForPharmacy", { pharmacyId: pid });
  }

  getStandardPharmacyShifts(pid: string): Promise<{ simpleShifts: C2U.Interfaces.TAllocatedShift[] }> {
    return Parse.Cloud.run("loadStandardFulfillmentShift", { pharmacyId: pid });
  }

  getClickAndCollectPharmacyShifts(pid: string): Promise<{ simpleShifts: C2U.Interfaces.TAllocatedShift[] }> {
    return Parse.Cloud.run("loadClickCollectFulfillmentShift", { pharmacyId: pid });
  }

  loadItemsForPharmacyCount(pharmacy: Pharmacy, params: any): Promise<number> {
    let pharmacyItems = pharmacy.get("pharmacyItems");
    let query = pharmacyItems.query();
    query.include('category');
    query.include('subcategory');
    query.equalTo('status', 'Active');

    if (params) {

      if (params.category) {
        query.equalTo('category', params.category);

        if (params.subcategory) {
          query.equalTo('subcategory', params.subcategory);
        }
      }

      if (params.search) {
        query.contains('canonical', params.search);
      }

    }
    return query.count();
  }
  async loadAllPharmacyItems(pharmacy: Pharmacy, limit:number): Promise<Item[]> {
    const pharmacyItems = pharmacy.get("pharmacyItems");
    const query = pharmacyItems.query();
    query.include('category');
    query.include('subcategory');
    query.select('apn');
    query.select('name');
    query.select('basePrice');
    query.ascending('name');
    query.select("category");
    query.select("subcategory");
    query.equalTo('status', 'Active');
    query.limit(limit);
    return query.find();
  }
  //loading pharmacy items for schedule summary in manage pharmacy page.
  async loadAllPharmacyScheduleItems(pharmacy: Pharmacy, limit: number): Promise<Item[]> {
    const pharmacyItems = pharmacy.get('pharmacyItems');
    const query = pharmacyItems.query();
    query.select('schedule');
    query.equalTo('status', 'Active');
    query.select('apn');
    query.limit(limit);
    return query.find();
  }



  loadItemsForPharmacy(pharmacy: Pharmacy, params: any): Promise<Item[]> {
    let pharmacyItems = pharmacy.get("pharmacyItems");
    let query = pharmacyItems.query();
    query.include('category');
    query.include('subcategory');
    query.equalTo('status', 'Active');

    if (params) {
      if (params.pagination) {
        query.limit(params.pagination.limit);
        query.skip(params.pagination.limit * params.pagination.page);
      }

      if (params.sort) {
        if (params.sort.direction == "asc") {
          query.ascending(params.sort.column);
        }
        if (params.sort.direction == "desc") {
          query.descending(params.sort.column);
        }
      }

      if (params.category) {
        query.equalTo('category', params.category);

        if (params.subcategory) {
          query.equalTo('subcategory', params.subcategory);
        }
      }

      if (params.search) {
        query.contains('canonical', params.search);
      }


    }
    return query.find();
  }

  async loadPharmacyItems(pharmacy): Promise<Item[]> {
    return Parse.Cloud.run('loadPharmacyItemsSkeleton', {
      pharmacy: pharmacy.id
    });
  }

  async generateXeroPharmacyReport(data): Promise<any> {
    return Parse.Cloud.run('xeroModelReportByMonth4Pharamcy', data)
  }

  async generateBrisbanePharmacyReport(data): Promise<any> {
    return Parse.Cloud.run('transactionReportByMonth4Pharmacy', data)
  }

  async searchForPharmacy(searchString): Promise<Pharmacy[]> {
    let queryString = searchString.toLowerCase();
    const query = new Parse.Query(Pharmacy);
    query.contains('canonical', queryString);
    query.equalTo('status', 'Active');
    return query.find();
  }

  public async savePharmacy(data) {
    const pharmacy = new Pharmacy();
    return pharmacy.save(data);
  }

  public async getPharmacyUsername(id: string): Promise<string> {
    return await Parse.Cloud.run('getPharmacyUsername', { id: id });
  }


  //101 - Service
  //BM:GOOGLE:TASK:7 -- This doesn't appear to be used anywhere, see if we can remove it
  // getPharmacyByPostCode(postcode: string): Promise<any> {
  //   return Parse.Cloud.run('getPharmacyBracketModel', { 'post_code': postcode });
  // }

  getPharmacyByAddress(address: any): Promise<any> {
    return Parse.Cloud.run('getPharmacyBracketModel', { 'address': address });
  }

  loadPharmaciesCount(params: any, search: any): Promise<number> {
    const query = new Parse.Query(Pharmacy);
    if (search) {
      query.contains(search.column, search.value);
    }

    if (params) {
      if (params.status) {
        query.equalTo('status', params.status);
      } else {
        query.containedIn('status', ['Active', 'Inactive']);
      }
    }

    return query.count();
  }

  loadPharmacies(pagination: any = { page: 0, limit: 15 }, sort: any = { direction: 'asc', column: 'createdAt' }, params: any, search: any, includeService?: boolean): Promise<Pharmacy[]> {
    let query = new Parse.Query(Pharmacy);
    query.include('user');

    if (includeService) {
      query.include('serviceability');
    }


    if (pagination) {
      query.skip(pagination.page * pagination.limit);
      query.limit(pagination.limit);
    }

    if (sort) {
      if (sort.direction == 'asc') {
        query.ascending(sort.column);
      }

      if (sort.direction == 'desc') {
        query.descending(sort.column);
      }
    }

    if (search) {

      query.contains(search.column, search.value);

    }

    if (params) {
      if (params.status) {

        query.equalTo('status', params.status);

      }
    } else {
      query.containedIn('status', ['Active', 'Inactive']);
    }

    return query.find();
  }

  async loadPharmacy(pagination, sort?: ISort, params?, count: boolean = false) {
    const query = new Parse.Query(Pharmacy);
    query.equalTo('status', 'Active');
    if (params) {
      this.applyParams(query, params);
    }
    if (!count) {
      query.limit(pagination.limit);
      query.skip(pagination.skip);
    }
    if (sort) {
      this.sort(query, sort);
    } else {
      query.descending('createdAt');
    }
    if (count) {
      return query.count();
    }
    return query.find();
  }

  changePharmacyPassword(data) {
    return Parse.Cloud.run('changePharmacyPassword', data);
  }

  //Brand Specific
  loadBrands(pagination: IPagination, sort: ISort, params: any = {}, count = false): Promise<Brand[] | number> {
    const query = new Parse.Query(Brand);
    if (params) {
      this.applyParams(query, params);
    }
    this.applyCategoriesFilter(query, params);
    if (!count) {
      query.limit(pagination.limit);
      query.skip(pagination.skip);
    }


    query.equalTo('status', 'Active');
    if (sort) {
      this.sort(query, sort);
    } else {
      query.ascending('name');
    }

    if (!count) {
      return query.find().then((res) => res, this.catchError);
    } else {
      return query.count().then((res) => res, this.catchError);
    }
  }

  private applyCategoriesFilter(query, params) {
    if (Array.isArray(params.categories)) {
      const categories = params.categories.map((id: string) => {
        const obj = new Category();
        obj.id = id;
        return obj;
      });
      if (categories.length) {
        query.containedIn('categories', categories);
      }
    } else if (params.categories && typeof params.categories === 'string') {
      const category = Category.createWithoutData(params.categories);
      category.id = params.categories;
      query.equalTo('categories', category);
    }
  }


  //Subscribtions Functions

  loadSubscriptionsForCustomer(customer: any): Promise<Subscriptions> {
    const query = new Parse.Query(Subscriptions);
    query.equalTo('customer', customer);
    return query.first();
  }

  //ScriptFlow Functions
  getArchivedOrders(pagination: any = { page: 0, limit: 15 }, sort: any = { direction: 'asc', column: 'createdAt' }, params: any, search: any): Promise<ScriptFlow[]> {
    const query = new Parse.Query(ScriptFlow);
    if (pagination) {
      query.skip(pagination.page * pagination.limit);
      query.limit(pagination.limit);
    }
    if (sort) {
      if (sort.direction == 'asc') query.ascending(sort.column);
      if (sort.direction == 'desc') query.descending(sort.column);
    }
    if (search) query.matches(search.column, search.value, 'i');
    return query.find();
  }

  loadArchiveCount(params: any, search: any): Promise<number> {
    const query = new Parse.Query(ScriptFlow)
    if (search) query.matches(search.column, search.value, 'i');
    return query.count();
  }

  ScriptFlowCount(): Promise<number> {
    return new Parse.Query(ScriptFlow).count();
  }


  //AutoMessageConfiguration Functions
  fetchAllAutoMessageConfigurations(): Promise<AutoMessageConfiguration[]> {
    const query = new Parse.Query(AutoMessageConfiguration);
    return query.findAll();
  }

  fetchAutoMessageConfigurationById(id: string) {
    const query = new Parse.Query(AutoMessageConfiguration)
      .equalTo('objectId', id);
    return query.first();
  }

  async updateAutoMessageTemplate(id: string, template: AutoMessageConfiguration) {
    console.log("at service template!!", template);
    const updateObj = await new Parse.Query(AutoMessageConfiguration).equalTo("objectId", id).first();
    console.log("updated dasfsd template", updateObj);
    updateObj.set("type", template.type);
    updateObj.set("orderStatus", template.orderStatus);
    // updateObj.set("programType", template.programType);
    updateObj.set("alertConfiguration", template.alertConfiguration);
    updateObj.set("status", template.status);
    updateObj.set("partner", template.partner);
    console.log("updated after set", updateObj);
    return await updateObj.save();
  }

  //OrderComments Functions
  loadCommentsForOrder(order: Order): Promise<OrderComment[]> {
    return new Parse.Query(OrderComment).equalTo('order', order).ascending('createdAt').find();
  }

  loadLastCommentsForOrder(order: Order): Promise<OrderComment> {
    return new Parse.Query(OrderComment).equalTo('order', order).descending('updatedAt').first();
  }

  //Partner Functions
  loadAllPartnerUsers(): Promise<Partner[]> {
    const query = new Parse.Query(Partner);
    query.ascending('name');
    return query.find();
  }

  async getPartnerForOrder(order: Order): Promise<Partner | undefined> {
    const result = await new Parse.Query(PartnerOrder)
      .equalTo('order', order)
      .include('partner')
      .first();
    return result?.partner as Partner || undefined;
  }

  async getPartnerByShortId(shortId: string): Promise<Partner> {
    return await new Parse.Query(Partner)
      .equalTo('shortId', shortId)
      .first();
  }

  //PharmacyComment Functions
  loadPharmacyCommentForOrder(order: Order): Promise<PharmacyComment[]> {
    return new Parse.Query(PharmacyComment).equalTo('order', order).ascending('createdAt').find();
  }
  getCommentSubscribtion(): any {
    return new Parse.Query(PharmacyComment).subscribe();
  }

  loadAllPharmacyComment(): Promise<PharmacyComment[]> {
    // last seven day messages
    const now = new Date();
    now.setDate(now.getDate() - 7);
    return new Parse.Query(PharmacyComment)
      .greaterThanOrEqualTo('createdAt', now)
      .include('order')
      .equalTo('resolved', false)
      .descending('createdAt')
      .find();
  }

  async updateAction(resolved: boolean, commentID: string) {
    const updateObj = await PharmacyComment.getFByID(commentID);
    return await updateObj.save({
      resolved
    });
  }


  //EventTracking Functions

  loadEventsForOrder(order: Order): Promise<EventTracking[]> {
    return new Parse.Query(EventTracking)
      .equalTo('order', order)
      .ascending('createdAt')
      .find();
  }

  loadEventsForCustomer(customer: User): Promise<EventTracking[]> {
    return new Parse.Query(EventTracking)
      .equalTo('customer', customer)
      .containedIn("source", ["Customer Name Updated", "Customer Email Updated", "Subscription Token Removed", "Subscription date updated"])
      .ascending('createdAt')
      .find()
  }

  getCustomerNotes(customerUser: User) {
    return new Parse.Query(EventTracking)
      .equalTo('customer', customerUser)
      .equalTo('source', 'Customer Notes (Internal Use Only)')
      .findAll()
  }

  //average time taken by admin to approve script order in last two days
  async avgTimeForApproval() {
    // const currentUser = Parse.User.current();
    // console.log(currentUser);

    //last two days 
    let now = new Date();
    now.setDate(now.getDate() - 2);

    const query = new Parse.Query(EventTracking);
    //query.equalTo('user', currentUser);
    query.equalTo('action', 'Status updated from: Unpaid to Approved');
    query.include('order');
    query.limit(1000);
    query.greaterThan('createdAt', now);

    const values = await query.find();
    if (!values) { return 0; };

    //order count in the timeframe of 9am-5pm
    let orderCount = 0;
    //total time in minutes
    let totalTime = 0;
    console.log(values.length);

    values.map(value => {

      const orderCreatedAt = (value.order as Order).createdAt;
      const eventCreatedAt = value.createdAt;

      //check if they are same date
      if (orderCreatedAt.getDate() != eventCreatedAt.getDate()) { return; };

      const orderCreatedAtInMin = this.convertTimeToMinutes(orderCreatedAt);

      //order between 9:00am-20:30pm
      if (orderCreatedAtInMin >= 540 && orderCreatedAtInMin <= 1230) {
        orderCount++;
        const timeDiff = eventCreatedAt.getTime() - orderCreatedAt.getTime();
        const resultInMinutes = Math.round(timeDiff / 60000);
        totalTime += resultInMinutes;
      }
    })
    return Math.round(totalTime / orderCount);
  }

  convertTimeToMinutes(date) {
    return ((date.getHours() * 60) + date.getMinutes());
  }



  getPharmacyNotes(pharmacyId: string): Promise<EventTracking[]> {
    return new Parse.Query(EventTracking)
      .equalTo('pharmacy', Pharmacy.pointer(pharmacyId))
      .equalTo('source', 'Pharmacy Notes (Internal Use Only)')
      .findAll();
  }

  loadEventsForPharmacy(pharmacyId: string, limit = null) {
    const pharmacyPointer: C2U.Pointer<"Pharmacy"> = {
      __type: 'Pointer',
      className: 'Pharmacy',
      objectId: pharmacyId
    };
    const query = new Parse.Query(EventTracking);
    query.equalTo('pharmacy', pharmacyPointer);
    if (limit) query.limit(limit);
    return query.findAll();
  }
  loadEventsForSMS(customerId: string, limit = null) {
    const customerPointer: C2U.Pointer<"_User"> = {
      __type: 'Pointer',
      className: '_User',
      objectId: customerId
    };
    console.log("get event data! for customer!!", customerPointer);
    const query = new Parse.Query(EventTracking);
    query.equalTo('customer', customerPointer);
    query.equalTo('source', 'AWS Custom SMS');
    if (limit) query.limit(limit);
    return query.findAll();
  }

  //CustomerSessionDetails Functions
  async loadForCustomer(id: string): Promise<CustomerSessionDetails> {
    let result = await Parse.Cloud.run('loadSessionForCustomer', {
      id: id
    });
    return result as CustomerSessionDetails;

  }

  getOne(user: User): Promise<CustomerSessionDetails> {


    if (user) {

      const query = new Parse.Query(CustomerSessionDetails);
      query.include('customer');
      query.equalTo('customer', Parse.User.current());
      return query.first();
    } else {
      return this.getCustomerSessionDetails();
    }

  }

  saveSession(session: CustomerSessionDetails): Promise<CustomerSessionDetails> {
    let user = User.current();
    if (user) {

      return session.save();
    } else {

      return this.setCustomerSessionDetails(this);
    }
  }

  getPostalCode(data) {
    let post_code = "";
    let address_components = data.address.address_components;
    address_components.forEach((val, i) => {
      let types = val.types;
      types.forEach((type, y) => {
        if (type == "postal_code") {
          post_code = val.short_name;
        }
      })
    })
    return post_code;
  }

  async setCustomerSessionDetails(val: any) {

    localStorage.setItem('customerSessionDetails', JSON.stringify(val));
    return val;
  }


  async getCustomerSessionDetails() {
    //return await this.storage.get('customerSessionDetails');
    let localData = JSON.parse(localStorage.getItem('customerSessionDetails'));
    if (localData) {
      let result = new CustomerSessionDetails();
      result.address = localData.address;
      result.pharmacy = localData.pharmacy;
      return result;
    }
    return null;

  }

  async adminSaveAddress(id: string, address: any): Promise<CustomerSessionDetails> {
    console.log("SAVING SOMETHING");
    console.log(this);
    try {
      return (await Parse.Cloud.run('adminSave', { id: id, address: address })) as CustomerSessionDetails;
    } catch (error) {
      return error;
    }
  }

  async adminNewSessionDetail(userid: string) {
    try {
      return Parse.Cloud.run('adminNewSession', { userid: userid });
    } catch (error) {
      return error;
    }
  }



  loadProfilesForCustomer(user: User): Promise<CustomerMedicalProfile[]> {

    const query = new Parse.Query(CustomerMedicalProfile);
    query.equalTo('customer', user);
    query.descending('createdAt');
    return query.find();
  }

  loadById(id): Promise<CustomerMedicalProfile> {
    const query = new Parse.Query(CustomerMedicalProfile);
    query.equalTo('objectId', id);
    return query.first();
  }

  toString(medicalProfile: CustomerMedicalProfile): string {
    return `${medicalProfile.name}, ${medicalProfile.medicare_card_number}, ${medicalProfile.concession_card_number}, ${medicalProfile.age}, ${medicalProfile.weight}`;
  }


  //DeliveryBrackets Functions
  loadDeliveryBrackets(): Promise<DeliveryBrackets[]> {
    return new Parse.Query(DeliveryBrackets).limit(1000).find();
  }

  getByName(name: string): Promise<DeliveryBrackets> {
    return new Parse.Query(DeliveryBrackets).equalTo('name', name).first();
  }

  //Promotion Functions
  newPromotionDefault(): Promotion {
    const promotion = new Promotion();
    promotion.type = "percentage";
    promotion.value = 10;
    promotion.maxNrUses = 0;
    promotion.active = true;
    return promotion;
  }

  promotionCount(): Promise<number> {
    return new Parse.Query(Promotion).count();
  }
  async loadPromotion(): Promise<Promotion[]> {
    const limit = await this.promotionCount();
    console.log("The limit is = " + limit);

    return new Parse.Query(Promotion).descending('createdAt').limit(limit).find();
  }
  //promotion isactive isnotexpired.
  loadValidPromotion(): Promise<Promotion[]> {
    //includes today's date in iso format
    var start = moment(new Date());
    start.startOf('day');

    console.log(start);

    return new Parse.Query(Promotion).equalTo('active', true).greaterThanOrEqualTo('expiry', start.toDate()).limit(500).find();
  }


  //PublicHoliday Functions
  loadPublicHolidays(): Promise<PublicHolidays[]> {
    const query = new Parse.Query(PublicHolidays);
    query.greaterThanOrEqualTo('date', new Date());
    query.ascending('date');
    return query.find();
  }

  next14DaysPublicHolidays(): Promise<PublicHolidays[]> {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0); //Start of day

    let endDate = new Date();
    endDate.setDate(endDate.getDate() + 14);
    endDate.setHours(23, 59, 59, 999); //End of day

    const query = new Parse.Query(PublicHolidays);
    query.greaterThanOrEqualTo('date', new Date(startDate.toUTCString()));
    query.lessThanOrEqualTo('date', new Date(endDate.toUTCString()));
    query.ascending('date');

    return query.find();
  }


  //PharmacyDay Functions

  loadDayPharmacyDay(day): Promise<PharmacyDay> {
    let query = new Parse.Query(PharmacyDay);
    query.equalTo('day', day);
    return query.first();
  }

  loadDayForPharmacyPharmacyDay(day, pharmacy): Promise<PharmacyDay> {
    let query = new Parse.Query(PharmacyDay);
    query.equalTo('day', day);
    query.equalTo('pharmacy', { "__type": "Pointer", "className": "_User", "objectId": pharmacy });
    return query.first();
  }

  loadAllPharmacyDay(): Promise<PharmacyDay[]> {
    let query = new Parse.Query(PharmacyDay);
    query.ascending('displayOrder');
    return query.find();
  }

  loadAllForPharmacyPharmacyDay(pharmacy: Pharmacy): Promise<PharmacyDay[]> {
    console.log(pharmacy);
    let query = new Parse.Query(PharmacyDay);
    query.ascending('displayOrder');
    query.equalTo('pharmacy', pharmacy.user as User);
    return query.find();
  }


  getShiftsPharmacyDay(day: PharmacyDay): Promise<PharmacyShift[]> {
    let relation = day.shifts;
    let result = relation.query();
    result.ascending('cutoffn');
    result.includeAll();
    return result.find();
  }

  getShiftsForPharmacyPharmacyDay(day: PharmacyDay, pharmacy: Pharmacy): Promise<PharmacyShift[]> {
    let relation = day.shifts;
    let result = relation.query();
    result.ascending('cutoffn');
    result.includeAll();
    result.equalTo('pharmacy', pharmacy.user as User);
    return result.find();
  }

  //PromotionCustomer Functions

  findByCustomerId(id): Promise<PromotionCustomer[]> {
    return new Parse.Query(PromotionCustomer).equalTo('customer', id).includeAll().find();
  }
  findUnusedPromotion(id): Promise<PromotionCustomer[]> {
    return new Parse.Query(PromotionCustomer).equalTo('customer', id).includeAll().equalTo("used", false).find();
  }

  //PharmacyReport Functions
  async releasePharmacyReportForMonth(data: any) {
    return Parse.Cloud.run('releasePharmacyReportForMonth', data);
  }

  async generateReportForPharmacy(selectedMonthYear: string, pharmacy: any, pharmacyUser: any) {
    const query = new Parse.Query(PharmacyReport);
    if (pharmacy) query.equalTo('pharmacy', pharmacy);
    if (pharmacyUser) query.equalTo('pharmacyUser', pharmacyUser)
    query.equalTo('reportingMonthYear', selectedMonthYear);
    query.limit(10000)
    return this.formatData(await query.find())
  }

  formatData(pharmacyReport: PharmacyReport[]) {

    console.log(pharmacyReport);

    const formattedJSON = []
    try {
      pharmacyReport.map(order => {
        order.OTCItems.map(o => {
          // console.log(o);

          formattedJSON.push({
            "Date": moment(order.orderCreationDate).format('DD/MM/YYYY'),
            "Order ID": order.orderID.includes('#') ? order.orderID.substring(1) : order.orderID,
            "Pharmacy": order.pharmacyName,
            "C2U Fulfilled": !order.selfManaged,
            "Product Name": o.productName,
            "Product Price": o.productPrice,
            "Product Quantity": o.productQuantity,
            "Product Total": o.productTotal,
            "Product Type": o.productType,
            "PBS Code": '',
            "Pack Quantity": '',
            "PBS Rebate": 0,
            "Amount Paid by Government": 0,
            "Pharmacy Cost of Goods": o.pharmaCOG,
            "Brand Premium": '',
            "Total Due Ex GST": o.paymentDueExGST,
            "less GST on Platform Fee": o.GST,
            "Amount Remitted": o.amountRemitted
          })
        })
        order.prescriptionItems.map(p => {
          console.log(p);
          formattedJSON.push({
            "Date": moment(order.orderCreationDate).format('DD/MM/YYYY'),
            "Order ID": order.orderID,
            "Pharmacy": order.pharmacyName,
            "C2U Fulfilled": !order.selfManaged,
            "Product Name": p.productName,
            "Product Price": p.productPrice,
            "Product Quantity": p.productQuantity,
            "Product Total": p.productTotal,
            "Product Type": p.productType,
            "PBS Code": p.pbsCode,
            "Pack Quantity": p.packQuantity,
            "PBS Rebate": p.pbsRebate,
            "Amount Paid by Government": p.amountPaidByGovernment,
            "Pharmacy Cost of Goods": p.pharmaCOG,
            "Brand Premium": p.brandPremiumInc,
            "Total Due Ex GST": p.paymentDueExGST,
            "less GST on Platform Fee": p.GST,
            "Amount Remitted": p.amountRemitted
          })
        })
        if (order.deliveryFee > 0) {
          formattedJSON.push({
            "Date": moment(order.orderCreationDate).format('DD/MM/YYYY'),
            "Order ID": order.orderID,
            "Pharmacy": order.pharmacyName,
            "C2U Fulfilled": !order.selfManaged,
            "Product Name": 'Delivery fee subsidy',
            "Product Price": 0,
            "Product Quantity": 0,
            "Product Total": 0,
            "Product Type": '',
            "PBS Code": '',
            "Pack Quantity": '',
            "PBS Rebate": 0.0,
            "Amount Paid by Government": 0,
            "Pharmacy Cost of Goods": 0.0,
            "Brand Premium": '',
            "Total Due Ex GST": 5,
            "less GST on Platform Fee": 0,
            "Amount Remitted": 5
          })
        }
      })
    } catch (error) {
      console.log(error);

    }

    console.log(formattedJSON);

    return formattedJSON;
  }


  //PharmacyShiftDelivery Functions

  async createPharmacyShiftDelivery(data) {
    return await Parse.Cloud.run("createPharmacyShiftDelivery", data)
  }

  async getDeliveryShifts() {

    let currentDateFloor = moment().startOf('day').toDate();

    let query = new Parse.Query(PharmacyShiftDelivery);
    query.greaterThanOrEqualTo('date', currentDateFloor);
    return await query.find();
  }


  //PharmacyException Functions

  loadPharmacyExceptions(): Promise<PharmacyException[]> {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0); //Start of day
    const query = new Parse.Query(PharmacyException);
    query.greaterThanOrEqualTo('date', new Date(startDate.toUTCString()));
    query.ascending('date');
    return query.find();
  }

  checkForException(pharmacy): Promise<PharmacyException> {

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date();
    endDate.setHours(23, 59, 59, 999);

    const query = new Parse.Query(PharmacyException);
    query.greaterThanOrEqualTo('date', new Date(startDate.toUTCString()));
    query.lessThanOrEqualTo('date', new Date(endDate.toUTCString()));
    query.equalTo('pharmacy', pharmacy.user);


    return query.first();
  }


  getExceptionsForPharmacyCount(pharmacy: Pharmacy) {
    return new Parse.Query(PharmacyException).equalTo('pharmacy', pharmacy.user as User).descending('date').count();
  }

  getExceptionsForPharmacy(pharmacy: Pharmacy) {
    return new Parse.Query(PharmacyException).equalTo('pharmacy', pharmacy.user as User).greaterThanOrEqualTo('date', new Date()).descending('date').find();
  }

  getExceptionForPublicHoliday(pharmacy: Pharmacy, publicHoliday: any) {
    return new Parse.Query(PharmacyException).equalTo('pharmacy', pharmacy.user as User).equalTo('publicHoliday', publicHoliday).first();
  }

  //SlideImage Functions
  loadSlideImages(): Promise<SlideImage[]> {
    const query = new Parse.Query(SlideImage);
    query.equalTo('isActive', true)
    query.ascending('order');
    return query.find();
  }

  getHubPharmacies(): Promise<HubPharmacy[]> {
    return new Parse.Query(HubPharmacy).include("pharmacy").findAll();
  }

  getPartners(): Promise<Partner[]> {
    return new Parse.Query(Partner).findAll();
  }

  async getTokenInfo(token : string){
    try {
        return await Parse.Cloud.run('getERXFromURL', 
        { 
          code : token
        });
    } catch (error) {
        return error;
    }
  }

  async saveTokenToSubscriptions(token: TSubscriptionToken) {
      console.log("Saving subscription", token);
      try {
          return await Parse.Cloud.run('saveEmailERXToken',token);
      } catch (error) {
          return error;
      }
  }

  async getPreviousPrescriptionOrders(customer: User, order: Order) {
    return await Order.Query()
      .equalTo('customer', customer)
      .lessThan('createdAt', order.createdAt)
      .exists('metadata.subscription')
      .notEqualTo('status', 'Cancelled')
      .ascending('createdAt')
      .find();
  }

  public async fetchAllPharmacyCommentTemplates(status: "Active" | "Inactive"){
    const query = new Parse.Query(PharmacyCommentsTemplate);
    if(status){
      query.equalTo('status', status);
    }
    return query.limit(500).find();
  }

  public async fetchPharmacyCommentTemplates(){
    const query = new Parse.Query(PharmacyCommentsTemplate);
    return query.limit(500).find();
  }

  public fetchMiscStrings(): Promise<MiscString[]> {
    const query = new Parse.Query(MiscString);
    return query.findAll();
  }

  public fetchMiscStringsByType(type: 'customerTag' | 'orderTag' | 'pharmacyTag'): Promise<MiscString[]> {
    const query = new Parse.Query(MiscString);
    query.equalTo('type', type);
    return query.findAll();
  }

  public setNewSubscriptionAndAgendaJob(params) {
    return Parse.Cloud.run('scheduleNextSubscriptionRun', params);
  }
  
}
