import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'c2u-tuple-row',
  templateUrl: './c2u-tuple-row.component.html',
  styleUrls: ['./c2u-tuple-row.component.scss']
})
export class C2uTupleRowComponent implements OnInit {

  @Input() label: string;
  @Input() timestamp: string;
  @Input() value: string = "Value";
  @Input() large: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
