import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'inbox-message',
  templateUrl: './inbox-message.component.html',
  styleUrls: ['./inbox-message.component.scss']
})
export class InboxMessageComponent {

  @Input() comment: any;//Sort this out
  @Input() minimal: boolean = false;

  @Output() viewEvent = new EventEmitter<any>();
  @Output() resolvedEvent = new EventEmitter<any>();

  emitView() {
    this.viewEvent.emit(this.comment);
  }

  emitResolved() {
    this.resolvedEvent.next(this.comment);
  }

}
