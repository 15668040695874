import {Component, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

const moment = _rollupMoment || _moment;

export const MONTH_YEAR_FORMAT = {
    parse: {
        dateInput: 'MM/YYYY'
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

@Component({
    selector: 'month-year-picker',
    templateUrl: './month-year-picker.component.html',
    styleUrls: ['./month-year-picker.component.scss'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_FORMAT
        }
    ],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [
        MatDatepickerModule,
        MatInputModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule
    ]
})

export class MonthYearPickerComponent {
    constructor() { }
    @Output() valueChange: EventEmitter<any> = new EventEmitter();
    date = new FormControl()
    
    setMonthYear(val: Moment, datepicker: MatDatepicker<Moment>) {
        console.log('val', val)
        const ctrlValue = moment();
        ctrlValue.month(val.month());
        ctrlValue.year(val.year());
        this.date.setValue(ctrlValue);
        this.valueChange.emit(this.date.value);
        datepicker.close();
    }



}