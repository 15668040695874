import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './services/interceptors/index';
import { ErrorHandlerService } from './services/error.handler.service';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogLeaveFeedback } from './dialogs/leave-feedback/leave-feedback.page';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { DialogUserProfile } from './dialogs/user-profile/user-profile.page';
import { CreateEmailSubscriptionComponent } from './dialogs/create-email-subscription/create-email-subscription.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewTimelineDetailsComponent } from './dialogs/view-timeline-details/view-timeline-details/view-timeline-details.component';
import { NotificationCenterComponent } from './layout/notification-center/notification-center.component';
import { DialogPharmacyNotification } from './dialogs/pharmacy-notification/pharmacy-notification.component';
import { CreatePharmacyComplianceComponent } from './dialogs/create-pharmacy-compliance/create-pharmacy-compliance.component';

// hubspot chat variables
declare global {
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      load: () => void;
    };
  };
  var hsConversationsSettings: {}
}

const config: SocketIoConfig = { url: environment.socketIo, options: {
  autoConnect: false,
}};




@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    DialogLeaveFeedback,
    DialogUserProfile,
    CreateEmailSubscriptionComponent,
    ViewTimelineDetailsComponent,
    NotificationCenterComponent,
    DialogPharmacyNotification,
    CreatePharmacyComplianceComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    MatSnackBarModule,
    SharedModule,
    BrowserAnimationsModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    AgGridModule,
    SocketIoModule.forRoot(config),
    ReactiveFormsModule,
    MatDialogModule
  ],
  providers: [
    ErrorHandlerService,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
