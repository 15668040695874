import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'c2u-button',
  templateUrl: './c2u-button.component.html',
  styleUrls: ['./c2u-button.component.scss']
})
export class C2uButtonComponent implements OnInit {

  @Input() label: string = "Button Text";
  @Input() dark: boolean = false;
  @Input() disabled: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
