<div class="row" [matTooltip]="(showControls && currentQuant == 0) ? 'This item will be removed from the order' : ''" [ngClass]="{'remove' : showControls && currentQuant == 0}">
    <div class="prod-image">
        <img [src]="itemSrc">
    </div>
    <div class="details">
        <p class="name">{{item.title}}</p>
    </div>
    <div class="controls" *ngIf="!showControls">
        <p class="label">
            {{currentQuant}} x A{{item.price.unit_price.formatted_amount}}
        </p>
    </div>
    <div class="controls" *ngIf="showControls">
        <button [matTooltip]="currentQuant == 1 ? 'Mark as sold out' : 'Reduce quantity by 1'" *ngIf="currentQuant > 0" (click)="minusClick()">
            <img src="../../../../assets//icons/pink-minus.svg">
        </button>
        <p class="label">
            {{currentQuant}} x A{{item.price.unit_price.formatted_amount}}
        </p>
        <button [matTooltip]="currentQuant != maxQuant - 1 ? 'Increase quantity by 1' : 'Restore back to original value'" *ngIf="currentQuant < maxQuant"  (click)="plusClick()">
            <img src="../../../../assets//icons/pink-plus.svg">
        </button>
    </div>
    <div class="prices">
        <p class="main">A{{(currentQuant * item.price.unit_price.amount/100) | currency }}</p>
        <p class="label">inc. UBER charges</p>
    </div>
    <div class="remove" *ngIf="showControls">
        <button matTooltip="Mark as sold out" *ngIf="currentQuant > 0" (click)="removeClick()">
            <img src="../../../../assets//icons/pink-x.svg">
        </button>
    </div>
</div>