import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

export interface eventTimeline {
  timestamp: Date;
  action: string;
  user: string;
  source:string;
  payload:any;
}

@Component({
  selector: 'c2u-event-tracking-table',
  templateUrl: './c2u-event-tracking-table.component.html',
  styleUrls: ['./c2u-event-tracking-table.component.scss']
})
export class C2uEventTrackingTableComponent implements OnInit {

  //Input
  @Input() data:any  =[];

  @Output() rowClicked = new EventEmitter<any>();

 
  public timelineTableData: any[] =[];
  public timelineDataSource: MatTableDataSource<eventTimeline>;
  public timelineHeaders:string[] = ['timestamp', 'action','user'];

  constructor() {
   }

  ngOnInit(): void {
    this.timelineTableData = this.data.map(element => this.timelineRowTransform(element));
    this.timelineDataSource = new MatTableDataSource(this.timelineTableData);
  }

  timelineRowTransform(event):eventTimeline {
    return {
      timestamp: event.createdAt ? event.createdAt: event.timestamp ,
      action: event.action,
      user: event.userName? event.userName: event.user,
      source: event.source,
      payload:event.payload
    }
  }

  clickAction(row:any) {
    if(row) {
      console.log(row);
      
      this.rowClicked.emit(row);

    }
  }

}
