<h2 mat-dialog-title>Edit Pharmacy Fulfilment Methods</h2>
<mat-dialog-content>
    <form [formGroup]="form">
        <div class="row">
            <mat-checkbox formControlName="postal">Postal</mat-checkbox>
        </div>
        <div class="row">
            <mat-checkbox formControlName="onDemand">On Demand</mat-checkbox>
            <mat-form-field *ngIf="form.get('onDemand').value">
                <mat-label>Buffer</mat-label>
                <input matInput type="number" placeholder="Buffer (in minutes)" formControlName="onDemandBufferMinutes">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-checkbox formControlName="standard">Standard</mat-checkbox>
            <mat-form-field *ngIf="form.get('standard').value">
                <mat-label>Delivery Cutoff</mat-label>
                <input matInput type="number" placeholder="Delivery Cutoff (in minutes)" formControlName="standardDeliveryCutoffMinutes">
            </mat-form-field>
        </div>
        <div class="row">
            <mat-checkbox formControlName="clickAndCollect">Click and Collect</mat-checkbox>
            <mat-form-field *ngIf="form.get('clickAndCollect').value">
                <mat-label>Buffer</mat-label>
                <input matInput type="number" placeholder="Buffer (in minutes)" formControlName="clickAndCollectBufferMinutes">
            </mat-form-field>
        </div>
    </form>
    <span class="buttons">
        <button mat-flat-button color="info" (click)="submit()" [disabled]="!form.valid && form.dirty">Submit</button>
        <button mat-flat-button color="accent" (click)="close()">Cancel</button>
    </span>
</mat-dialog-content>