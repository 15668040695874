<form class="custom-form">
    <mat-form-field class="custom-form-field" appearance="fill" style="width: 100%;">
        <mat-label>{{placeholder}}</mat-label>
        <mat-chip-grid #stringGrid>
            <mat-chip-row [class]="tag?.metadata?.color" *ngFor="let tag of activeTags" (removed)="remove(tag)">
                {{ tag.value }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-row>
        </mat-chip-grid>
        <ng-container *ngIf="autocomplete">
            <input
                name="currentString"
                [placeholder]="placeholder"
                #stringInput
                [(ngModel)]="currentStringValue"
                [matChipInputFor]="stringGrid"
                [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                (matChipInputTokenEnd)="add($event)"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let string of filteredStrings" [value]="string">
                    {{ string }}
                </mat-option>
            </mat-autocomplete>
        </ng-container>
        <ng-container *ngIf="!autocomplete">
            <input
                name="currentString"
                [placeholder]="placeholder"
                #stringInput
                [(ngModel)]="currentStringValue"
                [matChipInputFor]="stringGrid"
                [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                (matChipInputTokenEnd)="add($event)"
            />
        </ng-container>
    </mat-form-field>
</form>