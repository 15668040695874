import { MenuItem } from "./sidebar-menu";

export const PHARMACY_SIDEBAR_MENU_ITEMS: MenuItem[] = [
  {
    name: 'Orders',
    url: '/p-orders',
    categories: [
      {
        categoryName: null,
        nestedItems: [
          {
            name: 'Active Orders',
            url: '/p-orders/active',
          },
          // {
          //   name: 'New',
          //   url: '/p-orders/new',
          // },
          // {
          //   name: 'In Progress ',
          //   url: '/p-orders/inprogress'
          // },
          {
            name: 'C2U Ready',
            url: '/p-orders/ready'
          },
          {
            name: "C2U Shipped Out",
            url: '/p-orders/shippedout'
          },
          // {
          //   name: 'Historical',
          //   url: '/p-orders/historical'
          // }
        ]
      },
      {
        categoryName: 'Past Orders',
        nestedItems: [
          {
            name: 'C2U',
            url: '/p-orders/historical'
          },
          {
            name: 'Market Place',
            url: '/p-orders/market-place-historical'
          }
        ]
      },
      {
        categoryName:'Need Help ?',
        nestedItems: [
          {
            name: 'View our training videos',
            url: '/p-orders/training-videos',
            
          }
        ]
      },
      {
        categoryName: null,
        nestedItems: [
          {
            name: 'Script Flow',
            url: '/p-orders/script-flow',

          }
        ]
      }
      // {
      //   categoryName:'Market Place',
      //   nestedItems: [
      //     // {
      //     //   name:'Orders',
      //     //   url:'/p-orders/market-place'
      //     // },
      //     {
      //       name:'Archive',
      //       url:'/p-orders/market-place-historical'
      //     }
      //   ]
      // }
    ]
  },
  // {
  //   name: "Deliveries",
  //   url: "/p-delivery",
  //   categories: [
  //     {
  //       categoryName: null,
  //       nestedItems: [
  //         {
  //           name: "Delivery View",
  //           url: '/p-delivery/deliveries'
  //         },
  //       ]
  //     }
  //   ]
  // },
  {
    name: "Catalogue",
    url: "/p-items",
    categories: [
      {
        categoryName: null,
        nestedItems: [
          {
            name: "Items",
            url: '/p-items/items'
          },

        ]
      }
    ]
  },
  {
    name: 'Settings',
    url: '/p-settings',
    categories: [
      {
        categoryName: null,
        nestedItems: [
          {
            name: "General Settings",
            url: '/p-settings/manage'
          },
          {
            name: "Pharmacy Details",
            url: '/p-settings/details'
          }
        ]
      }
    ]
  }
]