<h2 mat-dialog-title>Add Feedback</h2>
<mat-dialog-content>
    <form [formGroup]='feedbackForm'>
        <mat-form-field>
            <mat-label>Area Affected</mat-label>
            <input matInput type="text" formControlName="area" [(ngModel)]="feedback.area">
            <button mat-button *ngIf="feedback.area" matSuffix 
                aria-label="Clear" (click)="feedback.area=''">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Importance (0 - lowest to 5 - critical)</mat-label>
            <input matInput type="number" formControlName="importance" [(ngModel)]="feedback.importance">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Describe your issue</mat-label>
            <textarea matInput formControlName='description' cdkTextareaAutosize
                                        cdkAutosizeMinRows="5" [(ngModel)]="feedback.description"
                                        placeholder="Enter description here..."></textarea>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="accent" (click)="saveFeedback()">Save</button>
</mat-dialog-actions>