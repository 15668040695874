import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';
import { PharmacyComment } from '@chemist2u/types-client/C2U/ParseObjects/index.client';


@Component({
  selector: 'inbox-folder',
  templateUrl: './inbox-folder.component.html',
  styleUrls: ['./inbox-folder.component.scss'],
})
export class InboxFolderComponent {

  @Input() comments: PharmacyComment[] = [];
  @Output() viewOrderEvent = new EventEmitter<PharmacyComment>();
  @Output() resolveEvent = new EventEmitter<PharmacyComment[]>();

  constructor(private cdr: ChangeDetectorRef){

  }

  orderedComments: any = {};

  threadOpen: {[key: string]: boolean} = {};


  orderIds: string[] = [];
  toggleStates: {[key: string]: boolean} = {};


  ngOnInit() {
    this.setupFolder();
  }

  ngOnChanges() {
    this.setupFolder();
  }

  setupFolder() {
    const orderedComments = {}
    this.comments.forEach(comment => {
      const orderId = comment.order.orderID;
      if (!orderedComments[orderId]) {
        orderedComments[orderId] = [];
      }
      orderedComments[orderId].push(comment);
      orderedComments[orderId] = orderedComments[orderId].sort((a, b) => a.updatedAt < b.updatedAt ? -1 : 1);
      this.toggleStates[orderId] = false;
    });

      this.orderIds = Object.keys(orderedComments);

      this.orderIds.filter(o => !!this.orderedComments[o]?.length).forEach(o => {
        this.threadOpen[o] = false;
      })

      this.orderedComments = orderedComments;
  }

  bsFix = true;
  async resolveOrder(event, orderId) {
    event.stopPropagation();
    const result = await Swal.fire({
      title: "Resolved?",
      text: "Are you sure you want to make this thread as resolved?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#f96332",
    });
    
    if (result.isConfirmed) {
      this.resolveEvent.emit(this.orderedComments[orderId]);
      this.toggleStates[orderId] = true;
    } else {
      this.toggleStates[orderId] = false;
    }
    this.bsFix = false;
    setTimeout(() => {
      this.bsFix = true;
    });
    this.cdr.detectChanges();
    
  }

  viewOrder(comment) {
    this.viewOrderEvent.emit(comment);
  }

  resolveComment(comment) {
    this.resolveEvent.emit([comment]);
  }

}
