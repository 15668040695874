import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'c2u-order-comment-grid',
  templateUrl: './c2u-order-comment-grid.component.html',
  styleUrls: ['./c2u-order-comment-grid.component.scss']
})
export class C2uOrderCommentGridComponent implements OnInit {

  //inputs
  @Input() comments: any = [];
  

  //outputs
  @Output() editGridComment = new EventEmitter<any>();
  @Output() deleteGridComment = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this
  }

  editRowComment(comment:any) {
    console.log(comment);
    this.editGridComment.emit(comment);
  }

  deleteRowComment(comment:any) {
    console.log(comment);
    this.deleteGridComment.emit(comment)
  }

}
