import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Parse from 'parse';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})

export class PharmacyAuthGuard  {
    private $auth = inject(AuthService);
    constructor(private router: Router) {}

    canActivate() {
        const currentUser = Parse.User.current();
        if(currentUser && currentUser.authenticated()) {
            const userRole = this.$auth.getRole();
            if (userRole === 'Pharmacy') return true;
            if (userRole === 'Admin') {
              this.router.navigate(['/'],)
              return false;
            } else {
              this.$auth.logout();
              this.router.navigate(['/login']);
              return false;
            }
        }
        this.router.navigate(['/login'],)
        return false;
    }
}