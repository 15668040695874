import { Component, OnInit, Input, Injector } from "@angular/core";

import { Router } from "@angular/router";
import * as moment from 'moment';

type OzempicRecord  = {
    orderId: string;
    lastPurchaseDate: Date;
    status: string;
    name: string;
}

@Component({
    selector: "customer-ozempic-tracker",
    templateUrl: './customer-ozempic-tracker.component.html',
    styleUrls: ['./customer-ozempic-tracker.component.scss']
})
export class CustomerOzempicTrackerComponent implements OnInit {

    router;

    @Input() customer;


    showRecentOrders: boolean = false;
    recentOrders: OzempicRecord[] =[] ;


    constructor(
        injector: Injector
    ) {
        this.router = injector.get(Router);
    }

    ngOnInit(): void {
        const meta = this.customer.get('meta');
        if (meta?.ozempic) {
            const now = new Date();
            const recentOzempics = meta.ozempic.filter(o => {
                const difference = now.getTime() - o.lastPurchaseDate.getTime();
                const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
                if (daysDifference <= 21) return true;
                return false;
            })
            if (recentOzempics.length){ 
                this.recentOrders = recentOzempics.map(record => {
                    record.message = moment(record.lastPurchaseDate).format('DD/MM/YYYY') + " | " + record.name + " | " + record.status;
                    return record;
                });
            }
            else this.recentOrders = [];
        }
    }

    showOrders() {
        this.showRecentOrders = !this.showRecentOrders;
    }

    closeOrders() {
        this.showRecentOrders = false;
    }

    async goToOrder(record: OzempicRecord) {
        window.open(this.router.serializeUrl(this.router.createUrlTree(['orders/manage/' + record.orderId])), '_blank');
    }
}
