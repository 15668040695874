import { inject, Injectable } from '@angular/core';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import * as Parse from 'parse';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private $state = inject(StateService);

  constructor() { }


  public recoverPassword(email) {
    return Parse.User.requestPasswordReset(email);
  }

  public getRole() {
    return localStorage.getItem('ROLE');
  }

  async logout() {
    const res = await Parse.User.logOut();
    this.$state.publicLoggedInSubject.next(false);
    this.$state.isLoggedIn.next(false);
    this.$state.isPharmacyLoggedIn.next(false);
    localStorage.removeItem('ROLE');
    return res;
  }

  async signIn(data: any = {}): Promise<User> {

    try {
      const userRole = await Parse.Cloud.run('isUserType', { username: data.username });
      if (!userRole) throw "Invalid username or password. Please try again";
      const roleName = userRole.get("name");
      if (!roleName && !(roleName == 'Pharmacy' || roleName == 'Admin')) throw new Error("User with role: " + roleName + " can't use this service");
      const user = await Parse.User.logIn(data.username, data.password);
      localStorage.setItem('ROLE', roleName);
      if (roleName == 'Pharmacy') this.$state.isPharmacyLoggedIn.next(true);
      if (roleName == 'Admin') this.$state.isLoggedIn.next(true);
      this.$state.publicLoggedInSubject.next(true);
      return user as User;
    } catch (error) {
      throw error;
    }
  }

  


  public async restoreSesion() {

    try {
      const user = await User.current().fetch() as User;

      if (user !== null && user.authenticated()) {
        const isAdmin = await Parse.Cloud.run('isAdmin', { username: user.username });
        const isPharmacy = await Parse.Cloud.run('isPharmacy', { username: user.username });


        this.$state.publicLoggedInSubject.next(true);
        if (isAdmin) {
          this.$state.isLoggedIn.next(true);
        }
        if (isPharmacy) {
          this.$state.isPharmacyLoggedIn.next(true);
        }

      } else {
        await this.logout();
      }
    } catch (error) {
      console.warn(error);
      if (error.code == 209) {
        window.localStorage.clear();
        location.reload();
      }

    }

  }
}
