import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router';
import { Howl } from 'howler';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private history: string[] = []

    //beep audio for new uber order
    public audio: Howl = null;
    public newUberOrderURL: string="";
    public newC2uOrderURL: string="";

    constructor(private router: Router, private location: Location) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {

                this.history.push(event.urlAfterRedirects)
                // console.log("THE CURRENT HISTORY IS");
                // console.log(this.history);
                if(this.audio && (event.urlAfterRedirects === this.newUberOrderURL || event.urlAfterRedirects === this.newC2uOrderURL)) {
                    this.audio.stop();
                    // this.audio.unload();
                    this.audio = null;
                    this.newUberOrderURL = "";
                    this.newC2uOrderURL = "";
                }
            }
        })
    }

    back(url: string): void {
        this.history.pop()
        if (this.history.length > 0) {
            this.location.back()
        } else {
            this.router.navigateByUrl(url);
        }
    }
}