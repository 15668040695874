
<div class="row">
    <div class="datepicker">
        <div>
            <input type="text" matInput class="invisible" [matDatepicker]="datePicker">
            <input class="picker" [(ngModel)]="selectedDate" placeholder="Select month and year">
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker startView="year" [startAt]="selectedDate"
                (monthSelected)="dropDownDates($event)">
            </mat-datepicker>
        </div>
        <c2u-button (click)="sendSelectedDate()" [disabled]="!selectedDate" label="Export"></c2u-button>
    </div>
</div>
