<div class="page login flex">
  <div class="header">
    <img src="/assets/images/logo.svg"/>
  </div>
  <div class="main flex">
    <div class="login-form-container-wrapper">
      <div class="title">
        Log into Chemist2U 
      </div>
      <div class="login-form-container flex">
        <form id="loginForm" [formGroup]="loginForm" (ngSubmit)="onSubmitLoginForm()">
          <div class="form-group">
            <input type="text" class="primary"  placeholder="Username" formControlName="username"  [ngClass]="{'error': f.get('username').errors?.required && submitted ? true: false}"/>
          </div>
          <div class="form-group">
            <input type="password" class="primary" placeholder="Password" formControlName="password" [ngClass]="{'error': f.get('password').errors?.required && submitted ? true: false}"/>
          </div>
          <button type="submit" class="btn primary" [disabled]="f.invalid">Log in</button>
        </form>
        <div class="forget-password-clink-container flex">
          <a [routerLink]="['/forgot-password']" class="primary-link">FORGOT YOUR PASSWORD?</a>
        </div>
      </div>
    </div>
  </div>
</div>
