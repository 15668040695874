import { Component, inject, Injector } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Parse from 'parse';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { OrderUser } from './services/socket/orderusers.service';
import { NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BasePage } from './pages/base-page';
import { AmplitudeService } from './services/amplitude.service';
import { AuthService } from './services/auth.service';
import { StateService } from './services/state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BasePage {
  private $auth = inject(AuthService);
  private $state = inject(StateService);
  //public title = 'Chemist2U-New-Admin-Dashboard';
  public loggedIn: boolean = false;
  public isPharmacyLoggedIn: boolean = false;

  constructor(
    private orderUserService: OrderUser,
    private amplitude: AmplitudeService,
    injector: Injector) {
    super(injector);
    this.initializeApp().then(() => {
      this.$state.publicLoggedInSubject.subscribe(loggedIn => {
        if (loggedIn) {
          this.orderUserService.initialize();
        } else {
          this.orderUserService.disconnect();
        }
      });

      this.orderUserService.socketConnectedObservable.subscribe(connected => {
        if (connected) {
          this.updateSocketStatus(this.router.url);
        }
      });

      this.$state.isLoggedIn.subscribe(loggedIn => {
        this.loggedIn = loggedIn;
      });

      this.$state.isPharmacyLoggedIn.subscribe(loggedIn => {
        this.isPharmacyLoggedIn = loggedIn;
      });
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        let navObject = event as NavigationEnd;
        let url = navObject.urlAfterRedirects;

        this.updateSocketStatus(url);
      });
  }

  updateSocketStatus(url) {
    if (User.current()) {
      // admin socket IO
      if (this.$state.isLoggedIn.getValue()) {
        this.orderUserService.updateAdminStatus(url);
      }

      // pharmacy socket IO
      if (this.$state.isPharmacyLoggedIn.getValue()) {
        //this.orderUserService.updatePharmacyStatus(url);
      }
    }
  }

  async initializeApp() {
    this.setupParse();
    console.log("INITIALIZE APP");
   

 
    await this.$auth.restoreSesion();

    await this.checkRole();
  }

  setupParse() {
    (Parse as any).serverURL = environment.serverUrl;
    (Parse as any).liveQueryServerURL = environment.liveQueryServerURL;
    Parse.initialize(environment.appId);
    this.amplitude.initialize();
  }


  async checkRole() {
    const user: User = User.current();

    if (user && user.authenticated()) {
      const role = await this.$cloud.isUserType(user.getUsername());
      const roleName = role.get('name');
      console.log(roleName);
      if(roleName === 'Pharmacy') await this.initializeHubSpot(user);
      localStorage.setItem('ROLE', roleName);
    } else {
      localStorage.removeItem('ROLE');
     
    }
  }



  
}
