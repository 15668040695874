import { Component, Injector, Inject } from '@angular/core';
import { BasePage } from '../../../pages/base-page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pharmacy, PharmacyException, PublicHolidays, User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'dialog-edit-pharmacy-hours',
    templateUrl: './edit-pharmacy-holiday-hours.html',
    styleUrls: ['./edit-pharmacy-holiday-hours.scss']
})


export class DialogEditPharmacyHours extends BasePage {

    constructor(injector: Injector,
        public dialogRef: MatDialogRef<DialogEditPharmacyHours>,
        @Inject(MAT_DIALOG_DATA) public data: {
            pharmacy: Pharmacy,
            showNormalTradingHours: boolean
            publicHoliday: PublicHolidays
        }) {
        super(injector);
    }


    public exceptionAddForm: FormGroup;
    public showError: boolean = false;
    public errorMessage: string = '';
    public radioButtonData: Array<{ value: string; label: string; }> = [
        { value: 'Closed', label: 'Closed' },
        { value: 'Normal hours', label: 'Normal Trading Hours' },
        { value: 'Special hours', label: 'Special Trading Hours' },

    ];


    async ngOnInit() {
        console.log(this.data);
        if (!this.data.showNormalTradingHours) {
            this.radioButtonData = this.radioButtonData.filter(d => d.value !== 'Normal hours');
        }
        this.exceptionAddForm = new FormGroup({
            date: new FormControl<Date | null>({ value: this.data.publicHoliday ? this.data.publicHoliday.date : null, disabled: this.data.publicHoliday ? true : false }, Validators.required),
            reason: new FormControl<string | undefined>({ value: this.data.publicHoliday ? this.data.publicHoliday.name : "", disabled: this.data.publicHoliday ? true : false }, Validators.required),
            openingTime: new FormControl<string | undefined>(''),
            closingTime: new FormControl<string | undefined>(''),
            type: new FormControl<string >('', [Validators.required]),
        });
    }

    dateFilter = (date: Date | null): boolean => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return date >= currentDate;
    };


    saveException() {
        if (!this.exceptionAddForm.invalid) {
            this.showError = false;
            this.errorMessage = '';
            const rawValue = this.exceptionAddForm.getRawValue();
            console.log(rawValue,);
            
            if (rawValue.type == 'Special hours' && (!rawValue.openingTime || !rawValue.closingTime)) {
                this.showError = true;
                this.errorMessage = 'Please enter opening and closing time.';
                return;
            }

            const values = this.updateTime(rawValue.openingTime, rawValue.closingTime);
            if (values.timeDifference < 0) {
                this.showError = true;
                this.errorMessage = 'Closing time should be greater than opening time.';
                return;
            }

            let obj = new PharmacyException();
            
            obj.date = rawValue.date;
            obj.reason = rawValue.reason;
            
            obj.pharmacy = this.data.pharmacy.user as User;

            obj.openingTime = values.openingTime 
            obj.closingTime = values.closingTime 

            // closing and opening time in minutes
            obj.openingTimen = values.openingTimen 
            obj.closingTimen = values.closingTimen
            obj.type = rawValue.type;
            if (this.data.publicHoliday) {
                obj.publicHoliday = this.data.publicHoliday;
            }
            this.dialogRef.close(obj);
        }
    }

    updateTime(T1: string, T2: string) {
        if(!T1 && !T2) return {
            openingTime: undefined,
            closingTime: undefined,
            openingTimen: undefined,
            closingTimen: undefined,
            timeDifference: undefined
        };

        const [hours, minutes] = this.convert12to24(T1).split(":");
        const [hours1, minutes1] = this.convert12to24(T2).split(":");
        console.log(hours, minutes, hours1, minutes1);
        

        const openingTimen = Number(hours) * 60 + Number(minutes);
        const openingTime = new Date();
        openingTime.setHours(Number(hours));
        openingTime.setMinutes(Number(minutes));

        const closingTimen = Number(hours1) * 60 + Number(minutes1);
        const closingTime = new Date();
        closingTime.setHours(Number(hours1));
        closingTime.setMinutes(Number(minutes1));

        return {
            openingTime: openingTime,
            closingTime: closingTime,
            openingTimen: openingTimen,
            closingTimen: closingTimen,
            timeDifference: closingTimen - openingTimen
        }
    }

    get date() { return this.exceptionAddForm.get('date') }
    get reason() { return this.exceptionAddForm.get('reason') }
    get type() { return this.exceptionAddForm.get('type') }


    convert12to24(time12h): string {
        const [time, modifier] = time12h.split(" ");
        let [hours, minutes] = time.split(":");
        
        if (hours == '12') {
            hours = '00';
        }
        if (modifier == 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        const finaltime: string = (hours + minutes).length < 4 ? '0' + (hours +":"+ minutes) : (hours +":"+ minutes);
        return finaltime;

    }
    
    getTimeDifference(time1:string, time2:string):number {
        const minutes1 = parseInt(time1.substring(0, 2), 10) * 60 + parseInt(time1.substring(2), 10);
        const minutes2 = parseInt(time2.substring(0, 2), 10) * 60 + parseInt(time2.substring(2), 10);

        return minutes2 - minutes1;
    }

}