<h2 mat-dialog-title>Change Address</h2>
<mat-dialog-content>
    <mat-form-field *ngIf="!searchMode">
        <mat-label>Current Address</mat-label>
        <input matInput aria-label="Current Address" [disabled]="!searchMode" [(ngModel)]="searchString">
        <button (click)="clearAddressString()" mat-button matSuffix  aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field *ngIf="searchMode">
        <mat-label>Address Search</mat-label>
        <input matInput aria-label="Pharmacy" [matAutocomplete]="auto" [(ngModel)]="searchString"
            [formControl]="addressSearchCtrl">
        <button (click)="searchString = ''" mat-button matSuffix  aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option (click)="selectAddress(address)" *ngFor="let address of addressResults"
                [value]="address.description">
                {{address.structured_formatting.main_text}}, {{address.structured_formatting.secondary_text}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <!-- <div>
        <mat-checkbox [(ngModel)]='copy.deliverToDoor' [checked]="copy.deliverToDoor">Delivery to door (unchecked pickup outside)</mat-checkbox>
    </div> -->
    <mat-form-field>
        <mat-label>Apt/Suite/Floor</mat-label>
        <input matInput aria-label="Apartment Suite Floor" [(ngModel)]="copy.aptSuiteFloor">
        <button *ngIf="copy.aptSuiteFloor" (click)="copy.aptSuiteFloor=''" mat-button matSuffix 
            aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Business Name</mat-label>
        <input matInput aria-label="Business Name" [(ngModel)]="copy.businessName">
        <button *ngIf="copy.businessName" (click)="copy.businessName=''" mat-button matSuffix 
            aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div class="row">
        <mat-checkbox class="example-margin" [(ngModel)]="copy.atl" (ngModelChange)="onAtlChange($event)">
          <p class="label">Authority To Leave</p>
        </mat-checkbox>
      </div>
    <mat-form-field>
        <mat-label>Delivery Notes</mat-label>
        <input matInput aria-label="Delivery Notes"  [(ngModel)]="copy.deliveryNote" [required]="makeDeliveryNoteRequired"  #deliveryNoteInput="ngModel">
        <button *ngIf="copy.deliveryNote" (click)="copy.deliveryNote=''" mat-button matSuffix 
            aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
        <mat-error *ngIf="deliveryNoteInput.invalid && deliveryNoteInput.touched">
            Delivery Notes are required when "Authority To Leave" is selected.
        </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button (click)="saveAddress()" [disabled]="makeDeliveryNoteRequired && deliveryNoteInput.invalid" mat-flat-button color='accent'>Save Address</button>
</mat-dialog-actions>