<div class="table-grid">
    <div class="header row col-num-{{columnDefs.length}}">
        <div class="header col" *ngFor="let col of columnDefs" (click)="sort(col)" [class.active]="activeSort === col.alias">
            <h4>{{ col.alias }}</h4>
            <span class="material-icons" *ngIf="sortDirection && col.sortable">
                arrow_upward
            </span>
            <span class="material-icons" *ngIf="!sortDirection && col.sortable">
                arrow_downward
            </span>
        </div>
    </div>
    <div class="rows">
        <ng-container  *ngIf="loadState === 'loaded'">
            <div class="row col-num-{{columnDefs.length}}" *ngFor="let row of pageData" (click)="handleRowClick(row)">
                <div class="col" *ngFor="let colDef of columnDefs" matTooltip="{{row[colDef.name]}}" matTooltipPosition="below" >
                    <p *ngIf="colDef.type === 'string'">{{ row[colDef.name] }}</p>
                    <p *ngIf="colDef.type === 'currency'">{{ row[colDef.name] | currency}}</p>
                    <p *ngIf="colDef.type === 'date'">{{ row[colDef.name] | date: "d/M/Y h:mm" }}</p>
                </div>
            </div>
        </ng-container>
        <div class="row" *ngIf="!rowData.length && loadState === 'loaded'">
            <div class="col no-data">
                <p>No data found</p>
            </div>
        </div>
        <div class="spinner-container" *ngIf="loadState === 'loading'">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <div class="controls-row" *ngIf="rowData.length">
        <mat-label matPrefix class="items-count">Items per page:</mat-label>
        <mat-form-field style="margin-right: 1rem;">
            <mat-select [formControl]="pageSizeCtrl">
                <mat-option value="5">5</mat-option>
                <mat-option value="10">10</mat-option>
                <mat-option value="25">25</mat-option>
                <mat-option value="50">50</mat-option>
                <mat-option value="100">100</mat-option>
            </mat-select>
        </mat-form-field>
        <p class="page-count">{{currentPageRange}}</p>
        <button  (click)="start()" [disabled]="pageNum === 1">
            <i class="material-icons" [class.disabled]="pageNum === 1" >first_page</i>
        </button>
        <button  (click)="back()" [disabled]="pageNum === 1">
            <i class="material-icons" [class.disabled]="pageNum === 1" (click)="back()">keyboard_arrow_left</i>
        </button>
        <button  (click)="next()" [disabled]="pageNum === totalPages">
            <i class="material-icons" [class.disabled]="pageNum === totalPages" (click)="next()">keyboard_arrow_right</i>
        </button>
        <button  (click)="end()" [disabled]="pageNum === totalPages">
            <i class="material-icons" [class.disabled]="pageNum === totalPages" (click)="end()">last_page</i>
        </button>                        
    </div>
</div>