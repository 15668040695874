import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class EventsService {

    constructor() { }

    triggerUpdateUberOrderCount: Subject<any> = new Subject();

    //   triggerCurrentUserRemoved: Subject<any> = new Subject();
    //   triggerUserLoggedIn: Subject<any> = new Subject();
    //   triggerInitialize: Subject<any> = new Subject();
    //   triggerAddressSelect: Subject<any> = new Subject();
    //   triggerProductFilter: Subject<any> = new Subject();
}
