<ag-grid-angular class="ag-grid-datatable-primary ag-theme-alpine"
                 #agGrid
                 style="width: 100%; height: 100%;"
                 id="myGrid"
                 [gridOptions]="gridOptions"
                 [components]="components"
                 [components]="frameworkComponents"
                 [rowData]="rowData"
                 (gridReady)="onGridReady($event)"
                 (rowClicked)="onRowClicked($event)"
                 (firstDataRendered)="onFirstDataRendered($event)"
                 (gridSizeChanged)="onFirstDataRendered($event)"
></ag-grid-angular>
