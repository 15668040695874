import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Parse from 'parse';

@Injectable({
    providedIn: 'root'
})

export class DashboardGuard  {

    constructor(private router: Router) {}

    canActivate() {
        const currentUser = Parse.User.current();
        if (currentUser && currentUser.authenticated())  return true;
        this.router.navigate(['/login'],);
        return false;
    }
}