<div class="pane" [class.bg-warn]="warn">
    <div class="header">
        <div class="title"><h3>{{title}}</h3></div>
        <div class="actions" *ngIf="action.length">
            <button (click)="actionClicked()" [ngClass]="{'black':dark, 'disabled': disabled}">{{action}}</button>
        </div>
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
</div>