import { Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { CloudFunctionsService } from '../services/cloud.functions.service';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';


export abstract class BasePage {
    protected activatedRoute: ActivatedRoute;
    protected router: Router;
    private meta: Meta;
    private title: Title;
    protected sanitizer: DomSanitizer;
    protected snackBar: MatSnackBar;

    public $cloud:CloudFunctionsService;

    startPosition: MatSnackBarHorizontalPosition = 'start';
    endPosition: MatSnackBarHorizontalPosition = 'end';
    bottomPosition: MatSnackBarVerticalPosition = 'bottom';
    toposition: MatSnackBarVerticalPosition = 'top';

    constructor(injector: Injector) {
        this.router = injector.get(Router);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.sanitizer = injector.get(DomSanitizer);
        this.meta = injector.get(Meta);
        this.title = injector.get(Title);
        this.snackBar = injector.get(MatSnackBar);
        this.$cloud = injector.get(CloudFunctionsService);
        console.log(this.meta);
    }


    get Swal(): any {
        return Swal;
    }

    public get serverUrl(): string {
        return environment.serverUrl;
    }

    public get appUrl(): string {
        return environment.appUrl;
    }

    public get appId(): string {
        return environment.appId;
    }
    public get sqAppID(): string {
        return environment.sqAppID
    }
    public get sqLocationID(): string {
        return environment.sqLocationID
    }


    public setPageTitle(title: string): void {
        this.title.setTitle(title);
    }

    public showNotification(message, action, duration) {
        return this.snackBar.open(message, action, {
            duration: duration,
            horizontalPosition: this.endPosition,
            verticalPosition: this.bottomPosition
        })
    }
    public showNotificationWithURL(message:string, action:string, link:string) {
        return this.snackBar.open(message, action, { 
            horizontalPosition: this.endPosition,
            verticalPosition: this.toposition,
        })
        .onAction()
        .subscribe(() => this.router.navigateByUrl(link));
    }


    public noticeAlert(icon,title,message) {
        return Swal.fire({
            icon: icon,
            title: title,
            text: message
        })
    }
    public errorAlert(message, source?) {
        if(source) {
            return Swal.fire({
                icon: 'error',
                title: source,
                text: message
            })
        } else {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: message
            })
        }
        
    }

    public promptAlert(title,message, buttonText) {
        return Swal.fire({
            icon: 'question',
            title: title,
            text: message,
            showCancelButton: true,
            confirmButtonText: buttonText
        })
    }

    async initializeHubSpot(user: User) {
       
        const body = <HTMLDivElement>document.body;
        
        if (user && user.get('email') && user.get('first_name') && user.get('last_name')) {
          const email = user.get('email');
          //get the token and load
          const token = await this.$cloud.getHubspotAuthenticationToken(email, user.get('first_name'), user.get('last_name'));
          
          const hack = document.createElement('script');
          let inlineScript = document.createTextNode(`
            window.hsConversationsSettings = {
              identificationEmail: "${email}",
              identificationToken: "${token}"
            };
          `);
          hack.appendChild(inlineScript);
          body.appendChild(hack);
          window.HubSpotConversations.widget.load();
        }
        else {
          window.hsConversationsSettings['loadImmediately'] = true;
        }
      }


}
