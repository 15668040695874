import { Component, Inject, Injector, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HubPharmacy } from '@chemist2u/types-client/C2U/ParseObjects/HubPharmacy';
import { Partner } from '@chemist2u/types-client/C2U/ParseObjects/Partner';
import { Pharmacy } from '@chemist2u/types-client/C2U/ParseObjects/Pharmacy';
import { Observable, map, startWith } from 'rxjs';
import { BasePage } from 'src/app/pages/base-page';
import { FetchService } from 'src/app/services/fetch.service';

type THubPartnerMap = {
  partner: Partner;
  hubPharmacies: HubPharmacy[];
}


type THubDialogData = {
  partners?: Partner[],
  hubPartnerMap?: THubPartnerMap
}

@Component({
  selector: 'hubs-dialog',
  templateUrl: './hubs-dialog.component.html',
  styleUrls: ['./hubs-dialog.component.scss']
})
export class HubsDialogComponent extends BasePage implements OnInit {

  private $fetch = inject(FetchService)

  constructor(
    injector: Injector,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<HubsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: THubDialogData
  ) {

    super(injector);
    this.pharmSearchCtrl = new FormControl();
    this.filteredPharmacy = this.pharmSearchCtrl.valueChanges.pipe(
      startWith(''),
      map(pharmacy => pharmacy ? this.filterPharmacies(pharmacy) : this.pharmacies.slice())
    )

    if (!!this.data.hubPartnerMap) {
      this.form = this.fb.group({
        'partner': [this.data.hubPartnerMap.partner.name, Validators.required],
        'NSW': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'NSW')?.pharmacy as Pharmacy).businessName, Validators.required],
        'VIC': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'VIC')?.pharmacy as Pharmacy).businessName, Validators.required],
        'QLD': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'QLD')?.pharmacy as Pharmacy).businessName, Validators.required],
        'SA': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'SA')?.pharmacy as Pharmacy).businessName, Validators.required],
        'WA': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'WA')?.pharmacy as Pharmacy).businessName, Validators.required],
        'TAS': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'TAS')?.pharmacy as Pharmacy).businessName, Validators.required],
        'NT': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'NT')?.pharmacy as Pharmacy).businessName, Validators.required],
        'ACT': [(this.data.hubPartnerMap.hubPharmacies.find(h => h.state === 'ACT')?.pharmacy as Pharmacy).businessName, Validators.required],
      });
      this.form.get('partner').disable();
      console.log('edit default values :)', this.form.getRawValue())
    } else {
      this.form = this.fb.group({
        'partner': ['', Validators.required],
        'NSW': ['', Validators.required],
        'VIC': ['', Validators.required],
        'QLD': ['', Validators.required],
        'SA': ['', Validators.required],
        'WA': ['', Validators.required],
        'TAS': ['', Validators.required],
        'NT': ['', Validators.required],
        'ACT': ['', Validators.required],
      });
    }

    if (this.data.partners) {
      this.partnerNames = this.data.partners.map(p => p.name);
    } else {
      this.partnerNames = [this.data.hubPartnerMap?.partner.name];
    }
  }

  pharmSearchCtrl: FormControl;
  filteredPharmacy: Observable<Pharmacy[]>;

  states: string[] = [
    "ACT",
    "NSW",
    "NT",
    "QLD",
    "SA",
    "TAS",
    "VIC",
    "WA",
  ];

  form: FormGroup | undefined;

  pharmacies: Pharmacy[] = [];
  pharmacyNames: string[] = [];

  partnerNames: string[] = []

  async ngOnInit(): Promise<void> {
    const pharmacies = await this.$fetch.getAllPharmacies();
    this.pharmacies = pharmacies.filter(p => !!p.meta?.weightLossPharmacy);
    this.pharmacyNames = this.pharmacies.map(p => p.businessName);
  }

  submitForm() {
    try {
      const formValue = this.form.getRawValue();

      const partner = !!this.data?.partners ? this.data.partners.find(p => p.name === formValue.partner) : this.data.hubPartnerMap?.partner;
      const pharmacyStateMap = this.states.map(state => {
        const value = formValue[state];
        const pharmacy = this.pharmacies.find(p => p.businessName === value);
        return { state, pharmacy };
      })
      const result = { partner, pharmacyStateMap };
      this.dialogRef.close(result);
    } catch (error) {
      console.error('Error submitting form', error);
    }
  }

  filterPharmacies(search: string): any[] {//fix
    const array = this.pharmacies.filter(p => p.businessName.toLowerCase().includes(search.toLowerCase()));
    return array.length ? array : [{ businessName: 'No results found' }];
  }

}
