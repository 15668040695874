import { Component, Injector, Inject } from '@angular/core';
import { BasePage } from '../../pages/base-page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import { PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';

@Component({
    selector: 'dialog-change-address',
    templateUrl: './change-address.html',
    styleUrls: ['./change-address.scss']
})

export class DialogChangeAddress extends BasePage {
    constructor(injector: Injector,

        public dialogRef: MatDialogRef<DialogChangeAddress>,
        @Inject(MAT_DIALOG_DATA) public data: TSessionAddress | undefined) {
        super(injector);


        this.addressSearchCtrl.valueChanges
            .pipe(
                filter((value: string) => value.length >= 3),
                distinctUntilChanged(),
                debounceTime(1000),
            ).subscribe(async x => {
                const placeAutoCompleteResponse = await this.$cloud.getAddressList(x);
                this.addressResults = placeAutoCompleteResponse.data.predictions;
            })
    }

    public copy: TSessionAddress | undefined;
    public addressResults: PlaceAutocompleteResult[] = [];
    public searchString: string = '';
    public searchMode: boolean = false;
    public selectedAddress: PlaceAutocompleteResult | undefined;


    public addressSearchCtrl: UntypedFormControl = new UntypedFormControl();
    public aptSuiteFloorCtrl: UntypedFormControl = new UntypedFormControl();
    public businessNameCtrl: UntypedFormControl = new UntypedFormControl();
    public deliverToDoorCtrl: UntypedFormControl = new UntypedFormControl();
    public deliveryNoteCtrl: UntypedFormControl = new UntypedFormControl();
    public makeDeliveryNoteRequired: boolean = false;

    onAtlChange(newValue: boolean) {
        if (!this.makeDeliveryNoteRequired && newValue === true) {
          this.makeDeliveryNoteRequired = true;
        }
    }

    async ngOnInit() {
        console.log(this.data);

        if (this.data) {
            this.copy = JSON.parse(JSON.stringify(this.data));
            this.searchString = this.data.selectedAddress ? this.data.selectedAddress.description : "";
        } else {
            this.searchMode = true;
            this.copy = {
                aptSuiteFloor: "",
                businessName: "",
                deliveryNote: "",
                deliveryToDoor: true,
                selectedAddress: undefined,
                address: undefined,
                atl: false
            }
        }


    }

    clearAddressString() {
        console.log("Cleared the value");
        this.searchString = '';
        this.searchMode = true;
    }


    selectAddress(address: PlaceAutocompleteResult) {
        this.selectedAddress = address;
        console.log("THE SELECTED ADDRESS IS");
        console.log(this.selectedAddress);
    }


    async saveAddress() {
        //address changed
        if (this.selectedAddress) {
            this.copy.selectedAddress = this.selectedAddress;
            const res = await this.$cloud.getAddressDetails((this.copy.selectedAddress as PlaceAutocompleteResult).place_id);
            this.copy.address = res.data.result as PlaceData;
        }

        this.dialogRef.close(this.copy);
    }
}