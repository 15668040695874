import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import * as Parse from 'parse';
import { EventsService } from '../state/globalevents.service';
import { OrderHelper } from '../helpers/order';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects/index.client';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private $order = inject(OrderHelper);
  private events = inject(EventsService);
  public bOrderSub = new BehaviorSubject(null);
  public bOrders = new BehaviorSubject<Order[]>([]);
  public pharmacyNotfications = new BehaviorSubject<Order[]>([]);
  constructor() {
    this.bOrderSub.subscribe(subscribtion => {
      if (subscribtion) this.setupOrders(subscribtion)
    })

    this.events.triggerUpdateUberOrderCount.subscribe(res => {
      const { count } = res;
      this.uberOrderCount = count;
      this.bUberOrderCount.next(this.uberOrderCount);
    })
  }

  private _uberOrdersCount: number;

  get uberOrderCount(): number {
    return this._uberOrdersCount;
  }

  set uberOrderCount(count: number) {
    this._uberOrdersCount = count;
  }



  public bUberOrderCount = new BehaviorSubject(this.uberOrderCount);


  initialiseLiveQuery() {
    const user = Parse.User.current();
    if (user) {
      from(new Parse.Query(Order)
        .notContainedIn('status', ['Delivered', 'Cancelled'])
        //@ts-ignore
        .notEqualTo('pharmacy.objectId', 'v8IU9cmIDG')
        .include('customer').addAscending('createdAt')
        .subscribe()
      ).subscribe(res => this.bOrderSub.next(res));
    }
  }

  async getOrders() {
    const orders = await this.$order.loadAllOrdersForOffline();
    this.bOrders.next(orders);
  }

  setupOrders(subscribtion) {
    const generic = ['open', 'close'];
    // const objectBased = ['create', 'update', 'enter', 'leave', 'delete'];

    generic.map(option => {
      subscribtion.on(option, () => console.log(`${option.toUpperCase()}: ORDERS Session`))
    })

    subscribtion.on('enter', order => {
      const orders = this.bOrders.value;
      orders.push(order);
      this.bOrders.next(orders);
    })

    subscribtion.on('create', order => {
      console.error("LIVEQUERY CREATE ORDER: ", order);
      const orders = this.bOrders.value;
      orders.push(order);
      this.bOrders.next(orders);
    })

    subscribtion.on('delete', order => {
      const orders = this.bOrders.value;
      const findIndex = orders.findIndex(i => i.id === order.id);
      if (!(findIndex < 0)) {
        orders.splice(findIndex, 1);
      }
      this.bOrders.next(orders);
    })

    subscribtion.on('leave', order => {
      const orders = this.bOrders.value;
      const findIndex = orders.findIndex(i => i.id === order.id);
      if (!(findIndex < 0)) {
        orders.splice(findIndex, 1);
      }
      this.bOrders.next(orders);
    })

    subscribtion.on('update', order => {
      console.error("LIVEQUERT UPDATE ORDER: ", order);
      const orders = this.bOrders.value;
      const findIndex = orders.findIndex(i => i.id === order.id);
      if (!(findIndex < 0)) {
        orders[findIndex] = order;
      }
      this.bOrders.next(orders);
    })
  }
}
