<div class="nav">
    <button (click)="backClick()" class="back left">
        <div class="icon">
            <img src="../assets/icons/back-arrow.svg" />
        </div>
    </button>
    <div class="meta left">
        <div class="order">
            <p><b>ORDER:</b><span>#{{orderID}}</span></p>
        </div>
        <div class="created" style="margin-right: 2rem;">
            <span>{{ orderPlacedAt | date: "E d/M h:mm a" }}</span>
            <div class="icon">
                <img src="../assets/icons/globe.svg" />
            </div>
        </div>
        <nav-pane title="Provider" [hasActions]="false" [label]="provider"></nav-pane>
    </div>
    <div class="right">
        <!-- <div class="time" *ngIf="orderState == 'CREATED' || orderState == 'ACCEPTED'">
            <p class="label" *ngIf="orderState == 'CREATED'">Time To Accept</p>
            <p class="label" *ngIf="orderState == 'ACCEPTED'">Time To Process</p>
            <p class="value">{{minuteTimer}}:{{secondTimer}}</p>
        </div> -->
        <nav-pane title="pharmacy" *ngIf="showPharmacyBox" [actions]="pharmacyActions" [hasActions]='true' [link]="pharmacyLink" [label]="pharmacyName"></nav-pane>
        <nav-pane title="customer" [actions]="customerActions" [hasActions]='true' [link]="customerLink" [label]="customerName" [link]></nav-pane>        
        <nav-pane title="status" [hasActions]="false" [label]="orderState"></nav-pane>
    </div>

</div>