<h2 mat-dialog-title>Add New Promotion</h2>
<mat-dialog-content>
    <div class="spinner" *ngIf="loadspinner">
      <mat-spinner color="accent"></mat-spinner>
    </div>
    <div *ngIf="!loadspinner">
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput type="text" ngDefaultControl [(ngModel)]="promotion.code" required/>
      </mat-form-field>
      <div class="grid">
        <mat-slide-toggle ngDefaultControl [(ngModel)]="promotion.active">Active</mat-slide-toggle>
  
        <mat-slide-toggle ngDefaultControl [(ngModel)]="promotion.classOTC"
          >OTC Discount</mat-slide-toggle
        >
  
        <mat-slide-toggle ngDefaultControl [(ngModel)]="promotion.classScript"
          >Script Discount</mat-slide-toggle
        >
  
        <mat-slide-toggle ngDefaultControl [(ngModel)]="promotion.classFee"
          >Shipping Discount</mat-slide-toggle
        >
      </div>
  
      <mat-form-field>
        <mat-label>Select Type</mat-label>
        <mat-select  [(value)]="promotion.type">
          <mat-option *ngFor="let option of options" [value]="option.value"
            >{{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
       <mat-form-field>
        <mat-label>Select expiry date</mat-label>
        <input matInput [matDatepicker]="picker" ngDefaultControl [(ngModel)]="promotion.expiry" />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field> 
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput type="number" ngDefaultControl [(ngModel)]="promotion.value" required />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Max Nr Uses</mat-label>
        <input matInput type="number" ngDefaultControl [(ngModel)]="promotion.maxNrUses" required />
      </mat-form-field>
      <div class="rule">
        <p>Rule</p>
        <p>{{promotion.promoRule}}</p>
      </div>
      <mat-error >{{errormessage}}</mat-error>
    </div>
     
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    (click)="submitForm()"
    color="accent"
    [disabled]="loadspinner"
  >
    Save
  </button>
  <button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
