<mat-form-field>
    <div *ngIf="disabled">
        <h4>{{control.value}}</h4>
        <mat-divider></mat-divider>
    </div>
    <ng-container *ngIf="!disabled">
        <input matInput [placeholder]="placeholder" [ariaLabel]="placeholder" [matAutocomplete]="auto"
        [formControl]="searchCtrl" required [disabled]="disabled" />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let record of searchResults | async" [value]="record" (onSelectionChange)="handleSelectionChange($event, control)">
                <span>{{ record }}</span>
            </mat-option>
        </mat-autocomplete>
    </ng-container>
</mat-form-field>