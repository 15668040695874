import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

interface ColDef {
    name: string;
    alias: string;
    type: 'string' | 'currency' | 'date';
    sortable?: boolean;
}

@Component({
    selector: 'grid-table',
    templateUrl: './grid-table.component.html',
    styleUrls: ['./grid-table.component.scss']
})
export class GridTableComponent implements OnInit {
    //Maximum columns for a single row is 16... if you want to handle more edit the scss
    @Input() rowData$: BehaviorSubject<any[]>;
    @Input() columnDefs: ColDef[];
    @Input() loadState: '' |'loading' | 'loaded';
    @Output() rowClick: EventEmitter<any> = new EventEmitter();

    rowData;

    pageData: any[];
    pageNum: number = 1;
    headerRow: string[];

    activeSort: string = '';
    sortDirection: boolean = false; //false = 'desc' and true = 'asc'

    pageSizeCtrl: FormControl<string>;

    get pageSize(): number {
        return parseInt(this.pageSizeCtrl.value)
    }   

    get totalPages(): number {
        const { rowData, pageSize } = this;
        const pageTotal = Math.ceil(rowData.length / pageSize);
        return pageTotal
    }

    get currentPageRange(): string {
        const total = this.rowData.length;
        const start =((this.pageNum * this.pageSize) + 1) - (this.pageSize);
        const end = this.pageNum ? (start -1) + this.pageSize : this.pageSize;
        return `${start} - ${end > total ? total : end} of ${total}`; 
    }

    constructor() {}
    
    ngOnInit(): void {
        this.pageSizeCtrl = new FormControl();
        this.pageSizeCtrl.setValue('5');
        this.pageSizeCtrl.valueChanges.subscribe(v => {
            this.resetTable()
        })
        this.headerRow = this.columnDefs.map(({ alias }) => alias);
        this.rowData$.subscribe(v => {
            this.rowData = v;
            this.setPageData();
        })
    }

    resetTable() {
        this.pageNum = 1;
        this.setPageData();
    }

    handleRowClick(row: any) {
        this.rowClick.next(row);
    }

    sort(col) {
        const { alias, sortable, name, type } = col;
        if(typeof sortable === 'undefined') return;

        if (alias === this.activeSort) {
            this.sortDirection = !this.sortDirection;
        } else {
            this.activeSort = alias;
            this.sortDirection = false;
        }

        this.rowData = this.rowData.sort((a, b) => {
            let aVal = type === 'date' ? new Date(a[name]).valueOf() : a[name];
            let bVal = type === 'date' ? new Date(b[name]).valueOf() : b[name];
            if (this.sortDirection) return aVal - bVal;
            else return bVal - aVal;
        })
        this.resetTable()
    }

    start() {
        this.resetTable()
    }

    back() {
        if (this.pageNum !== 1){
            this.pageNum--;
            this.setPageData();
        }
    }

    next() {
        if (this.pageNum !== this.totalPages){
            this.pageNum++;
            this.setPageData();
        }
    }

    end() {
        this.pageNum = this.totalPages;
        this.setPageData();
    }

    setPageData(): void {
        const {rowData, pageNum, pageSize} = this;
        const startIndex = pageNum === 1 ? 0 : (pageNum-1) * pageSize;
        let endIndex = pageSize * pageNum;
        if (startIndex + pageSize > rowData.length) endIndex = rowData.length;
        const newPageData = rowData.slice(startIndex, endIndex);
        this.pageData = newPageData;
    }
}