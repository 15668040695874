import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'create-email-subscription',
  templateUrl: './create-email-subscription.component.html',
  styleUrls: ['./create-email-subscription.component.scss']
})
export class CreateEmailSubscriptionComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CreateEmailSubscriptionComponent>
  ) {
    this.form = this.fb.group({
      token: ['', Validators.required],
      // supplyRemaining: ['', Validators.required],
      // itemName: ['', Validators.required],
      // status : ['', Validators.required]
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  saveTokenTemplate(): void {
    if (this.form.valid) {
      const formData = this.form.value;
      // Trigger the cloud function here, passing the formData
      this.dialogRef.close(formData);
    }
  }
}
