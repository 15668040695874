<h2 mat-dialog-title>AWS SMS Details</h2>
<mat-dialog-content>
    <div>
        <h3 class="bold-text">Action Type:</h3>
        <span class="">{{ data.timestamp | date: "E d/M h:mm a" }}  {{ data.action }} </span>
    </div>
    <div *ngIf="data.payload && data.payload.length > 0">
        <span class="bold-text">Message:</span><br/>
        <span class="preformatted-text">{{ data.payload[0].smsText }}</span>
    </div>
    <div *ngIf="!(data.payload && data.payload.length > 0)">
        <span class="bold-text">Message:</span> No message available.
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
