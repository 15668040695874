import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'show-uber-eventshow-uber-event',
    templateUrl: './show-market-place-event.html',
    styleUrls: ['./show-market-place-event.scss']
})
export class DialogShowMarketPlaceEvent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<DialogShowMarketPlaceEvent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void { 
        console.log(this.data);
    }
}
