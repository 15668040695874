<div class="box" *ngIf="recentOrders?.length" >
    <div class="topbar">
      <div class="actions">
        <button class="action pink-menu" (click)="showOrders()" (clickOutside)="closeOrders()" style="width: 100%;">
          <div class="action-button">
            <p>Recent Ozempic Orders</p>
            <img src="../../../../assets/icons/down-arrow-light.svg" />
          </div>
          <div class="actions special" *ngIf="showRecentOrders">
            <div class="action-menu" *ngFor="let record of recentOrders" (click)="goToOrder(record)">
              <p class="action-label" style="text-wrap: nowrap;">{{ record.message }}</p>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>