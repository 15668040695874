import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects/Order';
import { DialogPharmacyNotification } from 'src/app/dialogs/pharmacy-notification/pharmacy-notification.component';
import { DataService } from 'src/app/services/global/data.service';

@Component({
  selector: 'notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit, OnDestroy {
  public notificationCount: number = 0;
  public orders: Order[] = [];

  public dialog = inject(MatDialog);

  private data = inject(DataService);

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.data.pharmacyNotfications.subscribe((data) => {
      this.notificationCount = data.length;
      this.orders = data;
    });

  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }

  openNotification(): void {
    console.log(this.orders);
    if(!this.orders.length) return;
    const dialogRef = this.dialog.open(DialogPharmacyNotification, {
      width: '600px',
    });
    const data = dialogRef.afterClosed();
    console.log(data);
  }
}

