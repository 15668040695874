<div class="comment">
    <div class="comment-header">
        <div class="labels">
          <p class="username">{{ comment.username }}</p>
          <p class="timestamp">
            {{ comment.updatedAt | date: "E d/MM h:mm a" }}
          </p>
        </div>
        <div class="controls" *ngIf="user.username == comment.username">
          <button (click)="editClick(comment)" matTooltip="Edit comment." 
            aria-label="Edit icon for edit comment button">
            <mat-icon>edit</mat-icon>
          </button>
          <button (click)="deleteClick(comment)" matTooltip="Remove comment." 
            aria-label="Delete icon from delete commend button">
            <mat-icon><span class="material-icons-outlined">
                delete_outline
              </span></mat-icon>
          </button>
        </div>
      </div>
      <div class="comment-body">
        <p class="comment-text">{{ comment.comment }}</p>
      </div>
</div>