import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { OrderMarketPlace, Pharmacy } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'nav-market-place-order',
  templateUrl: './nav-market-place-order.component.html',
  styleUrls: ['./nav-market-place-order.component.scss']
})
export class NavMarketPlaceOrderComponent implements OnInit {

  @Input() orderID: string = 'LOL';
  @Input() marketPlaceOrder: OrderMarketPlace;
  @Input() refresh: boolean = false;
  @Input() showPharmacyBox = true;
  @Output() back = new EventEmitter<boolean>();

  public pharmacyName: string = '';
  public pharmacyActions: any[] = [];
  public pharmacyLink: string = '';

  public customerName: string = '';
  public customerActions: any[] = [];
  public customerLink: string = '';
  public customerHasAction: boolean = false;

  public orderState: string = '';
  public orderPlacedAt: Date;
  public prepareCounter: number;

  public timeSub: Subscription;
  public timeToResend: number;
  public currentTime: number = 0;
  public minuteTimer: number = 0;
  public secondTimer: string = '00';
  public provider:string = '';

  constructor() { }

  ngOnDestroy() {
    if (this.timeSub) {
      this.timeSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    // this.prepareCounter = OrderMarketPlace.prepCounter;
  }

  backClick() {
    this.back.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (!this.marketPlaceOrder) return;

    this.pharmacyActions = [];
    this.customerActions = [];

    if (this.timeSub) {
      this.timeSub.unsubscribe();
    }

    if (this.marketPlaceOrder) {
      this.pharmacyName = (this.marketPlaceOrder.pharmacy as Pharmacy).get('businessName');
      if (this.marketPlaceOrder.pharmacy && (this.marketPlaceOrder.pharmacy as Pharmacy).get('contact')) {
        const contact = (this.marketPlaceOrder.pharmacy as Pharmacy).get('contact');
        const emailLabel = contact.email;
        const phoneLabel = "Call " + contact.first_name + " " + contact.last_name + " on " + contact.phone;
        this.pharmacyActions.push({
          icon: 'telephone',
          label: phoneLabel
        })
        this.pharmacyActions.push({
          icon: 'envelope',
          label: emailLabel
        })

        this.pharmacyLink = '/pharmacy/manage/' + (this.marketPlaceOrder.pharmacy as Pharmacy).id;
      }
      const customerDetails = this.marketPlaceOrder.customerDetails;
     
      this.customerName = customerDetails.fullName;
      this.provider = this.marketPlaceOrder.provider;

      this.customerActions.push({
        icon: 'telephone',
        label: customerDetails.phone
      })

      this.orderState = this.marketPlaceOrder.status;
      this.orderPlacedAt = this.marketPlaceOrder.placedAt;

      const placedAt = new Date(this.orderPlacedAt);

      const now = new Date();
      const differenceInSeconds = Math.round(Math.abs((placedAt.getTime() - now.getTime()) / 1000));
 
      let timeLeft = 0;
      // if(this.orderState == 'CREATED') {
      //   timeLeft = this.acceptCounter - differenceInSeconds 
      // }

      if(this.orderState == 'ACCEPTED') {
        timeLeft = this.prepareCounter - differenceInSeconds 
      }

      this.timeToResend = timeLeft;

      if (timeLeft > 0) {
        this.timeSub = interval(1000)
          .subscribe(res => {

            if (res + 1 == this.timeToResend) {
              this.timeSub.unsubscribe();
              this.timeSub = null;
              this.currentTime = 0;
            }
            this.currentTime = res + 1;
            this.minuteTimer = Math.floor((this.timeToResend - this.currentTime) / 60);
            this.secondTimer = ((this.timeToResend - this.currentTime) % 60).toString().padStart(2, '0');
          })
      }
    }
  }

}
