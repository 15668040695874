import * as Parse from 'parse';
import { EventEmitter } from '@angular/core';
import { ISort } from '../../shared/interfaces/request-params/sort';
import { IPagination } from '../../shared/interfaces/request-params/pagination';

export class BaseService extends Parse.Object {

  public errorEmitter = new EventEmitter();

  constructor(public model?: string) {
    super(model);
  }

  catchError(err) {
    switch (err.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        this.errorEmitter.emit('INVALID_SESSION');
        break;
    }
    throw err;
    return err;
  }

  sort(query, sort: ISort) {
    if (sort.direction === 'asc') {
      query.ascending(sort.identifier);
    } else {
      query.descending(sort.identifier);
    }
  }

  applyParams(query, params) {
    if (params.contains) {
      for (let i = 0; i < params.contains.length; i++) {
        const value = params.contains[i].value;
        const name = params.contains[i].name;
        if (value && value !== '') {
          query.matches(name, value, 'i');
        }
      }
    }

    if (params.equalTo) {
      for (let i = 0; i < params.equalTo.length; i++) {
        const name = params.equalTo[i].name;
        const value = params.equalTo[i].value;
        if (value) {
          query.equalTo(name, value);
        }
      }
    }
    if (params.include) {
      query.include(params.include);
    }
    if (params.withCount) {
      query.withCount(true);
    }
  }

  async getById(id: string, params: any = {}): Promise<any> {
    const Model = Parse.Object.extend(this.model);
    const query = new Parse.Query(Model);
    if (params.include) {
      query.include(params.include);
    }
    return query.get(id);
  }

  applyGeneralParams(query, pagination?: IPagination, params?) {
    if (params) {
      this.applyParams(query, params);
    }
    if (pagination) {
      query.limit(pagination.limit);
      query.skip(pagination.skip);
    }
  }
}
