import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { AdminGuard } from './helpers/admin.guard';
import { PharmacyAuthGuard } from './helpers/pharmacy.guard';
import { DashboardGuard } from './helpers/dashboard.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [DashboardGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule)
  },
  {
    path: 'catalog',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/catalog/catalog.module').then(m => m.CatalogModule)
  },
  {
    path: 'pharmacy',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pharmacy/pharmacy.module').then(m => m.PharmacyModule)
  },
  {
    path: 'customer',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackModule)
  },
  {
    path: 'promotion',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/promotion/promotion.module').then(m => m.PromotionModule)
  },
  {
    path: 'settings',
    canActivate: [AdminGuard],
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: "p-orders",
    canActivate: [PharmacyAuthGuard],
    loadChildren: () => import('./pages/pharmacy-pages/pharmacy-orders/pharmacy-orders.module').then(m => m.PharmacyOrdersModule)
  },
  {
    path: "p-items",
    canActivate: [PharmacyAuthGuard],
    loadChildren: () => import('./pages/pharmacy-pages/pharmacy-catalog/pharmacy-catalog.module').then(m => m.PharmacyCatalogModule)

  },
  {
    path: "p-settings",
    canActivate: [PharmacyAuthGuard],
    loadChildren: () => import('./pages/pharmacy-pages/pharmacy-settings/pharmacy-settings.module').then(m => m.PharmacySettingsModule)
  },
  {
    path: "p-delivery",
    canActivate: [PharmacyAuthGuard],
    loadChildren: () => import('./pages/pharmacy-pages/pharmacy-deliveries/pharmacy-deliveries.module').then(m => m.PharmacyDeliveriesModule)
  },
  {
    path: 'inbox',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
