import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';


@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, AfterViewInit {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();
  @Input() searchValueLength = 0;
  @Input() debounceTime = 500;

  @ViewChild('searchBox') input: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        filter(Boolean),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          const value = (event.target as HTMLInputElement).value;
          if (this.value !== value) {
            this.value = value;
            if (this.value.length >= this.searchValueLength){
              this.valueChange.emit(this.value);
            }
          }
        })
      )
      .subscribe();
  }

}
