import { Injectable } from "@angular/core";
import { BaseService } from "../dataservices/base.service";
import { OrderMarketPlace } from "@chemist2u/types-client/C2U/ParseObjects/index.client";
import * as Parse from "parse";

@Injectable({
    providedIn: 'root'
})

export class MarketplaceHelper extends BaseService {


    constructor() {
        super('MarketplaceHelper');
    }
    static readonly prepCounter: number = 1500;


    // live subscription by ID 
    getSubscriptionByID(id: string): any {
        return new Parse.Query(OrderMarketPlace).equalTo('objectId', id).subscribe();
    }

    // live subscription
    getSubscription(): any {
        let query = new Parse.Query(OrderMarketPlace);
        return query.subscribe();
    }

    getArchivedOrders(pagination: any = { page: 0, limit: 15 }, sort: any = { direction: 'asc', column: 'createdAt' }, params: any, search: any): Promise<OrderMarketPlace[]> {
        const query = new Parse.Query(OrderMarketPlace);
        if (pagination) {
            query.skip(pagination.page * pagination.limit);
            query.limit(pagination.limit);
        }

        if (sort) {
            if (sort.direction == 'asc') query.ascending(sort.column);
            if (sort.direction == 'desc') query.descending(sort.column);
        }

        if (search) query.matches(search.column, search.value, 'i');

        if (params) {
            if (params.status) query.equalTo('status', params.status);
        }
        else query.containedIn('status', ['FINISHED', 'CANCELED', 'DENIED', 'PENDING']);
        // query.include('pharmacy');
        return query.find();
    }

    loadArchiveCount(params: any, search: any): Promise<number> {
        const query = new Parse.Query(OrderMarketPlace)

        if (search) query.matches(search.column, search.value, 'i');

        if (params) {
            if (params.status) query.equalTo('status', params.status);
            else query.containedIn('status', ['FINISHED', 'CANCELED', 'DENIED']);
        }
        return query.count();
    }
    orderMarketPlaceCount(): Promise<number> {
        return new Parse.Query(OrderMarketPlace).containedIn('status', ['FINISHED', 'CANCELED', 'DENIED']).count();
    }

    loadMarketPlaceOrder(): Promise<OrderMarketPlace[]> {
        const query = new Parse.Query(OrderMarketPlace);
        // query.include(['pharmacy','customer']);
        query.descending('createdAt');
        query.equalTo('status', 'ACCEPTED');
        query.limit(10000);
        return query.find();
    }

    loadOne(id: string): Promise<OrderMarketPlace> {
        const query = new Parse.Query(OrderMarketPlace);
        query.equalTo('objectId', id);
        query.include("pharmacy");
        return query.first();
    }

    // * Menulog cloud functions calls

    setMLPharmacyOffline(pharmacyID: string,): Promise<any> {
        return Parse.Cloud.run('setMLPharmacyOffline', { restaurantReference: pharmacyID });
    }

    setMLPharmacyOnline(pharmacyID: string): Promise<any> {
        return Parse.Cloud.run('setMLPharmacyOnline', { restaurantReference: pharmacyID });
    }

    updateMLPharmacyItem(payload: any): Promise<any> {
        return Parse.Cloud.run('updateMLPharmacyItem', { payload: payload });
    }

    cancelMLOrder(providerID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('cancelMLOrder', { providerID: providerID, payload: payload });
    }

    updateMLOrder(providerID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('updateMLOrder', { providerID: providerID, payload: payload });
    }

    // * DoorDash cloud functions calls

    doordashStoreInfo(pharmacyID: string): Promise<any> {
        return Parse.Cloud.run('getDDStoreInfo', { pharmacyID: pharmacyID });
    }

    updateDDStoreStatus(pharmacyID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('updateDDStoreStatus', { pharmacyID: pharmacyID, payload: payload })
    }

    cancelDDOrder(orderID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('cancelDDOrder', { orderID: orderID, payload: payload });
    }

    updateDDOrder(orderID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('updateDDOrder', { orderID: orderID, payload: payload });
    }

    updateDDBatchItem(pharmacyID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('updateDDBatchItem', { pharmacyID: pharmacyID, payload: payload })
    }

    updateDDItemStatus(pharmacyID: string, payload: any): Promise<any> {
        return Parse.Cloud.run('updateDDItemStatus', { pharmacyID: pharmacyID, payload: payload })
    }

    // * UBER cloud function calls

    cancelUberOrder(orderId: string, reason: "OUT_OF_ITEMS" | "CUSTOMER_CALLED_TO_CANCEL" | "CANNOT_COMPLETE_CUSTOMER_NOTE" | "OTHER", details: string = "Reason not provided", cancelling_party: "MERCHANT" | "CUSTOMER"): Promise<any> {
        const payload = {
            reason, details, cancelling_party
        }
        return Parse.Cloud.run('cancelUberOrder', { orderId, payload: payload });
    }

    refreshUberOrderDetails(orderId: string): Promise<OrderMarketPlace> {
        return Parse.Cloud.run('refreshUberOrder', { orderId })
    }

    denyUberOrder(orderId: string, explanation: string, code: "STORE_CLOSED" | "POS_NOT_READY" | "POS_OFFLINE" | "ITEM_AVAILABILITY" | "MISSING_ITEM" | "MISSING_INFO" | "PRICING" | "CAPACITY" | "ADDRESS" | "SPECIAL_INSTRUCTIONS" | "OTHER", out_of_stock_items: string[], invalid_items: string[]): Promise<any> {
        const payload = {
            reason: {
                explanation, code, out_of_stock_items, invalid_items
            }
        }
        return Parse.Cloud.run('denyUberOrder', {
            orderId, payload: payload
        });
    }

    patchUberCart(orderId: string, fulfillment_issues: any[]) {
        const payload = {
            fulfillment_issues: fulfillment_issues
        }

        return Parse.Cloud.run('patchUberCart', { orderId, payload })
    }

    acceptUberOrder(orderId: string, reason: string): Promise<any> {
        const payload = {
            'reason': reason
        };
        return Parse.Cloud.run('acceptUberOrder', { orderId, payload: payload });
    }

    getUberOrderDetails(orderId: string): Promise<any> {
        return Parse.Cloud.run('getUberOrderDetails', { orderId })
    }

    disableUberItem(payload: any) {
        return Parse.Cloud.run('disableUberItem', payload);
    }

    setUberStoreStatus(payload: any) {
        return Parse.Cloud.run('setUberStoreStatus', payload);
    }

    getUberStoreDetails(payload: any) {
        return Parse.Cloud.run('getUberStoreDetails', payload)
    }

    updateUberPOSStatus(payload: any) {
        return Parse.Cloud.run('setUberPOSStatus', payload);
    }


    getStoreStatus(payload: any) {
        return Parse.Cloud.run('getUberStoreStatus', payload);
    }
    getUberStoresList() {
        return Parse.Cloud.run('getUberStoresList');
    }

    runMPPharmaciesItemCount() {
        return Parse.Cloud.run('mpPharmaciesItemCount');
    }

    loadOrdersByPharmacyId(id: string, limit: number = null) {
        const query = new Parse.Query(OrderMarketPlace)
            .equalTo('pharmacy', { __type: 'Pointer', 'className': 'Pharmacy', objectId: id });
        if (limit) query.limit(limit)
        return query.findAll();
    }
    loadActiveOrdersByPharmacyId(id: string, limit: number = null) {
        const query = new Parse.Query(OrderMarketPlace)
            .equalTo('pharmacy', { __type: 'Pointer', 'className': 'Pharmacy', objectId: id })
            .notContainedIn('status', ['CREATED', 'FINISHED', 'CANCELED', 'DENIED']);
        if (limit) query.limit(limit)
        return query.findAll();
    }
}