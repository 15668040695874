import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'c2u-empty-box-container',
  templateUrl: './c2u-empty-box-container.component.html',
  styleUrls: ['./c2u-empty-box-container.component.scss']
})
export class C2uEmptyBoxContainerComponent implements OnInit {

  @Input() text:string = "Nothing to see here!";
  @Input() textColor:string ="#ff1a75"


  @Output() actionClick =  new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  clickAction() {
    this.actionClick.emit(true);
  }
}
