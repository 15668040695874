import {  Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";

import { UserSuggestions } from "@chemist2u/types-client/C2U/ParseObjects/index.client";

@Injectable({ providedIn: 'root' })

export class FeedbackDataService {
    private feedbackData = new ReplaySubject<UserSuggestions>();
    public feedback = this.feedbackData.asObservable();
    constructor() {
        
    }
    setFeedbackData(usersuggestion:UserSuggestions){
        this.feedbackData.next(usersuggestion);
        console.log("HEYHEYHEYHEY!!!!!");
        console.log(usersuggestion);
        
    }
}
