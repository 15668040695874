import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'c2u-otc-item-row',
  templateUrl: './c2u-otc-item-row.component.html',
  styleUrls: ['./c2u-otc-item-row.component.scss']
})
export class C2uOtcItemRowComponent implements OnInit {

  @Input() item: any;
  @Input() showControls: boolean = false;
  @Output() remove = new EventEmitter<any>();
  @Output() minus = new EventEmitter<any>();
  @Output() plus = new EventEmitter<any>();

  public itemId: string = "";
  public itemSrc: string = "";
  public maxQuant: number = 1;
  public currentQuant: number = 1;


  public selected: string = "option1";
  
  constructor() { }

  ngOnInit(): void {
    this.itemId = this.item.id.split('_')[1];
    this.itemSrc = "https://c2u-prod.s3.ap-southeast-2.amazonaws.com/uber/resize/"+this.itemId+".jpg"
    this.maxQuant = this.item.quantity;
    this.currentQuant = this.item.quantity;
  }

  plusClick() {
    this.currentQuant += 1;
    const payload = {
      originalQuantity: this.item.quantity,
      currentQuantity: this.currentQuant,
      item: this.item
    }
    this.plus.emit(payload);
  }

  minusClick() {
    this.currentQuant -= 1;
    const payload = {
      originalQuantity: this.item.quantity,
      currentQuantity: this.currentQuant,
      item: this.item
    }
    this.minus.emit(payload);
  }

  removeClick() {
    this.currentQuant = 0;
    const payload = {
      originalQuantity: this.item.quantity,
      currentQuantity: this.currentQuant,
      item: this.item
    }
    this.remove.emit(payload);
  }

}
