import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { OrderHelper } from './helpers/order';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private $auth = inject(AuthService);
  private $order = inject(OrderHelper);
  constructor( private router: Router) {
    this.$order.errorEmitter.subscribe((errorCode) => {
      this.handleError(errorCode);
    });
  }

  async handleError(errorCode: String) {
    switch (errorCode) {
      case 'INVALID_SESSION':
        await this.$auth.logout();
        this.router.navigate(['/login']);
        break;
    }
  }
}

