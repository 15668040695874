import { Component, inject, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Promotion } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { BasePage } from 'src/app/pages/base-page';
import { FetchService } from 'src/app/services/fetch.service';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'dialog-add-promotion',
  templateUrl: './add-promotion.html',
  styleUrls: ['./add-promotion.scss'],
})

export class DialogAddPromotion extends BasePage implements OnInit {
  private $fetch = inject(FetchService);
  loadspinner: boolean = false;
  errormessage: string = '';

  constructor(
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddPromotion>,
    public loaderService: LoaderService
  ) {
    super(injector);
  }

  public options = [{
    name: 'Amount', value: 'amount'
  }, {
    name: 'Percentage', value: 'percentage'
  }];

  public selectedoption = 'Percentage';
  public promotion: Promotion;

  async ngOnInit(): Promise<void> {

    console.log(this.data);

    if (!this.data) {
      this.promotion = this.$fetch.newPromotionDefault();
      this.promotion.type = "percentage";
      this.promotion.value = 10;
      this.promotion.maxNrUses = 0;
      this.promotion.code = (Math.random() + 1).toString(36).substr(2, 5).toUpperCase()
      this.promotion.active = true;
      this.promotion.expiry = new Date();
    }
    else {
      this.loadspinner = true;
      this.promotion = await Promotion.getByID(this.data.id);
      this.loadspinner = false;
    }

  }

  async submitForm() {
    console.log(this.promotion);
    this.promotion.code = this.promotion.code.toUpperCase();

    if (!this.promotion.code) {
      this.errormessage = "Please check promo code";
      return;
    }
    else if (!this.promotion.value) {
      this.errormessage = "Please check value field";
      return;
    }
    else {
      this.errormessage = "";
    }

    try {
      this.loaderService.triggerLoading.emit(true);
      await this.promotion.save();

      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Successfully saved'
      });

      this.loaderService.triggerLoading.emit(false);
      this.dialogRef.close(this.promotion);

    } catch (error) {
      this.loaderService.triggerLoading.emit(false);

      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error
      });
    }
  }

}
