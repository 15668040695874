import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'c2u-pharmacy-report-row',
  templateUrl: './c2u-pharmacy-report-row.component.html',
  styleUrls: ['./c2u-pharmacy-report-row.component.scss']
})
export class C2uPharmacyReportRowComponent implements OnInit {

 //pass selected date to parent component
  @Output() getSelectedDate: EventEmitter<string> =   new EventEmitter();

  public selectedDate;

  @ViewChild('datePicker') datePicker: MatDatepicker<any>;

  dropDownDates(event) {
    this.selectedDate = moment(event).format('YYYY-MM');
    this.datePicker.close();
  }

  sendSelectedDate() {
    console.log(this.selectedDate);
    this.getSelectedDate.emit(this.selectedDate);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
