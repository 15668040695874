import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-order-status-button',
  templateUrl: './order-status-button.component.html',
  styleUrls: ['./order-status-button.component.scss']
})
export class OrderStatusButtonComponent implements ICellRendererAngularComp, OnInit {
  private params: any;

  @Input() status: string;

  constructor() { }

  ngOnInit(): void {

  }
  agInit(params: any): void {
    this.params = params;
    this.status = params.value;
  }

  public valueSquared(): number {
    return this.params.value * this.params.value;
  }

  refresh(): boolean {
    return false;
  }

}
