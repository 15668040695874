import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import C2U from '@chemist2u/types-client';


@Injectable({
    providedIn: 'root'
})
export class OrderUser {
    socketConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    socketConnectedObservable: Observable<boolean> = this.socketConnected.asObservable();

    adminStatuses: C2U.Socket.IAdminStatus[] = [];
    adminStatusesSubject: BehaviorSubject<C2U.Socket.IAdminStatus[]> = new BehaviorSubject<C2U.Socket.IAdminStatus[]>([]);
    adminStatusesObservable: Observable<C2U.Socket.IAdminStatus[]> = this.adminStatusesSubject.asObservable();

    pharmacyStatuses: C2U.Socket.IPharmacyStatus[] = [];
    pharmacyStatusesSubject: BehaviorSubject<C2U.Socket.IPharmacyStatus[]> = new BehaviorSubject<C2U.Socket.IPharmacyStatus[]>([]);
    pharmacyStatusesObservable: Observable<C2U.Socket.IPharmacyStatus[]> = this.pharmacyStatusesSubject.asObservable();

    singleCheckId: string = null;
    singleCheck: C2U.Socket.IPharmacyStatus = null;
    singleCheckSubject: BehaviorSubject<C2U.Socket.IPharmacyStatus> = new BehaviorSubject<C2U.Socket.IPharmacyStatus>(null);
    singleCheckObservable: Observable<C2U.Socket.IPharmacyStatus> = this.singleCheckSubject.asObservable();

    subbedToAdmin = false;
    unsubAdminTimeout: NodeJS.Timeout | undefined;
    subbedToPharmacies = false;
    unsubPharmaciesTimeout: NodeJS.Timeout | undefined;
    subbedToPharmacy = false;
    unsubPharmacyTimeout: NodeJS.Timeout | undefined;

    private readonly UNSUB_DEBOUNCE = 1000;

    constructor(private socket: Socket) {
        this.registerListeners();
    }

    subToAdminStatuses() {
        if (this.unsubAdminTimeout) {
            clearTimeout(this.unsubAdminTimeout);
            this.unsubAdminTimeout = undefined;
        } else if (!this.subbedToAdmin) {
            this.socket.emit('subToAdminStatuses');
            this.subbedToAdmin = true;
        }
    }

    unsubFromAdminStatuses() {
        if (!this.subbedToAdmin) return;
        this.unsubAdminTimeout = setTimeout(() => {
            this.socket.emit('unsubFromAdminStatuses');
            this.subbedToAdmin = false;
            clearTimeout(this.unsubAdminTimeout);
            this.unsubAdminTimeout = undefined;
        }, this.UNSUB_DEBOUNCE);
    }

    subToPharmacyStatuses() {
        if (this.unsubPharmaciesTimeout) {
            clearTimeout(this.unsubPharmaciesTimeout);
            this.unsubPharmaciesTimeout = undefined;
        } else if (!this.subbedToPharmacies) {
            this.socket.emit('subToPharmacyStatuses');
            this.subbedToPharmacies = true;
        }
    }

    unsubFromPharmacyStatuses() {
        if (!this.subbedToPharmacies) return;
        this.unsubPharmaciesTimeout = setTimeout(() => {
            this.socket.emit('unsubFromPharmacyStatuses');
            this.subbedToPharmacies = false;
            clearTimeout(this.unsubPharmaciesTimeout);
            this.unsubPharmaciesTimeout = undefined;
        }, this.UNSUB_DEBOUNCE);
    }

    subToPharmacyStatus(id: string) {
        if (this.unsubPharmacyTimeout) {
            clearTimeout(this.unsubPharmacyTimeout);
            this.unsubPharmacyTimeout = undefined;
        } else if (this.subbedToPharmacy) {
            this.socket.emit('subToPharmacyStatus', id);
            this.subbedToPharmacy = true;
        }
    }

    unsubFromPharmacyStatus(id: string) {
        if (!this.subbedToPharmacy) return;
        this.unsubPharmacyTimeout = setTimeout(() => {
            this.socket.emit('unsubFromPharmacyStatus', id);
            this.subbedToPharmacy = false;
            clearTimeout(this.unsubPharmaciesTimeout);
            this.unsubPharmaciesTimeout = undefined;
        }, this.UNSUB_DEBOUNCE);
    }
    
    updateAdminStatus(url: string) {
        let username = User.current().get("username");
        if (!username) {
            username = User.current().getUsername();
        }
        this.socket.emit('updateAdminStatus', { username, url });
    }
    
    updatePharmacyStatus(url: string) {
        const pharmacyStatus = {
            username: User.current().get("username"),
            id: User.current().id,
            location: url,
            lastseen: new Date(),
            connected: true
        };
        if (!pharmacyStatus.username) {
            pharmacyStatus.username = User.current().getUsername();
        }
        this.socket.emit('updatePharmacyStatus', pharmacyStatus);
    }

    setSingleCheckPharmacy(id: string) {
        this.singleCheckId = id;
        let pharmaFilter = this.pharmacyStatuses.filter(s => s.id == id);
        if (pharmaFilter.length) {
            this.singleCheck = pharmaFilter[0];
            this.singleCheckSubject.next(this.singleCheck);
        }
    }

    initialize() {
        if (environment.env === "admin") {
            console.error("WE ARE NOW INITIALIZING SOCKET.IO");
            this.socket.connect();
        }

        if (environment.env === "dispense") {
            console.log("SKIP SOCKET.IO INIT");
        }
    }

    private registerListeners() {
        this.socket.on("connect", this.onConnect.bind(this));
        this.socket.on("disconnect", this.onDisconnect.bind(this));
        this.socket.on("reconnect", this.onReconnect.bind(this));
        this.socket.on("allAdminStatuses", this.onAllAdminStatuses.bind(this));
        this.socket.on("allPharmacyStatuses", this.onAllPharmacyStatuses.bind(this));
        this.socket.on("updateForAdminStatuses", this.onUpdateForAdminStatuses.bind(this));
        this.socket.on("updateForPharmacyStatuses", this.onUpdateForPharmacyStatuses.bind(this));
        this.socket.on("updateForPharmacyStatus", this.onUpdateForPharmacyStatus.bind(this));
    }

    private onConnect(err) {
        if (err) {
            this.tryReconnect();
        } else {
            this.socketConnected.next(true);
        }
    }

    private onDisconnect() {
        this.tryReconnect();
    }

    private onReconnect() {
        this.socketConnected.next(true);
    }

    private onAllAdminStatuses(data) {
        console.log("onAllAdminStatuses", data);
        this.adminStatuses = data.adminStatuses;
        this.adminStatusesSubject.next(this.adminStatuses);
    }

    private onAllPharmacyStatuses(data) {
        console.log("onAllPharmacyStatuses", data);
        this.pharmacyStatuses = data.pharmacyStatuses;
        this.pharmacyStatusesSubject.next(this.pharmacyStatuses);
    }

    private onUpdateForAdminStatuses(data: { modified: C2U.Socket.IAdminStatus[], deleted: string[] }) {
        const modified = data.modified;
        const deletedIds = data.deleted;
        this.adminStatuses = this.adminStatuses.filter(s => {
            return !deletedIds.includes(s.socketid);
        }).map(s => {
            return modified.find(m => m.socketid == s.socketid) || s;
        });
        this.adminStatusesSubject.next(this.adminStatuses);
    }

    private onUpdateForPharmacyStatuses(data: { modified: C2U.Socket.IPharmacyStatus[] }) {
        console.log("onUpdateForPharmacyStatuses", data);
        const modified = data.modified;
        this.pharmacyStatuses = this.pharmacyStatuses.map(s => {
            return modified.find(m => m.id == s.id) || s;
        });
        this.pharmacyStatusesSubject.next(this.pharmacyStatuses);
    }

    private onUpdateForPharmacyStatus(data) {
        console.log("onUpdateForPharmacyStatus", data);
        let pharmacy = data.pharmacy;
        let index = this.pharmacyStatuses.findIndex(o => o.id == pharmacy.id);
        if (index !== -1) this.pharmacyStatuses[index] = pharmacy;
        if (index === -1) this.pharmacyStatuses.push(pharmacy);
        this.pharmacyStatusesSubject.next(this.pharmacyStatuses);

        if (this.singleCheckId && this.singleCheckId == pharmacy.id) {
            this.singleCheck = pharmacy;
            this.singleCheckSubject.next(this.singleCheck);
        }
    }

    private tryReconnect() {
        this.disconnect();
        setTimeout(() => {
            this.socket.connect();
        }, 1000);
    }

    disconnect() {
        if (this.socket.ioSocket.connected) {
            this.socket.disconnect();
        }
    }
}