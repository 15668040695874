<div class="pane">
    <div class="title">
        <h3>{{title}}</h3>
    </div>
    <div class="content">
        <p class="text" [ngClass]="{'action': hasActions}" (click)="goToLink()">{{label}}</p>
        <div class="actions" *ngIf="actions.length">
            <div class="action" *ngFor="let action of actions">
                <button [matTooltip]="action.label">
                    <div class="icon">
                        <img src="../assets/icons/{{action.icon}}.svg" />
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>