<h2 mat-dialog-title *ngIf="!hasComment">Add Comment</h2>
<h2 mat-dialog-title *ngIf="hasComment">Edit Comment</h2>
<mat-dialog-content>
    <div class="title">
        <h2 *ngIf="commentType=='pharmacyComment'">This note will be visible to the Pharmacy User</h2>
    </div>

    <mat-form-field>
        <mat-label>Search and Select Message</mat-label>
        <input matInput aria-label="Message type" [matAutocomplete]="auto" [formControl]="messageSearchCtrl">
        <button *ngIf="messageSearchCtrl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let type of filteredMessages" [value]="type.messageType" (click)="onMessageTypeSelect(type)">
                {{ type.messageType }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-form-field color='accent'>
        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" matInput [(ngModel)]="comment"
            placeholder="Enter your order note here...."></textarea>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button [disabled]="comment.length == 0" (click)="saveComment()" mat-flat-button color='accent'>Save
        Comment</button>
</mat-dialog-actions>