import { Component, Injector, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BasePage } from "../../pages/base-page";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MiscString } from "@chemist2u/types-client/C2U/ParseObjects";

@Component({
    selector: 'add-misc-string',
    templateUrl: './add-misc-string.component.html',
    styleUrls: ['./add-misc-string.component.scss']
})

export class AddMiscStringComponent extends BasePage implements OnInit {

    public categories: string[] = ['Customer Tag', 'Order Tag', 'Pharmacy Tag'];
    public tagColors: string[] = ['primary', 'accent', 'warn', 'info', 'success']

    public form: FormGroup;

    constructor(
        injector: Injector, 
        public dialogRef: MatDialogRef<AddMiscStringComponent>, 
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public dialogData: MiscString
    ) {
        super(injector)
        
        this.form = this.fb.group({
            selectedCategory: ['', [Validators.required]],
            selectedTagColor: [''],
            textValue: ['']
        });

        const data = dialogData;
        if (data) {
            this.form.setValue({
                selectedCategory: this.formatCategory(data.type),
                selectedTagColor: data?.metadata?.color,
                textValue: data.value
            })
        }
    }

    get selectedCategory(): FormControl<string> {
        return this.form.get('selectedCategory') as FormControl<string>;
    }

    get selectedTagColor(): FormControl<string> {
        return this.form.get("selectedTagColor") as FormControl<string>;
    }

    get textValue(): FormControl<string> {
        return this.form.get('textValue') as FormControl<string>;
    }

    ngOnInit(): void {
    }

    save() {
        const { selectedCategory, selectedTagColor, textValue } = this.form.getRawValue();
        const camelCaseCategory = selectedCategory.split(" ").reduce((a, s, index) => {
            if (index === 0) s = s.toLowerCase();
            a = `${a}${s}`
            return a;
        }, '')
                
        const result = {
            metadata: { color: selectedTagColor },
            value: textValue.trim(),
            type: camelCaseCategory
        }

        this.dialogRef.close({ data: result })
    }

    formatCategory(type: string): string {
        const upperCaseIndex = type.split('').findIndex(c => c.toUpperCase() === c);
        return `${type[0].toUpperCase()}${type.slice(1, upperCaseIndex)} ${type.slice(upperCaseIndex, type.length)}`
    }

}