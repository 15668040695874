<h2 mat-dialog-title>User Profile</h2>
<mat-dialog-content>

    <div *ngIf="pharmacy">
        <h4>General</h4>
        <div class="general">
            <mat-form-field>
                <mat-label>Business Name</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.businessName">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Company Name</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.companyName">
            </mat-form-field>

            <mat-form-field>
                <mat-label>ABN</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.ABN">
            </mat-form-field>

            <mat-form-field>
                <mat-label>ACN</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.ACN">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Website</mat-label>
                <input matInput type="url" [(ngModel)]="pharmacy.website">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Store Tel</mat-label>
                <input matInput type="tel" [(ngModel)]="pharmacy.primaryPhone">
            </mat-form-field>
        </div>
        <h4>Address</h4>
        <div class="address">
            <mat-form-field>
                <mat-label>Street 1</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.address.street1">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Street 2</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.address.street2">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Suburb</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.address.suburb">
            </mat-form-field>

            <mat-form-field>
                <mat-label>PostCode</mat-label>
                <input matInput type="number" [(ngModel)]="pharmacy.address.postCode">
            </mat-form-field>

            <mat-form-field>
                <mat-label>State</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.address.state">
            </mat-form-field>

        </div>

        <h4>Contact</h4>
        <div class="contact">
            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.contact.first_name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput type="text" [(ngModel)]="pharmacy.contact.last_name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Phone</mat-label>
                <input matInput type="tel" [(ngModel)]="pharmacy.contact.phone">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" [(ngModel)]="pharmacy.contact.email">
            </mat-form-field>

        </div>
    </div>
    <div>
        <mat-error>{{errorMessage}}</mat-error>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color='accent' *ngIf="!pharmacy?.status" (click)="updateProfile()">Update</button>
</mat-dialog-actions>