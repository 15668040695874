import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItem, SIDEBAR_MENU_ITEMS } from '../../shared/data/constants/sidebar-menu';
import { PHARMACY_SIDEBAR_MENU_ITEMS } from 'src/app/shared/data/constants/pharmacy-sidebar-menu';
import { Order, OrderMarketPlace, Pharmacy, PharmacyComment, User, UserSuggestions } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { LoaderService } from '../../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogLeaveFeedback } from '../../dialogs/leave-feedback/leave-feedback.page';
import { FeedbackDataService } from 'src/app/services/state/feedback.services';
import { DialogUserProfile } from 'src/app/dialogs/user-profile/user-profile.page';
import { NavigationService } from 'src/app/services/state/navigation.service';
import { Howl } from 'howler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/global/data.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { FetchService } from 'src/app/services/fetch.service';
import { OrderHelper } from 'src/app/services/helpers/order';
import { MarketplaceHelper } from 'src/app/services/helpers/marketplace';
import { TOrderFilterStatus } from 'src/app/pages/orders/status-orders/status-orders.page';
import { TOrderFulfillmentMethodClickAndCollect } from '@chemist2u/types-client/C2U/Interfaces';

type TAllowedLiveQueryEvents = 'create' | 'update' | 'enter';
export type OrderFilterMap = Partial<{ [key in TOrderFilterStatus]: number }>;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  private $auth = inject(AuthService);
  private $state = inject(StateService);
  private $fetch = inject(FetchService);
  private $order = inject(OrderHelper);
  private $marketplace = inject(MarketplaceHelper);

  public pageUrl = '/';
  public sidebarTitle: string;
  public userData: User;
  public pharmacy: Pharmacy;

  public menuItems: MenuItem[] = [];

  public isPharmacyUserLoggedIn: boolean;
  public ordersMarketPlaceLive: Parse.LiveQuerySubscription | undefined;
  public c2uOrders$: Parse.LiveQuerySubscription | undefined;
  private c2uOrderUpdate$: BehaviorSubject<{ type: TAllowedLiveQueryEvents, order: Order }> = new BehaviorSubject(null);

  // public avgApprovalTime: number;
  public pharmacyNotes: PharmacyComment[] = [];
  public inboxLength: Number;
  private notificationSound: Howl;
  public goToString: string;
  public orderCounts: OrderFilterMap | undefined;
  public orderTypesWithCounts: TOrderFilterStatus[] = [];
  private orderSub$: Subscription | undefined;
  public orders: Order[] = [];
  private pharmacyLiveComments$: Parse.LiveQuerySubscription | undefined;
  private ozempicItems = ['ozempic', 'semaglutide', 'wegovy', 'rybelsus'];
  private mounjaroItems = ['tirzepatide', 'mounjaro'];

  private orderNotifcationDuration: number = 3000;

  constructor(
    private router: Router,
    private loaderService: LoaderService,
    public dialog: MatDialog,
    public feedbackDataService: FeedbackDataService,
    public navigation: NavigationService,
    private snackBar: MatSnackBar,
    private data: DataService,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.pageUrl = this.router.url);
    this.initializeNotificationSound();
  }

  private initializeNotificationSound(): void {
    this.notificationSound = new Howl({
      src: ['../../../assets/audio/new-uber-order-audio.mp3'],
      autoplay: false, // Turn off autoplay
      loop: true,
      volume: 0.5,
    });
  }

  setSidebarTitle(title: string) {

    this.sidebarTitle = title;
  }

  initializeSidebar() {
    // find title according to the url
    this.pageUrl = this.router.url;
    for (let menu of this.menuItems) {
      if (menu.url == this.router.url) {
        this.sidebarTitle = menu.name;
        return;
      }

      for (let cat of menu.categories) {
        for (let nest of cat.nestedItems) {
          if (nest.url == this.router.url) {
            this.sidebarTitle = menu.name;
            return;
          }
        }
      }
    }
  }

  feedbackClick() {
    console.log("FEEDBACK");
    const dialogRef = this.dialog.open(DialogLeaveFeedback, {
      width: '800px'
    });
    const dialogData = dialogRef.afterClosed();
    dialogData.subscribe((data: UserSuggestions) => {
      if (data) {
        this.feedbackDataService.setFeedbackData(data);
      }
    });
  }


  menuItemBelongsToPage(menuItem: string) {
    if (this.pageUrl) {
      return this.pageUrl.indexOf(menuItem) !== -1 && !this.isHomePage();
    }
  }

  isHomePage() {
    return this.pageUrl === '/';
  }

  async ngOnInit() {
    this.userData = await User.current().fetch() as User;
    if (this.router.routerState.snapshot.url.includes('orders')) {
      if (!this.data.bOrderSub.getValue()) this.data.initialiseLiveQuery();
      await this.data.getOrders();
      this.getOrderCounts();
    }

    this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe(async val => {
        if ((val['url'].includes('orders') && !this.orderSub$) || val['url'] == '/orders/inbox') {
          await this.data.getOrders();
          this.getOrderCounts();
        }
        if (!val['url'].includes('orders')) {
          this.orders = [];
          if (this.orderSub$) this.orderSub$.unsubscribe();
          this.orderSub$ = undefined;

          this.pharmacyNotes = [];
          if (this.pharmacyLiveComments$) this.pharmacyLiveComments$.unsubscribe();
          this.pharmacyLiveComments$ = undefined;
        }
      })

    this.$state.isLoggedIn.subscribe(async userLoggedIn => {
      if (userLoggedIn) {
        if (this.router.url.includes('orders') && !this.orderSub$) {
          this.getOrderCounts();
          if (!this.sidebarTitle) this.sidebarTitle = 'Orders';
        }

        this.menuItems = SIDEBAR_MENU_ITEMS;
        this.initializeSidebar();

        //admin event tracking | i think we should just stop doing that
        // this.avgApprovalTime = await this.eventTracking.avgTimeForApproval();
      }
    });
    this.$state.isPharmacyLoggedIn.subscribe(async (pharmacyLoggedIn: boolean) => {
      if (pharmacyLoggedIn) {
        if (this.orderSub$) this.orderSub$.unsubscribe();
        //is it a uber pharmacy
        this.pharmacy = await this.$fetch.getUberPharmacyByUser(this.userData);
        await this.setupPharmacyOrders();
        if (!this.pharmacy) {
          //remove uber urls from the pharmacy sidebar menu
          PHARMACY_SIDEBAR_MENU_ITEMS.map(item => {
            // if (item.name == "Orders") {
            //   item.categories = item.categories.filter(i => i.categoryName !== 'Market Place')
            // }

            item.categories.map(c => {
              c.nestedItems = c.nestedItems.filter(i => !i.url.includes('market-place'))
              return c;
            })

            return item;
          })
        }
        this.isPharmacyUserLoggedIn = pharmacyLoggedIn;
        this.menuItems = PHARMACY_SIDEBAR_MENU_ITEMS;
        this.initializeSidebar();
      }
    })

  }

  async setC2UOrderQueryListeners(): Promise<void> {
    try {
      this.c2uOrders$ = await this.$order.getSubscription();

      this.c2uOrders$.on('update', (order: Order) => {
        const val = { type: 'update' as TAllowedLiveQueryEvents, order };
        this.c2uOrderUpdate$.next(val);
      });
      this.c2uOrders$.on('create', (order: Order) => {
        const val = { type: 'create' as TAllowedLiveQueryEvents, order };
        this.c2uOrderUpdate$.next(val);
      });
      this.c2uOrders$.on('enter', (order: Order) => {
        const val = { type: 'enter' as TAllowedLiveQueryEvents, order };
        this.c2uOrderUpdate$.next(val);
      });

      this.c2uOrderUpdate$
        .subscribe((value: { type: TAllowedLiveQueryEvents, order: Order }) => {
          try {
            if (!value) return;
            const { type, order } = value;

            if ((type === 'create' || type === 'update' || type === 'enter') && order.status === 'Paid' && !this.orders.find(o => o.id === order.id)) {
              this.navigation.newC2uOrderURL = `/p-orders/manage/${order.id}`;
              this.snackBar.open(`New C2U order ${order.orderID}`, 'View', {
                horizontalPosition: 'end',
                verticalPosition: 'top',
                duration: this.orderNotifcationDuration,
              }).onAction().subscribe(() => this.router.navigateByUrl(`/p-orders/manage/${order.id}`));
              if (!this.navigation.audio) this.playNotifcation();
              this.orders.push(order);
            }
          } catch (err) {
            console.log("ERROR IN DATA UPDATE C2U", err);
          }
        });
    } catch (error) {
      console.log("Error setting up query listeners", error)
    }
  }

  async playNotifcation(): Promise<void> {
    if (!this.notificationSound?.playing()) {
      this.notificationSound.play();
      this.navigation.audio = this.notificationSound;
    }
  }

  ngOnDestroy() {
    if (this.orderSub$) {
      this.orderSub$.unsubscribe();
      this.orderSub$ = undefined;
    }
    if (this.pharmacyLiveComments$) {
      this.pharmacyLiveComments$.unsubscribe();
      this.pharmacyLiveComments$ = undefined;
    }
    if (this.c2uOrders$) {
      this.c2uOrders$.unsubscribe();
      this.c2uOrders$ = undefined;
    }
    if (this.ordersMarketPlaceLive) {
      this.ordersMarketPlaceLive.unsubscribe();
      this.ordersMarketPlaceLive = undefined;
    }
  }

  async logout() {
    this.loaderService.triggerLoading.emit(true);
    await this.$auth.logout();
    this.router.navigate(['/login']);
    this.loaderService.triggerLoading.emit(false);
  }

  async viewProfile() {
    if (this.isPharmacyUserLoggedIn) {
      console.log(this.userData);
      const dialogRef = this.dialog.open(DialogUserProfile, {
        width: '800px',
        data: this.userData,

      });
      const data = dialogRef.afterClosed();
      data.subscribe(async data => {
        if (data) {
          console.log("-----");
          console.log(data);
          console.log("-----");

        }
      });
    }


  }


  async getOrderCounts() {
    this.orders = this.data.bOrders.getValue();

    this.orderSub$ = this.data.bOrders.subscribe((orders) => {
      this.orders = orders;
      this.buildOrderCountObj();
    });

    // this.buildOrderCountObj();
    this.buildPharmacyCommentCount();

    // get pharmacy count live
    this.pharmacyLiveComments$ = await this.$fetch.getCommentSubscribtion();
    this.pharmacyLiveComments$.on('create', (comment: PharmacyComment) => {
      if (comment.resolved == false) {
        this.pharmacyNotes.push(comment);
        this.buildPharmacyCommentCount();
      }
    });

    this.pharmacyLiveComments$.on('update', (comment: PharmacyComment) => {
      const commentIndex = this.pharmacyNotes.map(e => e.id).indexOf(comment.id)
      if (comment.resolved == true) {
        this.pharmacyNotes.slice(commentIndex, 1);
      } else {
        this.pharmacyNotes[commentIndex] = comment;
      }
      this.buildPharmacyCommentCount();

    });

    this.pharmacyLiveComments$.on('delete', (comment: PharmacyComment) => {
      const cIndex = this.pharmacyNotes.map(e => e.id).indexOf(comment.id);
      this.pharmacyNotes.splice(cIndex, 1);
      this.buildPharmacyCommentCount();
    });
  }

  async buildOrderCountObj() {
    const countObj: OrderFilterMap = {
      "Ready": 0,
      "Delivery Booked": 0,
      "Paid": 0,
      "Pharmacy Approved": 0,
      "Approved": 0,
      "Unpaid": 0,
      "Inbox": 0,
      "Hub Orders": 0,
      "Program": 0,
      "Urgent": 0,
      "Shipped Out": 0,
      "Pending": 0,
    };
    const postalPharmacies = ['L1Hl7GxUfz', 'FPbqNfrARu', 'x5sE9w8DK9', 'cSHljRDoK8', 'YFK8WxChK0', 'eXX5RiJ5Hg', 'Pc37AECWZH', 'Zq25BOOuWI', 'eBZp1O14qG', 'B44PNhvAV4', '9Zb2w7CAtP', 'iRuHQSDqrM', 'IclPP2vphJ', 'xTiyA76Zkn'];
    const postalStatusExceptions = ['Ready', 'Paid', 'Pharmacy Approved', 'Approved', 'Unpaid'];
    console.log(this.orders.length, "orders");

    console.log(this.orders.filter(o => o.type === 'subscription').length, "subscriptions");
    
    
    this.orders.forEach(o => {
      const isPostal = postalPharmacies.includes(o.pharmacy.objectId);
      if (o.type === 'subscription'){
        console.log( o.type);

        countObj["Program"] += 1;
      }
      else if (isPostal && this.containsOzempicOrMounjaro(o) && postalStatusExceptions.includes(o.get('status'))) {
        countObj["Hub Orders"] += 1;
      } 
      else {
        if(["Paid", "Pharmacy Approved", "Ready"].includes(o.status)) {
           // delivery booked
            if (o.status === 'Ready' && (o.deliveryDetails != null || o.deliveryDetails != undefined)) {
              countObj["Delivery Booked"] += 1;
            }
            // urgent
            else if(this.checkShiftTime(o)) {
              countObj["Urgent"] += 1;
            }
            else {
              countObj[o.get('status')] += 1;
            }

        }
        else {
          countObj[o.get('status')] += 1;
        }
      }
    })
    countObj["Inbox"] = this.pharmacyNotes && this.pharmacyNotes.length ? this.pharmacyNotes.length : 0;
    this.orderCounts = countObj;
    this.orderTypesWithCounts = Object.keys(countObj) as TOrderFilterStatus[];
  }

  checkShiftTime(order: Order): boolean {
    const allocatedShift = order.allocatedShift;
    if(order.fulfillmentDetails && order.fulfillmentDetails.selectedMethod.method === 'clickAndCollect') return false;
    if(order.fulfillmentDetails && order.fulfillmentDetails.selectedMethod.method === 'Standard') return false;
    if (!allocatedShift || !["Paid", "Pharmacy Approved"].includes(order.status)) return false;
    if (this.containsOzempicOrMounjaro(order) && order?.metadata?.partnerId !=='Bupa') return false;
    if(order.status === 'Ready' && order.deliveryDetails) return false;

    const pickup = new Date(allocatedShift.pickup);
    const now = new Date();
    // difference is less than 30 min then it is urgent
    return (pickup.getTime() - now.getTime()) < 1800000;
  }

  async buildPharmacyCommentCount() {
    this.pharmacyNotes = await this.$fetch.loadAllPharmacyComment();
    this.orderCounts["Inbox"] = this.pharmacyNotes.length;
  }

  async goTo() {
    if (!this.goToString) alert("Please man you gotta enter an order ID");
    else {
      const order = await this.$order.getOrderByOrderID(this.goToString.toUpperCase());
      const match = order ? order.id : null;
      if (match) {
        const url = this.router.serializeUrl(this.router.createUrlTree([`/orders/manage/${match}`]));
        window.open(url, '_blank');
      } else {
        alert("No order found for this id")
      }
      setTimeout(() => this.goToString = '', 1000);
    }
  }

  get showGoToSearch() {
    if (this.isPharmacyUserLoggedIn) return false;
    if (this.pageUrl.includes('orders') && !this.pageUrl.includes('market-place')) return true;
    return false;
  }


  containsOzempic(order: Order) {
    return order.prescriptions.some(p => {
      const items = p.items;
      return items.some(i => this.ozempicItems.some(eItem => i.productName.toLowerCase().includes(eItem)));
    })
  }

  containsMounjaro(order: Order) {
    return order.prescriptions.some(p => {
      const items = p.items;
      return items.some(i => this.mounjaroItems.some(eItem => i.productName.toLowerCase().includes(eItem)));
    })
  }

  containsOzempicOrMounjaro(order: Order) {
    return order.prescriptions.some(p => {
      const items = p.items;
      return items.some(i => [...this.ozempicItems, ...this.mounjaroItems].some(eItem => i.productName.toLowerCase().includes(eItem)));
    })
  }

  async setupPharmacyOrders() {
    try {
      this.orders = await this.$order.loadActivePharmacyOrdersById(this.pharmacy.id) as Order[];
      this.setupNotificationListeners();
      this.setC2UOrderQueryListeners();
      this.setMPOrderQueryListeners();
    } catch (err) {
      console.log("Error setting up pharmacy order listeners", err);
    }
  }

  async setupNotificationListeners() {
    const orders = this.orders.filter(order => {
      if (order.status === 'Ready' && order.fulfillmentDetails && order.fulfillmentDetails.selectedMethod.method === 'clickAndCollect') {
        if ((order.fulfillmentDetails as TOrderFulfillmentMethodClickAndCollect).clickAndCollectPin) return order;
      }
    })
    console.log("orders",orders);
    
    this.data.pharmacyNotfications.next(orders);
  }

  async setMPOrderQueryListeners() {
    this.ordersMarketPlaceLive = await this.$marketplace.getSubscription();
    this.ordersMarketPlaceLive.on('create', (mpOrder: OrderMarketPlace) => {
      this.snackBar.open(`New ${mpOrder.provider} order ${mpOrder.orderID}`, 'View', {
        horizontalPosition: 'end',
        verticalPosition: 'top',
        duration: this.orderNotifcationDuration,
      }).onAction().subscribe(() => this.router.navigateByUrl(`/p-orders/market-place/${mpOrder.provider.toLowerCase()}/${mpOrder.id}`))

      if (this.navigation.audio) return;
      this.navigation.newUberOrderURL = `/p-orders/market-place/${mpOrder.provider.toLowerCase()}/${mpOrder.id}`;
      this.playNotifcation();
    });
  }
}
