<mat-form-field>
    <mat-label>Month and Year</mat-label>
    <input matInput [matDatepicker]="dp" [formControl]="date">
    <mat-hint>MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
    <mat-datepicker #dp
                    startView="year"
                    (monthSelected)="setMonthYear($event, dp)"
                    panelClass="example-month-picker">
    </mat-datepicker>
  </mat-form-field>