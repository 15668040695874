import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';

@Component({
  selector: 'c2u-comment-row',
  templateUrl: './c2u-comment-row.component.html',
  styleUrls: ['./c2u-comment-row.component.scss']
})
export class C2uCommentRowComponent implements OnInit {

  //input
  @Input() comment:any;


  //output
  @Output() edit = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();

  public user:User;


  constructor() { }

  ngOnInit(): void {
    this.user = User.current();
  }

  editClick(comment:any) {
    console.log(comment);
    this.edit.emit(comment);

  }

  deleteClick(comment:any) {
    console.log(comment);
    this.delete.emit(comment);
  }

}
