import { Component, Injector, Inject } from '@angular/core';
import { BasePage } from '../../pages/base-page';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserSuggestions } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { LoaderService } from '../../services/loader.service';
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';


@Component({
    selector: 'dialog-leave-feedback',
    templateUrl: './leave-feedback.html',
    styleUrls: ['./leave-feedback.scss']
})

export class DialogLeaveFeedback extends BasePage {
    constructor(injector: Injector,
        public dialogRef: MatDialogRef<DialogLeaveFeedback>,
        @Inject(MAT_DIALOG_DATA) public data: unknown,
        public loaderService: LoaderService) {
        super(injector);


        
    
    }

    public feedback: { area: string, description: string, importance: number} = {
        area: "",
        description: "",
        importance: 0
    }

    public feedbackForm: UntypedFormGroup;

    async ngOnInit() {
       this.feedbackForm = new UntypedFormGroup({
           area: new UntypedFormControl('',[Validators.required]),
           description: new UntypedFormControl('',[Validators.required]),
           importance: new UntypedFormControl(0,[Validators.required,Validators.max(5), Validators.min(0)])
       })
    }

    get area() {
        return this.feedbackForm.get('area');
    }

    get description() {
        return this.feedbackForm.get('description');
    }

    get importance() {
        return this.feedbackForm.get('importance');
    }

    async saveFeedback() {
        try {
            this.loaderService.triggerLoading.emit(true);
            let saveObject =  new UserSuggestions();
            saveObject.user = User.current() as User;
            saveObject.area = this.feedback.area;
            saveObject.description = this.feedback.description;
            saveObject.importance = this.feedback.importance;
            await saveObject.save();
            this.dialogRef.close(saveObject);
            this.showNotification(`Feedback submitted`, "close", 3000);
            this.loaderService.triggerLoading.emit(false);
        } catch(error) {
            console.log(error);
            this.loaderService.triggerLoading.emit(false);
        }
        
    }



}