import { Component, inject, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User, Pharmacy } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { LoaderService } from 'src/app/services/loader.service';
import Swal from 'sweetalert2';
import { FetchService } from 'src/app/services/fetch.service';

@Component({
    selector: 'user-profile-user-profile',
    templateUrl: './user-profile.html',
    styleUrls: ['./user-profile.scss']
})
export class DialogUserProfile implements OnInit {
    private $fetch = inject(FetchService);
    public pharmacy: Pharmacy;

    public errorMessage = '';

    constructor(
        public dialogRef: MatDialogRef<DialogUserProfile>,
        @Inject(MAT_DIALOG_DATA) public data: User,
        public loaderService: LoaderService,
        

    ) { }

    async ngOnInit() {
        this.pharmacy = await this.$fetch.getPharmacyByUser(this.data);
        console.log("PHARMACY");
        console.log(this.pharmacy);

    }

    async updateProfile() {
        console.log("UPDATE PROFILE");

        //validation
        if (!this.pharmacy.businessName || !this.pharmacy.companyName
            || !this.pharmacy.ABN || !this.pharmacy.primaryPhone) {
            this.errorMessage = "Please fill all the required fields in the general section";
            return;
        }
        else if (!this.pharmacy.address.street1 || this.pharmacy.address.suburb
            || !this.pharmacy.address.postCode || !this.pharmacy.address.state) {
            this.errorMessage = "Please fill all the required fields in the address section"
            return;
        } else if (!this.pharmacy.contact.first_name || !this.pharmacy.contact.phone
            || !this.pharmacy.contact.email) {
            this.errorMessage = "Please fill all the required fields in the contact section"
            return;
        } else {
            this.errorMessage = ""
        }

        //save the details
        try {
            this.loaderService.triggerLoading.emit(true);

            //await this.pharmacy.save(null);

            // Swal.fire({
            //     icon: 'success',
            //     title: 'Success',
            //     text: 'Successfully Updated'
            // });

            this.dialogRef.close();

            this.loaderService.triggerLoading.emit(false);
        } catch (error) {

            this.loaderService.triggerLoading.emit(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error
            });
        }
    }
}
