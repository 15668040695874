<div class="content">
  <div class="events">
    <table mat-table [dataSource]="timelineDataSource">

      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef> Timestamp </th>
        <td mat-cell *matCellDef="let row"> {{row.timestamp | date: "E d/M h:mm a" }} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row"> {{row.action}} </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef> User </th>
        <td mat-cell *matCellDef="let row"> {{row.user}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="timelineHeaders;sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: timelineHeaders;" (click)="clickAction(row)"></tr>
    </table>

  </div>

</div>