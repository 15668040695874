import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotifyComponent } from './notify/notify.component';
import { LoaderComponent } from './loader/loader.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCustomSelectComponent } from './components/mat-custom-select/mat-custom-select.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollContainerComponent } from './components/scroll-container/scroll-container.component';
import { OrderStatusButtonComponent } from './components/order-status-button/order-status-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { CustomDateDisplayComponent } from './components/custom-date-display/custom-date-display.component';
import { AppDialogComponent } from './components/app-dialog/app-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { GridDatatableComponent } from './components/grid-datatable/grid-datatable.component';
import { AgGridModule } from 'ag-grid-angular';
import { DialogChangeAddress } from '../dialogs/change-address/change-address.page';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogAddPromotion } from '../dialogs/add-promotion/add-promotion.page';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { OrderActionBarComponent } from './components/order-action-bar/order-action-bar.component';
import { C2uButtonComponent } from './components/c2u-button/c2u-button.component';
import { C2uPaneComponent } from './components/c2u-pane/c2u-pane.component';
import { C2uOtcItemRowComponent } from './components/c2u-otc-item-row/c2u-otc-item-row.component';
import { C2uOtcItemGridComponent } from './components/c2u-otc-item-grid/c2u-otc-item-grid.component';
import { NavPaneComponent } from './components/navs/nav-pane/nav-pane.component';
import { C2uSummmaryRowComponent } from './components/c2u-summmary-row/c2u-summmary-row.component';
import { C2uTupleRowComponent } from './components/c2u-tuple-row/c2u-tuple-row.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { C2uPharmacyReportRowComponent } from './components/c2u-pharmacy-report-row/c2u-pharmacy-report-row.component';
import { DialogAddOrderComment } from '../dialogs/add-order-comment/add-order-comment.page';
import { C2uOrderCommentGridComponent } from './components/c2u-order-comment-grid/c2u-order-comment-grid.component';
import { C2uCommentRowComponent } from './components/c2u-comment-row/c2u-comment-row.component';
import { C2uEmptyBoxContainerComponent } from './components/c2u-empty-box-container/c2u-empty-box-container.component';
import { C2uEventTrackingTableComponent } from './components/c2u-event-tracking-table/c2u-event-tracking-table.component';
import { NavMarketPlaceOrderComponent } from './components/navs/nav-market-place-order/nav-market-place-order.component';
import { C2uMpOtcItemGridComponent } from './components/c2u-mp-otc-item-grid/c2u-mp-otc-item-grid.component';
import { C2uMpOtcItemRowComponent } from './components/c2u-mp-otc-item-row/c2u-mp-otc-item-row.component';
import { DialogShowMarketPlaceEvent } from '../dialogs/show-market-place-event/show-market-place-event.page';
import { GridTableComponent } from './components/grid-table/grid-table.component';
import { MatBadgeModule } from '@angular/material/badge'
import { CustomerOzempicTrackerComponent } from './components/customer-ozempic-tracker/customer-ozempic-tracker.component';
import { MonthYearPickerComponent } from './components/month-year-picker/month-year-picker.component';
import { DialogEditPharmacyHours } from '../dialogs/pharmacy/edit-pharmacy-holiday-hours/edit-pharmacy-holiday-hours.page';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatRadioModule } from '@angular/material/radio';
import { InboxMessageComponent } from './components/inbox-message/inbox-message.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { InboxFolderComponent } from './components/inbox-folder/inbox-folder.component';
import { EditPharmacyFulfilmentMethodsComponent } from '../dialogs/pharmacy/edit-pharmacy-fulfilment-methods/edit-pharmacy-fulfilment-methods.component';

import { HubsDialogComponent } from './components/hubs-dialog/hubs-dialog.component';
import { AutoCompleteInputComponent } from './components/auto-complete-input/auto-complete-input.component';
import { MatDividerModule } from "@angular/material/divider";
import { C2uDeliveryMethodComponent } from './components/c2u-delivery-method/c2u-delivery-method.component'
import { ChipInputComponent } from './components/chip-input/chip-input.component'
import { MatChipsModule } from '@angular/material/chips';
import { AddMiscStringComponent } from '../dialogs/add-misc-string/add-misc-string.component';
@NgModule({
    declarations: [
        NotifyComponent,
        LoaderComponent,
        MatCustomSelectComponent,
        SearchBoxComponent,
        ScrollContainerComponent,
        OrderStatusButtonComponent,
        CustomDateDisplayComponent,
        AppDialogComponent,
        GridDatatableComponent,
        DialogChangeAddress,
        DialogAddPromotion,
        OrderActionBarComponent,
        C2uButtonComponent,
        C2uPaneComponent,
        C2uOtcItemRowComponent,
        C2uOtcItemGridComponent,
        NavPaneComponent,
        C2uSummmaryRowComponent,
        C2uTupleRowComponent,
        C2uPharmacyReportRowComponent,
        DialogAddOrderComment,
        C2uOrderCommentGridComponent,
        C2uCommentRowComponent,
        C2uEmptyBoxContainerComponent,
        C2uEventTrackingTableComponent,
        NavMarketPlaceOrderComponent,
        C2uMpOtcItemGridComponent,
        C2uMpOtcItemRowComponent,
        DialogShowMarketPlaceEvent,
        GridTableComponent,
        CustomerOzempicTrackerComponent,
        DialogEditPharmacyHours,
        InboxMessageComponent,
        InboxFolderComponent,
        EditPharmacyFulfilmentMethodsComponent,
        HubsDialogComponent,
        AutoCompleteInputComponent,
        C2uDeliveryMethodComponent,
        ChipInputComponent,
        AddMiscStringComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatIconModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FormsModule,
        MatMenuModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSlideToggleModule,
        MatTableModule,
        AgGridModule,
        MatBadgeModule,
        MonthYearPickerComponent,
        NgxMaterialTimepickerModule,
        MatRadioModule,
        MatExpansionModule,
        MatDividerModule,
        MatChipsModule
    ],
    exports: [
        NotifyComponent,
        LoaderComponent,
        MatCustomSelectComponent,
        SearchBoxComponent,
        ScrollContainerComponent,
        OrderStatusButtonComponent,
        CustomDateDisplayComponent,
        AppDialogComponent,
        DialogChangeAddress,
        GridDatatableComponent,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatInputModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatButtonModule,
        ScrollingModule,
        MatMenuModule,
        MatDialogModule,
        AgGridModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        OrderActionBarComponent,
        C2uButtonComponent,
        C2uPaneComponent,
        C2uOtcItemRowComponent,
        C2uOtcItemGridComponent,
        NavPaneComponent,
        C2uSummmaryRowComponent,
        C2uTupleRowComponent,
        MatTooltipModule,
        C2uPharmacyReportRowComponent,
        C2uOrderCommentGridComponent,
        C2uCommentRowComponent,
        C2uEmptyBoxContainerComponent,
        C2uEventTrackingTableComponent,
        NavMarketPlaceOrderComponent,
        C2uMpOtcItemGridComponent,
        C2uMpOtcItemRowComponent,
        GridTableComponent,
        MatBadgeModule,
        MatSlideToggleModule,
        CustomerOzempicTrackerComponent,
        MonthYearPickerComponent,
        DialogEditPharmacyHours,
        InboxMessageComponent,
        InboxFolderComponent,
        C2uDeliveryMethodComponent,
        ChipInputComponent,
        AddMiscStringComponent
    ]
})
export class SharedModule {
}
