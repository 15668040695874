import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-custom-date-display',
  templateUrl: './custom-date-display.component.html',
  styleUrls: ['./custom-date-display.component.scss']
})
export class CustomDateDisplayComponent implements ICellRendererAngularComp, OnInit {
  private params: any;
  public format: any;
  dateTime;

  constructor() {
  }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
    this.dateTime = params.value;
    this.format = params.format;
  }

  public valueSquared(): number {
    return this.params.value * this.params.value;
  }

  refresh(): boolean {
    return false;
  }

}
