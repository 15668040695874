import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'c2u-otc-item-grid',
  templateUrl: './c2u-otc-item-grid.component.html',
  styleUrls: ['./c2u-otc-item-grid.component.scss']
})
export class C2uOtcItemGridComponent implements OnInit {

  constructor() { }

  @Input() items: any = [];
  @Input() showControls: boolean = false;
  @Output() minusClick = new EventEmitter<any>();
  @Output() plusClick = new EventEmitter<any>();
  @Output() removeClick = new EventEmitter<any>();
 

  ngOnInit(): void {
    this
  }

  plusRowClick(item:any) {
    this.plusClick.emit(item);
  }

  minusRowClick(item:any) {
    this.minusClick.emit(item);
  }

  removeRowClick(item:any) {
    this.removeClick.emit(item);
  }




}
