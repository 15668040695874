export const environment = {
    production: false,
    serverUrl: 'https://stagingmanage.c2u.co/parse',
    liveQueryServerURL: 'wss://stagingmanage.c2u.co/parse',
    appUrl: 'https://stagingmanage.c2u.co',
    appImageUrl: 'https://www.alexb.org/chemist2u.png',
    appId: '47civNnjdoUKsukw0xxS',
    source: 'com.chemist2u.website',
    sqAppID: 'sandbox-sq0idb-VGA_t-QZabpjfo65c9SJHg',
    sqURL: 'https://js.squareupsandbox.com/v2/paymentform',
    ga: 'GTM-N6D6QGR',
    pbsApiUrl: "https://api.pbs.gov.au/0.3/search.json",
    pbsApiKey: "a2ffef0cc0ffbbcc317322dc59c84f8a",
    socketIo: 'wss://sockets.chemist2u.com.au',
    sqLocationID:'MNKH3V06R5XGZ',
    c2uPbsApiUrl: "https://c2u-pbs-api.chemist2u.com.au",
    c2uPbsApiKey: '8feaba1d-9f9d-4004-a2ca-71544899bffd',
    env: 'admin',
    amplitudeKey: '0a9ff8e4068702ff98437ef81e6c9438'
  };