import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { LoaderService } from 'src/app/services/loader.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  private $auth = inject(AuthService);

  public forgotPasswordForm: FormGroup;
  public submitted = false;

  constructor(private fb: FormBuilder,
    private loaderService: LoaderService) {
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  async onSubmitForgotPaswordForm() {
    this.submitted = true;
    if (this.forgotPasswordForm.valid) {
      const userData = this.forgotPasswordForm.getRawValue();
      try {
        this.loaderService.triggerLoading.emit(true);
        await this.$auth.recoverPassword(userData.email);
        Swal.fire({
          title: 'Please check your email',
          text: "We've emailed you instructions for setting your password, if an account exists with the email you entered.",
          icon: 'success'
        });
        this.loaderService.triggerLoading.emit(false);
      } catch (err) {
        this.loaderService.triggerLoading.emit(false);
        console.log(err);
        if (err.code === 205) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No user found with this email'
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Network failed'
          });
        }
      }
    }
  }

  get f() {
    return this.forgotPasswordForm as FormGroup;
  }
}
