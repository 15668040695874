import { Injectable } from "@angular/core";
import { init, setUserId, logEvent } from '@amplitude/analytics-browser';
import { environment } from "src/environments/environment";
import { User } from '@chemist2u/types-client/C2U/ParseObjects/index.client';

type TEventProps = {
    [key: string]: string | boolean | number;
}

@Injectable({
    providedIn: 'root'
})

export class AmplitudeService {
    public AmplitudeEventNames = {
        USER_LOGIN: 'User Logged In',
        USER_LOGIN_FAIL: "User Login Failed",
        USER_LOGOUT: 'User Logged Out',
        ORDER_SEARCH: 'Admin searching for an order',
        MP_ORDER_SEARCH: 'Admin searching for a marketplace order',
        CUSTOMER_SEARCH: 'Admin searching for a customer',
        PHARMACY_SEARCH: "Admin searching for a pharmacy",
        P_ORDER_SEARCH: 'Pharmacist searching for an order',
        P_MP_ORDER_SEARCH: 'Pharmacist searching for a marketplace order',
        ORDER_VIEW: 'Admin viewing an order',
        MP_ORDER_VIEW: 'Admin viewing a marketplace order',
        CUSTOMER_VIEW: 'Admin viewing a customer',
        PHARMACY_VIEW: 'Admin viewing a pharmacy',
        P_ORDER_VIEW: "Pharmacist viewing an order",
        P_MP_ORDER_VIEW: "Pharmacist viewing a marketplace order",
    }

    constructor() {}

    initialize() {
        const user = User.current();
        if (user) {
            init(environment.amplitudeKey, {
                userId: user.id,
                defaultTracking: false
            });
        }
        else init(environment.amplitudeKey, { defaultTracking: false });
    }

    logEvent(event: string, props?: TEventProps) {
        logEvent(event, props);
    }

    setUserId() {
        setUserId(User.current().id);
    }
}