<div class="main flex">
  <div class="sidebar-container flex" *ngIf="loggedIn || isPharmacyLoggedIn">
    <app-sidebar></app-sidebar>
  </div>
  <div class="router-outlet flex">
    <router-outlet></router-outlet>
  </div>
  <div class="notification" *ngIf="isPharmacyLoggedIn">
    <notification-center></notification-center>
  </div>
</div>
<loader></loader>