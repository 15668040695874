import { Component, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import C2U from '@chemist2u/types-client';
import { Pharmacy } from '@chemist2u/types-client/C2U/ParseObjects/Pharmacy';
import { BasePage } from 'src/app/pages/base-page';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'edit-pharmacy-fulfilment-methods',
  templateUrl: './edit-pharmacy-fulfilment-methods.component.html',
  styleUrls: ['./edit-pharmacy-fulfilment-methods.component.scss']
})
export class EditPharmacyFulfilmentMethodsComponent extends BasePage {
  constructor(injector: Injector, public dialogRef: MatDialogRef<EditPharmacyFulfilmentMethodsComponent>, @Inject(MAT_DIALOG_DATA) public data: Pharmacy, public loaderService: LoaderService, private fb: FormBuilder) {
    super(injector);
  }

  public fulfilmentMethods: C2U.Interfaces.TPharmacyFulfillmentMethod[];
  public form: FormGroup | undefined;

  ngOnInit() {
    this.fulfilmentMethods = this.data.fulfillmentMethods;
    this.form = this.fb.group({
      postal: [''],
      onDemand: [''],
      onDemandBufferMinutes: [{ value: '', disabled: true }],
      standard: [''],
      standardDeliveryCutoffMinutes: [{ value: '', disabled: true }],
      clickAndCollect: [''],
      clickAndCollectBufferMinutes: [{ value: '', disabled: true }]
    })


    if (this.fulfilmentMethods.length) {
      this.fulfilmentMethods.forEach(method => {
        if (method.method === 'OnDemand') {
          this.form.get("onDemand").setValue(true);
          this.form.get('onDemandBufferMinutes').setValue(method.onDemandBufferMinutes);
          this.form.get('onDemandBufferMinutes').enable();
        }
        if (method.method === 'Standard') {
          this.form.get("standard").setValue(true);
          this.form.get('standardDeliveryCutoffMinutes').setValue(method.standardDeliveryCutoffMinutes);
          this.form.get('standardDeliveryCutoffMinutes').enable();
        }
        if (method.method === 'clickAndCollect') {
          this.form.get("clickAndCollect").setValue(true);
          this.form.get('clickAndCollectBufferMinutes').setValue(method.clickAndCollectBufferMinutes);
          this.form.get('clickAndCollectBufferMinutes').enable();
        }
        if (method.method === 'Postal') {
          this.form.get("postal").setValue(true);
        }
      });
    }
    this.manageControlActivation('onDemand', 'onDemandBufferMinutes');
    this.manageControlActivation('standard', 'standardDeliveryCutoffMinutes');
    this.manageControlActivation('clickAndCollect', 'clickAndCollectBufferMinutes');
  }

  private manageControlActivation(checkboxControlName: string, inputControlName: string): void {
    this.form.get(checkboxControlName).valueChanges.subscribe(value => {
      const control = this.form.get(inputControlName);
      if (value) {
        control.enable();
        control.setValidators([Validators.required]);
      } else {
        control.disable();
        control.clearValidators();
      }
      control.updateValueAndValidity();
    });
  }

  async submit() {
    const values = this.form.getRawValue();
    const fulfilmentMethods: C2U.Interfaces.TPharmacyFulfillmentMethod[] = [];
    if (values.postal) {
      fulfilmentMethods.push({
        method: 'Postal',
        isFree: false,
        deliveryFee: 8.95,
      });
    }
    if (values.onDemand) {
      fulfilmentMethods.push({
        method: 'OnDemand',
        isFree: false,
        deliveryFee: 11.95,
        onDemandBufferMinutes: values.onDemandBufferMinutes
      });
    }
    if (values.standard) {
      fulfilmentMethods.push({
        method: 'Standard',
        isFree: false,
        deliveryFee: 6.95,
        standardDeliveryCutoffMinutes: values.standardDeliveryCutoffMinutes
      });
    }
    if (values.clickAndCollect) {
      fulfilmentMethods.push({
        method: 'clickAndCollect',
        isFree: true,
        deliveryFee: 0,
        clickAndCollectBufferMinutes: values.clickAndCollectBufferMinutes
      });
    }
    this.dialogRef.close(fulfilmentMethods);
  }

  close() {
    this.dialogRef.close();
  }
}